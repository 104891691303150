<div class="container">
    <div class="content">
        <mat-tab-group (selectedTabChange)="triggerReload($event)">
            <mat-tab label="Upload a flight">
                <ffp-admin-upload-flights></ffp-admin-upload-flights>
            </mat-tab>
            <mat-tab label="Remove a flight">
                <ffp-admin-remove-flights [reloading]="hasToReload"></ffp-admin-remove-flights>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
