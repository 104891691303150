<div class="container">
    @if (isLoggedIn) {
        <div class="show-profile" [ngClass]="{ 'is-active': isProfileActive }">
            <mat-icon class="material-icons">account_circle</mat-icon>
            @if (isExtended) {
                <qtm-typography fontWeight="normal" [@in-out-animation]>
                    {{ userName }}
                </qtm-typography>
            }
        </div>
    }
    <div class="profile-menu" [ngStyle]="{ 'margin-left': menu.offsetWidth - 16 + 'px' }">
        <div class="profile-menu-content">
            <div class="user-info">
                <mat-icon class="material-icons">person_outline</mat-icon>
                <div class="text">
                    <qtm-typography fontWeight="bold" fontSize="8">
                        {{ userName }}
                    </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="light">
                        {{ userMail }}
                    </qtm-typography>
                </div>
            </div>

            <div class="profile-menu-items logged-in">
                @if (isUserAdmin) {
                    <div (click)="coreService.navigate('administration')">
                        <mat-icon class="material-icons">admin_panel_settings</mat-icon>
                        <qtm-typography fontWeight="medium" fontSize="8"> Admin account </qtm-typography>
                    </div>
                }
                <!--                <div>-->
                <!--                    <mat-icon class="material-icons">settings</mat-icon>-->
                <!--                    <qtm-typography-->
                <!--                        fontWeight="medium"-->
                <!--                        fontSize="8"-->
                <!--                    >-->
                <!--                        Settings-->
                <!--                    </qtm-typography>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</div>
