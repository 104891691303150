import { Directive, ElementRef, Injectable, Input, OnDestroy, Renderer2 } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    animationFrameScheduler,
    combineLatest,
    distinctUntilChanged,
    endWith,
    interval,
    switchMap,
    takeWhile,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable()
export class Destroy extends Observable<void> implements OnDestroy {
    private readonly destroySubject$ = new ReplaySubject<void>(1);

    constructor() {
        super((subscriber) => this.destroySubject$.subscribe(subscriber));
    }

    ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }
}

@Directive({
    selector: '[ffpCountUp]',
    providers: [Destroy],
})
export class CountUpDirective {
    private readonly count$ = new BehaviorSubject(0);
    private readonly duration$ = new BehaviorSubject(2000);
    private easeOutQuad = (x: number): number => x * (2 - x);
    private haveBeenTriggered: boolean = false;

    private readonly currentCount$ = combineLatest([this.count$, this.duration$]).pipe(
        switchMap(([count, duration]) => {
            // get the time when animation is triggered
            const startTime = animationFrameScheduler.now();

            return interval(0, animationFrameScheduler).pipe(
                // calculate elapsed time
                map(() => animationFrameScheduler.now() - startTime),
                // calculate progress
                map((elapsedTime) => elapsedTime / duration),
                // complete when progress is greater than 1
                takeWhile((progress) => progress <= 1),
                // apply quadratic ease-out function
                // for faster start and slower end of counting
                map(this.easeOutQuad),
                // calculate current count
                map((progress) => Math.round(progress * count)),
                // make sure that last emitted value is count
                endWith(count),
                distinctUntilChanged(),
            );
        }),
    );

    @Input('ffpCountUp')
    set count(count: number) {
        this.count$.next(count);
    }

    @Input()
    set duration(duration: number) {
        this.duration$.next(duration);
    }

    @Input()
    set triggerCountUp(value: boolean) {
        if (value && !this.haveBeenTriggered) {
            this.displayCurrentCount();
            this.haveBeenTriggered = true;
        }
    }

    constructor(
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly destroy$: Destroy,
    ) {}

    private displayCurrentCount(): void {
        this.currentCount$.pipe(takeUntil(this.destroy$)).subscribe((currentCount) => {
            this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', currentCount);
        });
    }
}
