import { Injectable, OnDestroy } from '@angular/core';
import { Service } from '../../models/service.interface';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from '../auth/auth.service';
import { MixPanelService } from '../tracking/mixpanel/mix-panel.service';
import { Notification } from '../../models/notification.interface';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CoreService implements OnDestroy {
    // Date
    private currentDate!: Date;
    private readonly currentDateInterval;

    // Services
    private services: Service[] = [];
    private currentService: Service | undefined;
    private _defaultService: Service = {
        value: 'default',
        description: '',
        route: '*',
        isActive: false,
        index: 'default',
        submenu: null,
    };
    private _servicesList: Service[] = [
        {
            value: 'Climate analytics',
            description: 'Monitor and understand your global impact',
            route: '/climate-analytics',
            isActive: false,
            index: 'climate-analytics',
            submenu: [
                {
                    value: 'City pair explorer',
                    description: null,
                    icon: 'data_thresholding',
                    route: '/city-pair-explorer',
                    isActive: false,
                    index: 'city-pair-explorer',
                },
                {
                    value: 'Past flight analysis',
                    description: null,
                    icon: 'travel_explore',
                    route: '/past-flight-analysis',
                    isActive: false,
                    index: 'past-flight-analysis',
                },
            ],
        },
        {
            value: 'Route explorer',
            description: 'Monitor and visualize your fleet and route',
            route: '/route-explorer',
            isActive: false,
            index: 'route-explorer',
            submenu: null,
        },
        {
            value: 'Forecast & Ops tools',
            description: 'Forecast and predict what are the best levers for operations',
            route: '/forecast',
            isActive: false,
            index: 'forecast',
            submenu: null,
        },
        {
            value: 'Climate map',
            description: 'Observe climate sensitive areas to build an effective strategy',
            route: '/climate-map',
            isActive: false,
            index: 'climate-map',
            submenu: null,
        },
    ];

    // Emission metric
    public _emissionMeasure: 'Gwp100' | 'Ccf' = 'Gwp100';

    // Display warning msg
    private displayWarning: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _isTactile: boolean = false;

    // Notifications
    private _notifications: Notification[] = [];
    private _notificationIndex = 3;

    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _mixPanelService: MixPanelService,
        private activatedRoute: ActivatedRoute,
        public breakpointObserver: BreakpointObserver,
    ) {
        this.setServices(this._servicesList);
        this.currentDateInterval = setInterval(() => {
            this.setDate(new Date());
        }, 1000);
    }

    // Date
    public setDate(value: Date): void {
        this.currentDate = value;
    }

    public getCurrentDate(): Date {
        return this.currentDate;
    }

    // Service
    public setServices(value: Service[]): void {
        this.services = value;
    }

    public setCurrentService(service: string | null, subservice: string | null = null): void {
        this._servicesList.forEach((el) => (el.index === service ? (el.isActive = true) : (el.isActive = false)));
        this._servicesList?.forEach((currentService) => {
            if (currentService.submenu !== null) {
                currentService.submenu.forEach((currentSubService) =>
                    currentSubService.index === subservice ? (currentSubService.isActive = true) : (currentSubService.isActive = false),
                );
            }
        });

        this.setServices(this._servicesList);
        if (service === null) {
            this.currentService = this._defaultService;
        } else {
            this.currentService = this._servicesList.find((el) => el.isActive)!;
        }
    }

    public setCurrentServiceFromRoute(route: string): void {
        let currentService = route.split('/')[1];
        let currentSubService = route.split('/')[2];
        const routePath = route.slice(1);
        if (currentSubService?.includes('?')) {
            currentSubService = currentSubService.substring(0, currentSubService.indexOf('?'));
        }
        if (currentService?.includes('?')) {
            currentService = currentService.substring(0, currentService.indexOf('?'));
        }
        if (routePath === '') {
            if (this._authService.isLoggedIn) {
                this._mixPanelService.track('home-page-viewed');
            } else {
                this._mixPanelService.track('landing-page-viewed');
            }
        } else {
            let routeEvent = currentService;
            if (currentSubService) {
                routeEvent += '-' + currentSubService;
            }
            this._mixPanelService.track(routeEvent + '-viewed');
        }
        if (this._servicesList.find((el) => el.index === currentService) === undefined) {
            this.setCurrentService(null);
        } else {
            this.setCurrentService(
                this._servicesList.find((el) => el.index === currentService)!.index,
                currentSubService!
                    ? this._servicesList.find((el) => el.index === currentService)!.submenu!.find((el) => el.index === currentSubService)!.index!
                    : null,
            );
        }
    }

    public getCurrentService(): Service | undefined {
        return this.currentService;
    }

    public getAllServices(): Service[] {
        return this.services;
    }

    public get currentRoute(): string {
        return this._router.url;
    }

    // Display warning

    public getDisplayWarning(): BehaviorSubject<boolean> {
        return this.displayWarning;
    }

    public setDisplayWarning(value: boolean): void {
        this.displayWarning.next(value);
    }

    // COMMON METHODS
    async navigate(route: string, extras?: NavigationExtras): Promise<void> {
        if (route === environment.appUrl) {
            window.location.assign(route);
        } else if (route.startsWith('http')) {
            this._mixPanelService.track('open-link', { link: route });
            window.open(route, '_blank');
        } else {
            await this._router.navigate([route], extras);
        }
    }

    getRouterExtras(): NavigationExtras {
        return this._router.getCurrentNavigation()!.extras;
    }

    mailTo(): void {
        this._mixPanelService.track('contact-us', {
            mail: 'flights-footprint@thalesdigital.io',
        });
        window.open('mailto:flights-footprint@thalesdigital.io', '_blank');
    }

    scrollTo(selector: string): void {
        const element = document.querySelector(selector);
        element!.scrollIntoView({ behavior: 'smooth' });
    }

    // array order ['DEKSTOP','TABLET','MOBILE']
    checkMediaQueries(array: (number | string)[]): number | string {
        if (
            this.breakpointObserver.isMatched('(min-width:641px) and (max-width:1024px)') ||
            this.breakpointObserver.isMatched(' (max-height: 641px) and (orientation: landscape)')
        ) {
            return array[1];
        } else if (this.breakpointObserver.isMatched('(max-width:641px)')) {
            return array[2];
        } else {
            return array[0];
        }
    }

    // BOOLEANS
    get isMobileOrTablet(): boolean {
        return this.breakpointObserver.isMatched('(max-width:1024px)');
    }

    get isTablet(): boolean {
        return (
            this.breakpointObserver.isMatched('(min-width:641px) and (max-width:1024px)') ||
            this.breakpointObserver.isMatched(' (max-height: 641px) and (orientation: landscape)')
        );
    }

    get isTactile(): boolean {
        return this._isTactile;
    }
    set isTactile(value: boolean) {
        this._isTactile = value;
    }

    get isMobile(): boolean {
        return (
            this.breakpointObserver.isMatched('(max-width:641px)') ||
            this.breakpointObserver.isMatched('(max-height:500px) and (orientation: landscape)')
        );
    }

    // EMISSION MEASURE
    set emissionMeasure(measure: 'Gwp100' | 'Ccf') {
        this._emissionMeasure = measure;
        this._router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                emissionMeasure: this.emissionMeasure,
            },
            queryParamsHandling: 'merge',
        });
    }

    get emissionMeasure(): 'Gwp100' | 'Ccf' {
        return this._emissionMeasure;
    }

    // NOTIFICATIONS
    public pushNotification(notification: Notification): void {
        if (
            notification.info === 'public-flight-warning' &&
            this._notifications.filter((item) => item.info === 'public-flight-warning').length !== 0
        ) {
            return;
        }
        this._notificationIndex++;
        notification.index = this._notificationIndex;
        this._notifications.push(notification);

        if (this._notifications.length > 5) {
            this._notifications.shift();
        }
    }

    public clearNotification(): void {
        this._notifications = [];
    }

    public removeNotification(notification: Notification): void {
        this._notifications = this._notifications.filter((item) => JSON.stringify(item) !== JSON.stringify(notification));
    }

    public getNotifications(): Notification[] {
        return this._notifications;
    }

    ngOnDestroy(): void {
        clearInterval(this.currentDateInterval);
    }
}
