import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ffp-logout-dialog',
    templateUrl: './logout-dialog.component.html',
    styleUrls: ['./logout-dialog.component.scss'],
})
export class LogoutDialogComponent {
    constructor(private dialogRef: MatDialogRef<LogoutDialogComponent>) {}

    closeDialog(arg?: string): void {
        this.dialogRef.close(arg);
    }
}
