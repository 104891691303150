import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreService } from '../../../../core/services/core/core.service';
import { SassHelperService } from '../../../../core/services';

@Component({
    selector: 'ffp-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss'],
})
export class LoadingDialogComponent implements OnDestroy {
    public currentService;
    private _intervalDurationInSecs = 4000;
    private _intervalNumber = 5;
    private _progressValue = 0;
    private _tasks = [
        'Computing emissions...',
        'Computing emissions...',
        'Computing fuel flow...',
        'Computing climate costs...',
        'Computing weather impact...',
        'Computing global emissions...',
    ];
    private _interval: ReturnType<typeof setInterval> = setInterval(() => {
        if (this._intervalNumber !== 1) {
            this._progressValue += 20;
            this._intervalNumber--;
        } else {
            this._progressValue += 1;
        }

        if (this._intervalNumber === 0) {
            clearInterval(this._interval);
        }
    }, this._intervalDurationInSecs);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { steps: string[]; currentStep: number },
        public _coreService: CoreService,
        public _sassHelper: SassHelperService,
    ) {
        this.currentService = this._coreService.getCurrentService()?.index;
    }

    get task(): string {
        return this._tasks[this._intervalNumber];
    }

    get value(): number {
        return this._progressValue;
    }

    get steps(): string[] {
        return [...new Set(this.data.steps)] as string[];
    }

    get currentStep(): number {
        return Math.min([...new Set(this.data.steps)].length - 1, this.data.currentStep);
    }

    ngOnDestroy(): void {
        clearInterval(this._interval);
    }

    public resetProgress(): void {
        this._intervalNumber = 5;
        this._progressValue = 0;
        clearInterval(this._interval);
        this.doTimer().then();
    }

    async doTimer(): Promise<void> {
        this._interval = setInterval(() => {
            if (this._intervalNumber !== 1) {
                this._progressValue += 20;
                this._intervalNumber--;
            } else {
                this._progressValue += 1;
            }

            if (this._intervalNumber === 0) {
                clearInterval(this._interval);
            }
        }, this._intervalDurationInSecs);
    }
}
