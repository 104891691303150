import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Flight } from '../../models/flight.interface';

@Injectable({
    providedIn: 'root',
})
export class ManagementService {
    constructor(private readonly httpClient: HttpClient) {}

    public postFlights(file: File, cityPair: string): Observable<{ message: string }> {
        const formData: FormData = new FormData();

        formData.append('file', file);
        formData.append('cityPair', cityPair);

        return this.httpClient.post<{ message: string }>(environment.backendApi + '/api/flights/new', formData);
    }

    public getFlights(): Observable<Flight[]> {
        return this.httpClient.get<Flight[]>(environment.backendApi + '/api/flights');
    }

    public removeFlight(flightNb: string, flightStartDate: string, flightStartTime: string): Observable<{ message: string }> {
        const body: FormData = new FormData();

        body.append('flightNumber', flightNb);
        body.append('startDate', flightStartDate);
        body.append('startTime', flightStartTime);

        return this.httpClient.post<{ message: string }>(environment.backendApi + '/api/flights/remove', body);
    }
}
