<div class="section">
    <span class="section-title">Select the flight(s) you want to remove</span>

    <div class="search-bar">
        <mat-form-field>
            <mat-label>Filter flights list</mat-label>
            <input matInput placeholder="Ex. AF1357 or 2021-11-05 or EGNX" (input)="filter($event)" />
        </mat-form-field>
    </div>

    <div class="section-content">
        <div class="records-table">
            <table mat-table [dataSource]="flights" aria-label="Flights enable in FFP">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef id="selectColumn">
                        <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()"
                        >
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Flight Number Column -->
                <ng-container matColumnDef="flightNumber">
                    <th mat-header-cell *matHeaderCellDef id="flightNumberColumn">Flight Number</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.flightNumber }}
                    </td>
                </ng-container>

                <!-- Start Airport Column -->
                <ng-container matColumnDef="startAirportName">
                    <th mat-header-cell *matHeaderCellDef id="departureColumn">Departure</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.startAirportCode }}
                    </td>
                </ng-container>

                <!-- End Airport Column -->
                <ng-container matColumnDef="endAirportName">
                    <th mat-header-cell *matHeaderCellDef id="destinationColumn">Destination</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.endAirportCode }}
                    </td>
                </ng-container>

                <!-- Start Date Column -->
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef id="startDateColumn">Start Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.startDate | date: "MM/dd/yyyy" }}
                    </td>
                </ng-container>

                <!-- End Date Column -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef id="endDateColumn">End Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.endDate | date: "MM/dd/yyyy" }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
            </table>
        </div>
    </div>
</div>

<div class="buttons">
    <button mat-raised-button color="accent" [disabled]="flights.data.length === 0 || flightsToImport === 0" (click)="remove()">Remove</button>
</div>
