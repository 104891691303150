export const MapboxStyleConfig = {
    version: 8,
    sources: {
        'mapbox://mapbox.mapbox-traffic-v1': {
            url: 'mapbox://mapbox.mapbox-traffic-v1',
            type: 'vector',
        },
        composite: {
            url: 'mapbox://mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2',
            type: 'vector',
        },
    },
    glyphs: 'mapbox://fonts/fontstack/{fontstack}/{range}.pbf',
    layers: [
        {
            id: 'land',
            type: 'background',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, land',
            },
            layout: {},
            paint: { 'background-color': 'hsl(185, 3%, 94%)' },
        },
        {
            id: 'landcover',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, land',
            },
            source: 'composite',
            'source-layer': 'landcover',
            maxzoom: 7,
            layout: {},
            paint: {
                'fill-color': 'hsl(185, 5%, 91%)',
                'fill-opacity': ['interpolate', ['exponential', 1.5], ['zoom'], 2, 0.3, 7, 0],
                'fill-antialias': false,
            },
        },
        {
            id: 'national-park',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, land',
            },
            source: 'composite',
            'source-layer': 'landuse_overlay',
            minzoom: 5,
            filter: ['==', ['get', 'class'], 'national_park'],
            layout: {},
            paint: {
                'fill-color': 'hsl(185, 7%, 89%)',
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, 0.5, 10, 0.5],
            },
        },
        {
            id: 'landuse',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, land',
            },
            source: 'composite',
            'source-layer': 'landuse',
            minzoom: 5,
            filter: ['match', ['get', 'class'], ['park', 'airport', 'glacier', 'pitch', 'sand', 'facility'], true, false],
            layout: {},
            paint: {
                'fill-color': 'hsl(185, 7%, 89%)',
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 6, ['match', ['get', 'class'], 'glacier', 0.5, 1]],
            },
        },
        {
            id: 'waterway-shadow',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, water',
            },
            source: 'composite',
            'source-layer': 'waterway',
            minzoom: 8,
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 11, 'round'],
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsl(185, 5%, 75%)',
                'line-width': [
                    'interpolate',
                    ['exponential', 1.3],
                    ['zoom'],
                    9,
                    ['match', ['get', 'class'], ['canal', 'river'], 0.1, 0],
                    20,
                    ['match', ['get', 'class'], ['canal', 'river'], 8, 3],
                ],
                'line-translate': ['interpolate', ['exponential', 1.2], ['zoom'], 7, ['literal', [0, 0]], 16, ['literal', [-1, -1]]],
                'line-translate-anchor': 'viewport',
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
            },
        },
        {
            id: 'water-shadow',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, water',
            },
            source: 'composite',
            'source-layer': 'water',
            layout: {},
            paint: {
                'fill-color': 'hsl(185, 5%, 75%)',
                'fill-translate': ['interpolate', ['exponential', 1.2], ['zoom'], 7, ['literal', [0, 0]], 16, ['literal', [-1, -1]]],
                'fill-translate-anchor': 'viewport',
            },
        },
        {
            id: 'waterway',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, water',
            },
            source: 'composite',
            'source-layer': 'waterway',
            minzoom: 8,
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 11, 'round'],
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsl(185, 3%, 83%)',
                'line-width': [
                    'interpolate',
                    ['exponential', 1.3],
                    ['zoom'],
                    9,
                    ['match', ['get', 'class'], ['canal', 'river'], 0.1, 0],
                    20,
                    ['match', ['get', 'class'], ['canal', 'river'], 8, 3],
                ],
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 8.5, 1],
            },
        },
        {
            id: 'water',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, water',
            },
            source: 'composite',
            'source-layer': 'water',
            layout: {},
            paint: { 'fill-color': 'hsl(185, 3%, 83%)' },
        },
        {
            id: 'land-structure-polygon',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, built',
            },
            source: 'composite',
            'source-layer': 'structure',
            minzoom: 13,
            filter: ['all', ['==', ['geometry-type'], 'Polygon'], ['==', ['get', 'class'], 'land']],
            layout: {},
            paint: { 'fill-color': 'hsl(185, 3%, 94%)' },
        },
        {
            id: 'land-structure-line',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'land-and-water',
                'mapbox:group': 'Land, water, & sky, built',
            },
            source: 'composite',
            'source-layer': 'structure',
            minzoom: 13,
            filter: ['all', ['==', ['geometry-type'], 'LineString'], ['==', ['get', 'class'], 'land']],
            layout: { 'line-cap': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.99], ['zoom'], 14, 0.75, 20, 40],
                'line-color': 'hsl(185, 3%, 94%)',
            },
        },
        {
            id: 'aeroway-polygon',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'transit',
                'mapbox:group': 'Transit, built',
            },
            source: 'composite',
            'source-layer': 'aeroway',
            minzoom: 11,
            filter: ['all', ['==', ['geometry-type'], 'Polygon'], ['match', ['get', 'type'], ['runway', 'taxiway', 'helipad'], true, false]],
            layout: {},
            paint: {
                'fill-color': 'hsl(185, 3%, 100%)',
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 11, 0, 11.5, 1],
            },
        },
        {
            id: 'aeroway-line',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'transit',
                'mapbox:group': 'Transit, built',
            },
            source: 'composite',
            'source-layer': 'aeroway',
            minzoom: 9,
            filter: ['==', ['geometry-type'], 'LineString'],
            layout: {},
            paint: {
                'line-color': 'hsl(185, 3%, 100%)',
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    9,
                    ['match', ['get', 'type'], 'runway', 1, 0.5],
                    18,
                    ['match', ['get', 'type'], 'runway', 80, 20],
                ],
            },
        },
        {
            id: 'building-outline',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'buildings',
                'mapbox:group': 'Buildings, built',
            },
            source: 'composite',
            'source-layer': 'building',
            minzoom: 15,
            filter: ['all', ['!=', ['get', 'type'], 'building:part'], ['==', ['get', 'underground'], 'false']],
            layout: {},
            paint: {
                'line-color': 'hsl(185, 0%, 84%)',
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 15, 0.75, 20, 3],
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 15, 0, 16, 1],
            },
        },
        {
            id: 'building',
            type: 'fill',
            metadata: {
                'mapbox:featureComponent': 'buildings',
                'mapbox:group': 'Buildings, built',
            },
            source: 'composite',
            'source-layer': 'building',
            minzoom: 15,
            filter: ['all', ['!=', ['get', 'type'], 'building:part'], ['==', ['get', 'underground'], 'false']],
            layout: {},
            paint: {
                'fill-color': ['interpolate', ['linear'], ['zoom'], 15, 'hsl(185, 2%, 90%)', 16, 'hsl(185, 0%, 90%)'],
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 15, 0, 16, 1],
                'fill-outline-color': 'hsl(185, 0%, 84%)',
            },
        },
        {
            id: 'tunnel-street-minor-low',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-color': 'hsl(185, 3%, 87%)',
                'line-opacity': ['step', ['zoom'], 1, 14, 0],
            },
        },
        {
            id: 'tunnel-street-minor-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 1%, 100%)',
                'line-gap-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'tunnel-primary-secondary-tertiary-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['primary', 'secondary', 'tertiary'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, ['match', ['get', 'class'], 'primary', 1, 0.75], 18, 2],
                'line-color': 'hsl(185, 1%, 100%)',
                'line-gap-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    5,
                    ['match', ['get', 'class'], 'primary', 0.75, 0.1],
                    18,
                    ['match', ['get', 'class'], 'primary', 32, 26],
                ],
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'tunnel-major-link-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 1%, 100%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'tunnel-motorway-trunk-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 1, 18, 2],
                'line-color': 'hsl(185, 1%, 100%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'tunnel-construction',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels-case',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 14,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['==', ['get', 'class'], 'construction'],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 87%)',
                'line-dasharray': [
                    'step',
                    ['zoom'],
                    ['literal', [0.4, 0.8]],
                    15,
                    ['literal', [0.3, 0.6]],
                    16,
                    ['literal', [0.2, 0.3]],
                    17,
                    ['literal', [0.2, 0.25]],
                    18,
                    ['literal', [0.15, 0.15]],
                ],
            },
        },
        {
            id: 'tunnel-major-link',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 87%)',
            },
        },
        {
            id: 'tunnel-street-minor',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-color': 'hsl(185, 3%, 87%)',
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'tunnel-primary-secondary-tertiary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['primary', 'secondary', 'tertiary'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    5,
                    ['match', ['get', 'class'], 'primary', 0.75, 0.1],
                    18,
                    ['match', ['get', 'class'], 'primary', 32, 26],
                ],
                'line-color': 'hsl(185, 3%, 87%)',
            },
        },
        {
            id: 'tunnel-motorway-trunk',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, tunnels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'tunnel'],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-color': 'hsl(185, 3%, 87%)',
            },
        },
        {
            id: 'turning-feature-outline',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 15,
            filter: ['all', ['==', ['geometry-type'], 'Point'], ['match', ['get', 'class'], ['turning_circle', 'turning_loop'], true, false]],
            layout: {
                'icon-image': 'turning-circle-outline',
                'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.122, 18, 0.969, 20, 1],
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 0,
                'icon-rotation-alignment': 'map',
            },
            paint: {},
        },
        {
            id: 'road-minor-low',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                [
                    'step',
                    ['zoom'],
                    ['==', ['get', 'class'], 'track'],
                    14,
                    ['match', ['get', 'class'], ['track', 'secondary_link', 'tertiary_link', 'service'], true, false],
                ],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, ['match', ['get', 'class'], 'track', 1, 0.5], 18, 12],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 1, 14, 0],
            },
        },
        {
            id: 'road-minor-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                [
                    'step',
                    ['zoom'],
                    ['==', ['get', 'class'], 'track'],
                    14,
                    ['match', ['get', 'class'], ['track', 'secondary_link', 'tertiary_link', 'service'], true, false],
                ],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, ['match', ['get', 'class'], 'track', 1, 0.5], 18, 12],
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'road-street-low',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 11,
            filter: [
                'all',
                ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 1, 14, 0],
            },
        },
        {
            id: 'road-street-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 11,
            filter: [
                'all',
                ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'road-secondary-tertiary-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 8,
            filter: [
                'all',
                ['match', ['get', 'class'], ['secondary', 'tertiary'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 0.75, 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.1, 18, 26],
                'line-opacity': ['step', ['zoom'], 0, 10, 1],
            },
        },
        {
            id: 'road-primary-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 7,
            filter: [
                'all',
                ['==', ['get', 'class'], 'primary'],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 1, 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-opacity': ['step', ['zoom'], 0, 10, 1],
            },
        },
        {
            id: 'road-major-link-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 10,
            filter: [
                'all',
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-opacity': ['step', ['zoom'], 0, 11, 1],
            },
        },
        {
            id: 'road-motorway-trunk-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 5,
            filter: [
                'all',
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 1, 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-opacity': ['step', ['zoom'], ['match', ['get', 'class'], 'motorway', 1, 0], 6, 1],
            },
        },
        {
            id: 'road-construction',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 14,
            filter: [
                'all',
                ['==', ['get', 'class'], 'construction'],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-dasharray': [
                    'step',
                    ['zoom'],
                    ['literal', [0.4, 0.8]],
                    15,
                    ['literal', [0.3, 0.6]],
                    16,
                    ['literal', [0.2, 0.3]],
                    17,
                    ['literal', [0.2, 0.25]],
                    18,
                    ['literal', [0.15, 0.15]],
                ],
            },
        },
        {
            id: 'road-major-link',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 10,
            filter: [
                'all',
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 13, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 13, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'road-minor',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                [
                    'step',
                    ['zoom'],
                    ['==', ['get', 'class'], 'track'],
                    14,
                    ['match', ['get', 'class'], ['track', 'secondary_link', 'tertiary_link', 'service'], true, false],
                ],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, ['match', ['get', 'class'], 'track', 1, 0.5], 18, 12],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'road-street',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 11,
            filter: [
                'all',
                ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'road-secondary-tertiary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 8,
            filter: [
                'all',
                ['match', ['get', 'class'], ['secondary', 'tertiary'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.1, 18, 26],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'road-primary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 6,
            filter: [
                'all',
                ['==', ['get', 'class'], 'primary'],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'road-motorway-trunk',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            filter: [
                'all',
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 13, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 13, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'road-rail',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'transit',
                'mapbox:group': 'Transit, surface',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['match', ['get', 'class'], ['major_rail', 'minor_rail'], true, false],
                ['match', ['get', 'structure'], ['none', 'ford'], true, false],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-color': 'hsl(185, 0%, 90%)',
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.5, 20, 1],
            },
        },
        {
            id: 'turning-feature',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, surface-icons',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 15,
            filter: ['all', ['==', ['geometry-type'], 'Point'], ['match', ['get', 'class'], ['turning_circle', 'turning_loop'], true, false]],
            layout: {
                'icon-image': 'turning-circle',
                'icon-size': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.095, 18, 1],
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-padding': 0,
                'icon-rotation-alignment': 'map',
            },
            paint: {},
        },
        {
            id: 'bridge-street-minor-low',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 1, 14, 0],
            },
        },
        {
            id: 'bridge-street-minor-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'bridge-primary-secondary-tertiary-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['primary', 'secondary', 'tertiary'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, ['match', ['get', 'class'], 'primary', 1, 0.75], 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    5,
                    ['match', ['get', 'class'], 'primary', 0.75, 0.1],
                    18,
                    ['match', ['get', 'class'], 'primary', 32, 26],
                ],
                'line-opacity': ['step', ['zoom'], 0, 10, 1],
            },
        },
        {
            id: 'bridge-major-link-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['<=', ['get', 'layer'], 1],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
            },
        },
        {
            id: 'bridge-motorway-trunk-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['<=', ['get', 'layer'], 1],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 1, 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
            },
        },
        {
            id: 'bridge-construction',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 14,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['==', ['get', 'class'], 'construction'],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-dasharray': [
                    'step',
                    ['zoom'],
                    ['literal', [0.4, 0.8]],
                    15,
                    ['literal', [0.3, 0.6]],
                    16,
                    ['literal', [0.2, 0.3]],
                    17,
                    ['literal', [0.2, 0.25]],
                    18,
                    ['literal', [0.15, 0.15]],
                ],
            },
        },
        {
            id: 'bridge-major-link',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['<=', ['get', 'layer'], 1],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'bridge-street-minor',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                [
                    'step',
                    ['zoom'],
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link', 'track'], true, false],
                    14,
                    [
                        'match',
                        ['get', 'class'],
                        ['street', 'street_limited', 'track', 'primary_link', 'secondary_link', 'tertiary_link', 'service'],
                        true,
                        false,
                    ],
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    12,
                    0.5,
                    14,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 2, 'track', 1, 0.5],
                    18,
                    ['match', ['get', 'class'], ['street', 'street_limited', 'primary_link'], 18, 12],
                ],
                'line-color': 'hsl(185, 3%, 100%)',
                'line-opacity': ['step', ['zoom'], 0, 14, 1],
            },
        },
        {
            id: 'bridge-primary-secondary-tertiary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['primary', 'secondary', 'tertiary'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': [
                    'interpolate',
                    ['exponential', 1.5],
                    ['zoom'],
                    5,
                    ['match', ['get', 'class'], 'primary', 0.75, 0.1],
                    18,
                    ['match', ['get', 'class'], 'primary', 32, 26],
                ],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'bridge-motorway-trunk',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['<=', ['get', 'layer'], 1],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'bridge-major-link-2-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['>=', ['get', 'layer'], 2],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.75, 20, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
            },
        },
        {
            id: 'bridge-motorway-trunk-2-case',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['>=', ['get', 'layer'], 2],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 1, 18, 2],
                'line-color': 'hsl(185, 0%, 90%)',
                'line-gap-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
            },
        },
        {
            id: 'bridge-major-link-2',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['>=', ['get', 'layer'], 2],
                ['match', ['get', 'class'], ['motorway_link', 'trunk_link'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0.5, 14, 2, 18, 18],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'bridge-motorway-trunk-2',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: [
                'all',
                ['==', ['get', 'structure'], 'bridge'],
                ['>=', ['get', 'layer'], 2],
                ['match', ['get', 'class'], ['motorway', 'trunk'], true, false],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'line-cap': ['step', ['zoom'], 'butt', 14, 'round'],
                'line-join': ['step', ['zoom'], 'miter', 14, 'round'],
            },
            paint: {
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 5, 0.75, 18, 32],
                'line-color': 'hsl(185, 3%, 100%)',
            },
        },
        {
            id: 'bridge-rail',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'transit',
                'mapbox:group': 'Transit, bridges',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 13,
            filter: ['all', ['==', ['get', 'structure'], 'bridge'], ['match', ['get', 'class'], ['major_rail', 'minor_rail'], true, false]],
            layout: { 'line-join': 'round' },
            paint: {
                'line-color': 'hsl(185, 0%, 90%)',
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 14, 0.5, 20, 1],
            },
        },
        {
            id: 'admin-1-boundary-bg',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'admin-boundaries',
                'mapbox:group': 'Administrative boundaries, admin',
            },
            source: 'composite',
            'source-layer': 'admin',
            minzoom: 7,
            filter: [
                'all',
                ['==', ['get', 'admin_level'], 1],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
            ],
            layout: { 'line-join': 'bevel' },
            paint: {
                'line-color': ['interpolate', ['linear'], ['zoom'], 8, 'hsl(185, 0%, 84%)', 16, 'hsl(185, 0%, 84%)'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 7, 3.75, 12, 5.5],
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.75],
                'line-dasharray': [1, 0],
                'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 8, 3],
            },
        },
        {
            id: 'admin-0-boundary-bg',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'admin-boundaries',
                'mapbox:group': 'Administrative boundaries, admin',
            },
            source: 'composite',
            'source-layer': 'admin',
            minzoom: 1,
            filter: [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
            ],
            layout: {},
            paint: {
                'line-width': ['interpolate', ['linear'], ['zoom'], 3, 3.5, 10, 8],
                'line-color': 'hsl(185, 0%, 84%)',
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 4, 0.5],
                'line-blur': ['interpolate', ['linear'], ['zoom'], 3, 0, 10, 2],
            },
        },
        {
            id: 'admin-1-boundary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'admin-boundaries',
                'mapbox:group': 'Administrative boundaries, admin',
            },
            source: 'composite',
            'source-layer': 'admin',
            minzoom: 2,
            filter: [
                'all',
                ['==', ['get', 'admin_level'], 1],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
            ],
            layout: { 'line-join': 'round', 'line-cap': 'round' },
            paint: {
                'line-dasharray': ['step', ['zoom'], ['literal', [2, 0]], 7, ['literal', [2, 2, 6, 2]]],
                'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.75, 12, 1.5],
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 1],
                'line-color': ['interpolate', ['linear'], ['zoom'], 3, 'hsl(185, 0%, 83%)', 7, 'hsl(185, 3%, 68%)'],
            },
        },
        {
            id: 'admin-0-boundary',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'admin-boundaries',
                'mapbox:group': 'Administrative boundaries, admin',
            },
            source: 'composite',
            'source-layer': 'admin',
            minzoom: 1,
            filter: [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'disputed'], 'false'],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
            ],
            layout: { 'line-join': 'round', 'line-cap': 'round' },
            paint: {
                'line-color': 'hsl(185, 0%, 67%)',
                'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
                'line-dasharray': [10, 0],
            },
        },
        {
            id: 'admin-0-boundary-disputed',
            type: 'line',
            metadata: {
                'mapbox:featureComponent': 'admin-boundaries',
                'mapbox:group': 'Administrative boundaries, admin',
            },
            source: 'composite',
            'source-layer': 'admin',
            minzoom: 1,
            filter: [
                'all',
                ['==', ['get', 'disputed'], 'true'],
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
            ],
            layout: { 'line-join': 'round' },
            paint: {
                'line-color': 'hsl(185, 0%, 67%)',
                'line-width': ['interpolate', ['linear'], ['zoom'], 3, 0.5, 10, 2],
                'line-dasharray': [
                    'step',
                    ['zoom'],
                    ['literal', [3.25, 3.25]],
                    6,
                    ['literal', [2.5, 2.5]],
                    7,
                    ['literal', [2, 2.25]],
                    8,
                    ['literal', [1.75, 2]],
                ],
            },
        },
        {
            id: 'road-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, road-labels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 10,
            filter: [
                'step',
                ['zoom'],
                ['match', ['get', 'class'], ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'], true, false],
                12,
                ['match', ['get', 'class'], ['motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'street', 'street_limited'], true, false],
                15,
                ['match', ['get', 'class'], ['path', 'pedestrian', 'golf', 'ferry', 'aerialway'], false, true],
            ],
            layout: {
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    10,
                    [
                        'match',
                        ['get', 'class'],
                        ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
                        10,
                        ['motorway_link', 'trunk_link', 'primary_link', 'secondary_link', 'tertiary_link', 'street', 'street_limited'],
                        9,
                        6.5,
                    ],
                    18,
                    [
                        'match',
                        ['get', 'class'],
                        ['motorway', 'trunk', 'primary', 'secondary', 'tertiary'],
                        16,
                        ['motorway_link', 'trunk_link', 'primary_link', 'secondary_link', 'tertiary_link', 'street', 'street_limited'],
                        14,
                        13,
                    ],
                ],
                'text-max-angle': 30,
                'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
                'symbol-placement': 'line',
                'text-padding': 1,
                'text-rotation-alignment': 'map',
                'text-pitch-alignment': 'viewport',
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-letter-spacing': 0.01,
            },
            paint: {
                'text-color': 'hsl(185, 3%, 47%)',
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'road-intersection',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'road-network',
                'mapbox:group': 'Road network, road-labels',
            },
            source: 'composite',
            'source-layer': 'road',
            minzoom: 15,
            filter: ['all', ['==', ['get', 'class'], 'intersection'], ['has', 'name']],
            layout: {
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'icon-image': 'intersection',
                'icon-text-fit': 'both',
                'icon-text-fit-padding': [1, 2, 1, 2],
                'text-size': ['interpolate', ['exponential', 1.2], ['zoom'], 15, 9, 18, 12],
                'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
            },
            paint: { 'text-color': 'hsl(185, 5%, 55%)' },
        },
        {
            id: 'waterway-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'natural-features',
                'mapbox:group': 'Natural features, natural-labels',
            },
            source: 'composite',
            'source-layer': 'natural_label',
            minzoom: 13,
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    ['canal', 'river', 'stream'],
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    ['disputed_canal', 'disputed_river', 'disputed_stream'],
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
                'text-max-angle': 30,
                'symbol-spacing': ['interpolate', ['linear', 1], ['zoom'], 15, 250, 17, 400],
                'text-size': ['interpolate', ['linear'], ['zoom'], 13, 12, 18, 16],
                'symbol-placement': 'line',
                'text-pitch-alignment': 'viewport',
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
            },
            paint: { 'text-color': 'hsl(185, 3%, 55%)' },
        },
        {
            id: 'natural-line-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'natural-features',
                'mapbox:group': 'Natural features, natural-labels',
            },
            source: 'composite',
            'source-layer': 'natural_label',
            minzoom: 4,
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    ['glacier', 'landform'],
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    ['disputed_glacier', 'disputed_landform'],
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['==', ['geometry-type'], 'LineString'],
                ['<=', ['get', 'filterrank'], 1],
            ],
            layout: {
                'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
                'text-max-angle': 30,
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'symbol-placement': 'line-center',
                'text-pitch-alignment': 'viewport',
            },
            paint: {
                'text-halo-width': 0.5,
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-blur': 0.5,
                'text-color': 'hsl(185, 3%, 47%)',
            },
        },
        {
            id: 'natural-point-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'natural-features',
                'mapbox:group': 'Natural features, natural-labels',
            },
            source: 'composite',
            'source-layer': 'natural_label',
            minzoom: 4,
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    ['dock', 'glacier', 'landform', 'water_feature', 'wetland'],
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    ['disputed_dock', 'disputed_glacier', 'disputed_landform', 'disputed_water_feature', 'disputed_wetland'],
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['==', ['geometry-type'], 'Point'],
                ['<=', ['get', 'filterrank'], 1],
            ],
            layout: {
                'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
                'icon-image': '',
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'text-offset': ['literal', [0, 0]],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
            },
            paint: {
                'icon-opacity': ['step', ['zoom'], ['step', ['get', 'sizerank'], 0, 5, 1], 17, ['step', ['get', 'sizerank'], 0, 13, 1]],
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 0.5,
                'text-halo-blur': 0.5,
                'text-color': 'hsl(185, 3%, 47%)',
            },
        },
        {
            id: 'water-line-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'natural-features',
                'mapbox:group': 'Natural features, natural-labels',
            },
            source: 'composite',
            'source-layer': 'natural_label',
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    ['bay', 'ocean', 'reservoir', 'sea', 'water'],
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    ['disputed_bay', 'disputed_ocean', 'disputed_reservoir', 'disputed_sea', 'disputed_water'],
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['==', ['geometry-type'], 'LineString'],
            ],
            layout: {
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    7,
                    ['step', ['get', 'sizerank'], 20, 6, 18, 12, 12],
                    10,
                    ['step', ['get', 'sizerank'], 15, 9, 12],
                    18,
                    ['step', ['get', 'sizerank'], 15, 9, 14],
                ],
                'text-max-angle': 30,
                'text-letter-spacing': ['match', ['get', 'class'], 'ocean', 0.25, ['sea', 'bay'], 0.15, 0],
                'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
                'symbol-placement': 'line-center',
                'text-pitch-alignment': 'viewport',
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
            },
            paint: { 'text-color': 'hsl(185, 3%, 55%)' },
        },
        {
            id: 'water-point-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'natural-features',
                'mapbox:group': 'Natural features, natural-labels',
            },
            source: 'composite',
            'source-layer': 'natural_label',
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    ['bay', 'ocean', 'reservoir', 'sea', 'water'],
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    ['disputed_bay', 'disputed_ocean', 'disputed_reservoir', 'disputed_sea', 'disputed_water'],
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['==', ['geometry-type'], 'Point'],
            ],
            layout: {
                'text-line-height': 1.3,
                'text-size': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    7,
                    ['step', ['get', 'sizerank'], 20, 6, 15, 12, 12],
                    10,
                    ['step', ['get', 'sizerank'], 15, 9, 12],
                ],
                'text-font': ['DIN Pro Italic', 'Arial Unicode MS Regular'],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-letter-spacing': ['match', ['get', 'class'], 'ocean', 0.25, ['bay', 'sea'], 0.15, 0.01],
                'text-max-width': ['match', ['get', 'class'], 'ocean', 4, 'sea', 5, ['bay', 'water'], 7, 10],
            },
            paint: { 'text-color': 'hsl(185, 3%, 55%)' },
        },
        {
            id: 'poi-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'point-of-interest-labels',
                'mapbox:group': 'Point of interest labels, poi-labels',
            },
            source: 'composite',
            'source-layer': 'poi_label',
            minzoom: 6,
            filter: ['<=', ['get', 'filterrank'], ['+', ['step', ['zoom'], 0, 16, 1, 17, 2], 1]],
            layout: {
                'text-size': ['step', ['zoom'], ['step', ['get', 'sizerank'], 18, 5, 12], 17, ['step', ['get', 'sizerank'], 18, 13, 12]],
                'icon-image': '',
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'text-offset': [0, 0],
                'text-anchor': [
                    'step',
                    ['zoom'],
                    ['step', ['get', 'sizerank'], 'center', 5, 'top'],
                    17,
                    ['step', ['get', 'sizerank'], 'center', 13, 'top'],
                ],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
            },
            paint: {
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 0.5,
                'text-halo-blur': 0.5,
                'text-color': [
                    'step',
                    ['zoom'],
                    ['step', ['get', 'sizerank'], 'hsl(185, 3%, 68%)', 5, 'hsl(185, 3%, 59%)'],
                    17,
                    ['step', ['get', 'sizerank'], 'hsl(185, 3%, 68%)', 13, 'hsl(185, 3%, 59%)'],
                ],
            },
        },
        {
            id: 'airport-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'transit',
                'mapbox:group': 'Transit, transit-labels',
            },
            source: 'composite',
            'source-layer': 'airport_label',
            minzoom: 8,
            filter: [
                'match',
                ['get', 'class'],
                ['military', 'civil'],
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                ['disputed_military', 'disputed_civil'],
                ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                false,
            ],
            layout: {
                'text-line-height': 1.1,
                'text-size': ['step', ['get', 'sizerank'], 18, 9, 12],
                'icon-image': ['step', ['get', 'sizerank'], ['concat', ['get', 'maki'], '-15'], 9, ['concat', ['get', 'maki'], '-11']],
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'text-offset': [0, 0.75],
                'text-rotation-alignment': 'viewport',
                'text-anchor': 'top',
                'text-field': ['step', ['get', 'sizerank'], ['coalesce', ['get', 'name_en'], ['get', 'name']], 15, ['get', 'ref']],
                'text-letter-spacing': 0.01,
                'text-max-width': 9,
            },
            paint: {
                'text-color': 'hsl(185, 3%, 47%)',
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
            },
        },
        {
            id: 'settlement-subdivision-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'place-labels',
                'mapbox:group': 'Place labels, place-labels',
            },
            source: 'composite',
            'source-layer': 'place_label',
            minzoom: 10,
            maxzoom: 15,
            filter: [
                'all',
                [
                    'match',
                    ['get', 'class'],
                    'settlement_subdivision',
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    'disputed_settlement_subdivision',
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                ['<=', ['get', 'filterrank'], 4],
            ],
            layout: {
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-transform': 'uppercase',
                'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
                'text-letter-spacing': ['match', ['get', 'type'], 'suburb', 0.15, 0.1],
                'text-max-width': 7,
                'text-padding': 3,
                'text-size': [
                    'interpolate',
                    ['cubic-bezier', 0.5, 0, 1, 1],
                    ['zoom'],
                    11,
                    ['match', ['get', 'type'], 'suburb', 11, 10.5],
                    15,
                    ['match', ['get', 'type'], 'suburb', 15, 14],
                ],
            },
            paint: {
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
                'text-color': 'hsl(185, 3%, 66%)',
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'settlement-minor-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'place-labels',
                'mapbox:group': 'Place labels, place-labels',
            },
            source: 'composite',
            'source-layer': 'place_label',
            maxzoom: 15,
            filter: [
                'all',
                ['<=', ['get', 'filterrank'], 3],
                [
                    'match',
                    ['get', 'class'],
                    'settlement',
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    'disputed_settlement',
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                [
                    'step',
                    ['zoom'],
                    true,
                    8,
                    ['>=', ['get', 'symbolrank'], 11],
                    10,
                    ['>=', ['get', 'symbolrank'], 12],
                    11,
                    ['>=', ['get', 'symbolrank'], 13],
                    12,
                    ['>=', ['get', 'symbolrank'], 15],
                    13,
                    ['>=', ['get', 'symbolrank'], 11],
                    14,
                    ['>=', ['get', 'symbolrank'], 13],
                ],
            ],
            layout: {
                'text-line-height': 1.1,
                'text-size': [
                    'interpolate',
                    ['cubic-bezier', 0.2, 0, 0.9, 1],
                    ['zoom'],
                    3,
                    ['step', ['get', 'symbolrank'], 12, 9, 11, 10, 10.5, 12, 9.5, 14, 8.5, 16, 6.5, 17, 4],
                    13,
                    ['step', ['get', 'symbolrank'], 23, 9, 21, 10, 19, 11, 17, 12, 16, 13, 15, 15, 13],
                ],
                'icon-image': [
                    'step',
                    ['zoom'],
                    ['case', ['==', ['get', 'capital'], 2], 'border-dot-13', ['step', ['get', 'symbolrank'], 'dot-11', 9, 'dot-10', 11, 'dot-9']],
                    8,
                    '',
                ],
                'text-font': ['DIN Pro Regular', 'Arial Unicode MS Regular'],
                'text-justify': [
                    'step',
                    ['zoom'],
                    [
                        'match',
                        ['get', 'text_anchor'],
                        ['left', 'bottom-left', 'top-left'],
                        'left',
                        ['right', 'bottom-right', 'top-right'],
                        'right',
                        'center',
                    ],
                    8,
                    'center',
                ],
                'text-offset': [
                    'step',
                    ['zoom'],
                    [
                        'match',
                        ['get', 'capital'],
                        2,
                        [
                            'match',
                            ['get', 'text_anchor'],
                            'bottom',
                            ['literal', [0, -0.3]],
                            'bottom-left',
                            ['literal', [0.3, -0.1]],
                            'left',
                            ['literal', [0.45, 0.1]],
                            'top-left',
                            ['literal', [0.3, 0.1]],
                            'top',
                            ['literal', [0, 0.3]],
                            'top-right',
                            ['literal', [-0.3, 0.1]],
                            'right',
                            ['literal', [-0.45, 0]],
                            'bottom-right',
                            ['literal', [-0.3, -0.1]],
                            ['literal', [0, -0.3]],
                        ],
                        [
                            'match',
                            ['get', 'text_anchor'],
                            'bottom',
                            ['literal', [0, -0.25]],
                            'bottom-left',
                            ['literal', [0.2, -0.05]],
                            'left',
                            ['literal', [0.4, 0.05]],
                            'top-left',
                            ['literal', [0.2, 0.05]],
                            'top',
                            ['literal', [0, 0.25]],
                            'top-right',
                            ['literal', [-0.2, 0.05]],
                            'right',
                            ['literal', [-0.4, 0.05]],
                            'bottom-right',
                            ['literal', [-0.2, -0.05]],
                            ['literal', [0, -0.25]],
                        ],
                    ],
                    8,
                    ['literal', [0, 0]],
                ],
                'text-anchor': ['step', ['zoom'], ['get', 'text_anchor'], 8, 'center'],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-max-width': 7,
            },
            paint: {
                'text-color': ['step', ['get', 'symbolrank'], 'hsl(185, 3%, 47%)', 11, 'hsl(185, 3%, 59%)', 16, 'hsl(185, 3%, 68%)'],
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'settlement-major-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'place-labels',
                'mapbox:group': 'Place labels, place-labels',
            },
            source: 'composite',
            'source-layer': 'place_label',
            maxzoom: 15,
            filter: [
                'all',
                ['<=', ['get', 'filterrank'], 3],
                [
                    'match',
                    ['get', 'class'],
                    'settlement',
                    ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                    'disputed_settlement',
                    ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                    false,
                ],
                [
                    'step',
                    ['zoom'],
                    false,
                    8,
                    ['<', ['get', 'symbolrank'], 11],
                    10,
                    ['<', ['get', 'symbolrank'], 12],
                    11,
                    ['<', ['get', 'symbolrank'], 13],
                    12,
                    ['<', ['get', 'symbolrank'], 15],
                    13,
                    ['>=', ['get', 'symbolrank'], 11],
                    14,
                    ['>=', ['get', 'symbolrank'], 13],
                ],
            ],
            layout: {
                'text-line-height': 1.1,
                'text-size': [
                    'interpolate',
                    ['cubic-bezier', 0.2, 0, 0.9, 1],
                    ['zoom'],
                    8,
                    ['step', ['get', 'symbolrank'], 18, 9, 17, 10, 15],
                    15,
                    ['step', ['get', 'symbolrank'], 23, 9, 22, 10, 20, 11, 18, 12, 16, 13, 15, 15, 13],
                ],
                'icon-image': [
                    'step',
                    ['zoom'],
                    ['case', ['==', ['get', 'capital'], 2], 'border-dot-13', ['step', ['get', 'symbolrank'], 'dot-11', 9, 'dot-10', 11, 'dot-9']],
                    8,
                    '',
                ],
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'text-justify': [
                    'step',
                    ['zoom'],
                    [
                        'match',
                        ['get', 'text_anchor'],
                        ['left', 'bottom-left', 'top-left'],
                        'left',
                        ['right', 'bottom-right', 'top-right'],
                        'right',
                        'center',
                    ],
                    8,
                    'center',
                ],
                'text-offset': [
                    'step',
                    ['zoom'],
                    [
                        'match',
                        ['get', 'capital'],
                        2,
                        [
                            'match',
                            ['get', 'text_anchor'],
                            'bottom',
                            ['literal', [0, -0.3]],
                            'bottom-left',
                            ['literal', [0.3, -0.1]],
                            'left',
                            ['literal', [0.45, 0.1]],
                            'top-left',
                            ['literal', [0.3, 0.1]],
                            'top',
                            ['literal', [0, 0.3]],
                            'top-right',
                            ['literal', [-0.3, 0.1]],
                            'right',
                            ['literal', [-0.45, 0]],
                            'bottom-right',
                            ['literal', [-0.3, -0.1]],
                            ['literal', [0, -0.3]],
                        ],
                        [
                            'match',
                            ['get', 'text_anchor'],
                            'bottom',
                            ['literal', [0, -0.25]],
                            'bottom-left',
                            ['literal', [0.2, -0.05]],
                            'left',
                            ['literal', [0.4, 0.05]],
                            'top-left',
                            ['literal', [0.2, 0.05]],
                            'top',
                            ['literal', [0, 0.25]],
                            'top-right',
                            ['literal', [-0.2, 0.05]],
                            'right',
                            ['literal', [-0.4, 0.05]],
                            'bottom-right',
                            ['literal', [-0.2, -0.05]],
                            ['literal', [0, -0.25]],
                        ],
                    ],
                    8,
                    ['literal', [0, 0]],
                ],
                'text-anchor': ['step', ['zoom'], ['get', 'text_anchor'], 8, 'center'],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-max-width': 7,
            },
            paint: {
                'text-color': ['step', ['get', 'symbolrank'], 'hsl(185, 3%, 47%)', 11, 'hsl(185, 3%, 59%)', 16, 'hsl(185, 3%, 68%)'],
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'state-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'place-labels',
                'mapbox:group': 'Place labels, place-labels',
            },
            source: 'composite',
            'source-layer': 'place_label',
            minzoom: 3,
            maxzoom: 9,
            filter: [
                'match',
                ['get', 'class'],
                'state',
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                'disputed_state',
                ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                false,
            ],
            layout: {
                'text-size': [
                    'interpolate',
                    ['cubic-bezier', 0.85, 0.7, 0.65, 1],
                    ['zoom'],
                    4,
                    ['step', ['get', 'symbolrank'], 10, 6, 9.5, 7, 9],
                    9,
                    ['step', ['get', 'symbolrank'], 21, 6, 16, 7, 13],
                ],
                'text-transform': 'uppercase',
                'text-font': ['DIN Pro Bold', 'Arial Unicode MS Bold'],
                'text-field': [
                    'step',
                    ['zoom'],
                    [
                        'step',
                        ['get', 'symbolrank'],
                        ['coalesce', ['get', 'name_en'], ['get', 'name']],
                        5,
                        ['coalesce', ['get', 'abbr'], ['get', 'name_en'], ['get', 'name']],
                    ],
                    5,
                    ['coalesce', ['get', 'name_en'], ['get', 'name']],
                ],
                'text-letter-spacing': 0.15,
                'text-max-width': 6,
            },
            paint: {
                'text-color': 'hsl(185, 3%, 68%)',
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1,
            },
        },
        {
            id: 'country-label',
            type: 'symbol',
            metadata: {
                'mapbox:featureComponent': 'place-labels',
                'mapbox:group': 'Place labels, place-labels',
            },
            source: 'composite',
            'source-layer': 'place_label',
            minzoom: 1,
            maxzoom: 10,
            filter: [
                'match',
                ['get', 'class'],
                'country',
                ['match', ['get', 'worldview'], ['all', 'US'], true, false],
                'disputed_country',
                ['all', ['==', ['get', 'disputed'], 'true'], ['match', ['get', 'worldview'], ['all', 'US'], true, false]],
                false,
            ],
            layout: {
                'text-line-height': 1.1,
                'text-size': [
                    'interpolate',
                    ['cubic-bezier', 0.2, 0, 0.7, 1],
                    ['zoom'],
                    1,
                    ['step', ['get', 'symbolrank'], 11, 4, 9, 5, 8],
                    9,
                    ['step', ['get', 'symbolrank'], 22, 4, 19, 5, 17],
                ],
                'icon-image': '',
                'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
                'text-justify': [
                    'step',
                    ['zoom'],
                    [
                        'match',
                        ['get', 'text_anchor'],
                        ['left', 'bottom-left', 'top-left'],
                        'left',
                        ['right', 'bottom-right', 'top-right'],
                        'right',
                        'center',
                    ],
                    7,
                    'center',
                ],
                'text-offset': ['literal', [0, 0]],
                'text-field': ['coalesce', ['get', 'name_en'], ['get', 'name']],
                'text-max-width': 6,
            },
            paint: {
                'icon-opacity': ['step', ['zoom'], ['case', ['has', 'text_anchor'], 1, 0], 7, 0],
                'text-color': 'hsl(185, 3%, 47%)',
                'text-halo-color': 'hsl(185, 1%, 100%)',
                'text-halo-width': 1.25,
            },
        },
    ],
};
