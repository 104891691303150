@if (isSelected && isHovered) {
    <ffp-button type="primary" size="small" neutral (ffpClick)="closeButtonCallback.emit()">
        <mat-icon class="material-icons-round"> close </mat-icon>
    </ffp-button>
}
<div class="container" [ngClass]="{ selected: isSelected, 'best-flight': isBestFlight }" #container>
    <div class="date item">
        <qtm-typography fontSize="9" fontWeight="medium" class="label"> DATE </qtm-typography>
        <qtm-typography fontSize="8" fontWeight="bold" class="value">
            {{ data!.startDate | formatDate: "mm/dd/yy" }} at
            {{ data!.startTime | formatHoursAndMinutes }}
        </qtm-typography>
    </div>
    <div class="flight-number item">
        <qtm-typography fontSize="9" fontWeight="medium" class="label"> FLIGHT NUMBER </qtm-typography>
        <qtm-typography fontSize="8" fontWeight="bold" class="value">
            {{ data!.flightNumber }}
        </qtm-typography>
    </div>
    <div class="climate-impact item">
        <qtm-typography fontSize="9" fontWeight="medium" class="label"> CLIMATE IMPACT </qtm-typography>
        <div class="climate-impact-value">
            @if (isBestFlight) {
                <div class="best-label">
                    <mat-icon class="material-icons-round">star</mat-icon>
                    <qtm-typography fontSize="8" fontWeight="bold" class="value"> Best </qtm-typography>
                </div>
            }
            <ffp-data-delta [value]="climateImpactComparison" [filled]="isBestFlight">
                {{ climateImpactComparison > 0 ? "+" + climateImpactComparison : climateImpactComparison }}%
            </ffp-data-delta>
        </div>
    </div>
    <div class="emission-distributions item">
        @if (_coreService.emissionMeasure.toLowerCase() === "ccf") {
            <qtm-typography fontSize="9" fontWeight="medium" class="label"> ATR<sub>20</sub> DISTRIBUTIONS </qtm-typography>
        }
        @if (_coreService.emissionMeasure.toLowerCase() === "gwp100") {
            <qtm-typography fontSize="9" fontWeight="medium" class="label"> GWP<sub>100</sub> DISTRIBUTIONS </qtm-typography>
        }
        @if (emissionDistributions) {
            <ffp-consumption-details
                [data]="emissionDistributions"
                [parentWidth]="container.clientWidth"
                [tooltip]="true"
                [svgId]="data!.flightNumber"
            >
            </ffp-consumption-details>
        }
    </div>
</div>
