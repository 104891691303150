import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, retry, timer } from 'rxjs';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(retry({ count: 1, delay: this.shouldRetry }));
    }

    shouldRetry(error: HttpErrorResponse): Observable<0> {
        if (error.status >= 400) {
            return timer(500);
        }
        throw error;
    }
}
