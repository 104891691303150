import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MixPanelService } from '../../../../services';
import { AuthService } from '../../../../services/auth/auth.service';
import { CoreService } from '../../../../services/core/core.service';
import { menuAnimation } from '../../animations/animations';

@Component({
    selector: 'ffp-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [menuAnimation],
})
export class ProfileComponent {
    @Input()
    isExtended: boolean = false;

    @Input()
    menu!: HTMLElement;

    isProfileActive: boolean = false;

    constructor(
        private _router: Router,
        private _mixPanelService: MixPanelService,
        protected authService: AuthService,
        public coreService: CoreService,
    ) {}

    async login(): Promise<void> {
        await this.authService.signIn();
    }

    get isLoggedIn(): boolean {
        return this.authService.isLoggedIn;
    }

    get userMail(): string {
        return this.authService.userMail!;
    }

    get userName(): string {
        return this.authService.firstName + ' ' + this.authService.lastName;
    }

    get isUserAdmin(): boolean {
        return this.authService.isUserAdmin;
    }
}
