import { Injectable } from '@angular/core';
import { Utils } from '../../utils/utils';
import { GeoJsonProperties } from 'geojson';

@Injectable({
    providedIn: 'root',
})
export class VerticalDisplayService {
    // eslint-disable-next-line
    public lastZoomEvent: any;

    constructor() {}

    public getPointColorFromProperties(properties: GeoJsonProperties, propertyName: string, legend: [number, string][]): string | null {
        let color = null;
        let firstIndex = null;
        let secondIndex = null;

        if (properties) {
            if (legend.map((c) => c[0]).indexOf(properties[propertyName]) > -1) {
                return legend[legend.map((c) => c[0]).indexOf(properties[propertyName])][1];
            } else {
                for (let i = 0; i < legend.length; i++) {
                    if (legend[i][0] < properties[propertyName]) {
                        firstIndex = i;
                    }

                    if (!secondIndex && legend[i][0] > properties[propertyName]) {
                        secondIndex = i;
                    }
                }
                if (firstIndex === null) {
                    color = legend[0][1];
                } else if (secondIndex === null) {
                    color = legend[legend.length - 1][1];
                } else {
                    const ratio = (properties[propertyName] - legend[firstIndex][0]) / (legend[secondIndex][0] - legend[firstIndex][0]);
                    color = Utils.getRgbaFromGradient(legend[firstIndex][1], legend[secondIndex][1], ratio);
                }
            }
        }

        return color;
    }
}
