import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCurrentTime' })
export class FormatCurrentTimePipe implements PipeTransform {
    transform(value: Date): string {
        return value?.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
        });
    }
}

@Pipe({ name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
    transform(value: Date): string {
        value.setMinutes(0);
        return value?.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
        });
    }
}

@Pipe({ name: 'formatFlightHour' })
export class FormatFlightHour implements PipeTransform {
    transform(value: Date): string {
        return value?.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
        });
    }
}

@Pipe({ name: 'getDate' })
export class GetDatePipe implements PipeTransform {
    transform(value: Date): string {
        return value
            ?.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
            })
            .toUpperCase();
    }
}
