import { Component } from '@angular/core';
import { ManagementService } from './services/management/management.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'ffp-admin',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent {
    hasToReload = false;

    constructor(private readonly _managementService: ManagementService) {}

    triggerReload(event: MatTabChangeEvent): void {
        this.hasToReload = event.tab.textLabel === 'Remove a flight';
    }
}
