export enum FlightPhase {
    FULL,
    CL,
    CR,
    DE,
    NA,
}

export interface FlightPhaseSelect {
    label: string;
    value: FlightPhase;
}
