import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    // eslint-disable-next-line
    selector: 'mat-autocomplete[optionsScroll]',
})
export class MatOptionsScrollDirective implements OnDestroy {
    @Input() thresholdPercent = 0.8;
    @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
    _onDestroy = new Subject<void>();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    // Note: When autocomplete raises opened, panel is not yet created (by Overlay)
                    // Note: The panel will be available on next tick
                    // Note: The panel wil NOT open if there are no options to display
                    setTimeout(() => {
                        // Note: remove listener just for safety, in case the close event is skipped.
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
                    });
                }),
                takeUntil(this._onDestroy),
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this._onDestroy),
            )
            .subscribe();
    }

    private removeScrollEventListener(): void {
        this.autoComplete.panel?.nativeElement.removeEventListener('scroll', this.onScroll);
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();

        this.removeScrollEventListener();
    }

    onScroll(event: Event): void {
        if (this.thresholdPercent === undefined) {
            this.optionsScroll.next({
                autoComplete: this.autoComplete,
                scrollEvent: event,
            });
        } else {
            const threshold = (this.thresholdPercent * 100 * (event.target as HTMLElement).scrollHeight) / 100;
            const current = (event.target as HTMLElement).scrollTop + (event.target as HTMLElement).clientHeight;

            if (current > threshold) {
                this.optionsScroll.next({
                    autoComplete: this.autoComplete,
                    scrollEvent: event,
                });
            }
        }
    }
}
