import { Component } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { SassHelperService } from '../../services';

@Component({
    selector: 'ffp-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent {
    constructor(
        public coreService: CoreService,
        public sassHelperService: SassHelperService,
    ) {}
}
