import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { catchError, concatMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard {
    constructor(
        protected readonly _router: Router,
        private _authService: AuthService,
        private _location: Location,
        @Inject(MSAL_GUARD_CONFIG)
        private _msalGuardConfig: MsalGuardConfiguration,
        private _msalService: MsalService,
    ) {}

    canActivate(state: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this._msalService.handleRedirectObservable().pipe(
            concatMap(() => {
                let r;
                if (!this._authService.isLoggedIn) {
                    r = {
                        path: '',
                        loadChildren: () => import('./features/landing-page/landing-page.module').then((m) => m.LandingPageModule),
                    };
                } else {
                    r = {
                        path: '',
                        loadChildren: () => import('./features/reporting/reporting.module').then((m) => m.ReportingModule),
                    };
                }
                this._router.resetConfig([r, ...this._router.config]);
                this._router.navigate(state.url, { queryParams: state.queryParams }).then();
                return of(true);
            }),
            catchError(() => {
                this._router.navigate([this._msalGuardConfig.loginFailedRoute]).then();
                return of(false);
            }),
        );
    }
}
