<div #menu [ngClass]="{ extended: isExtended }" class="content">
    <div class="top">
        <div
            class="menu-extend"
            (click)="extend()"
            [ngStyle]="{
                'background-color': sassHelperService.getProperty('ffp-' + coreService.getCurrentService()?.index + '-5'),
                color: sassHelperService.getProperty('ffp-' + coreService.getCurrentService()?.index + '-130'),
            }"
        >
            <mat-icon class="material-icons">menu_open</mat-icon>
        </div>
        <div class="airline-info">
            <img src="../../../../assets/icons/airlines/{{ airline.replace(' ', '_') }}.svg" id="airline-icon" alt="Airline icon" />
            @if (isExtended) {
                <qtm-typography fontSize="7" fontWeight="normal" [@in-out-animation]>
                    {{ airline | uppercase }}
                </qtm-typography>
            }
        </div>
    </div>
    @if (coreService.getAllServices() !== null) {
        <div class="middle">
            <div class="icon-item" (click)="coreService.navigate(environment.appUrl)" [ngClass]="{ active: coreService.currentRoute === '/' }">
                <mat-icon> home </mat-icon>
                @if (isExtended) {
                    <qtm-typography fontSize="7" fontWeight="normal" [@in-out-animation]> Home </qtm-typography>
                }
            </div>
            <div class="services">
                <qtm-typography fontSize="9" fontWeight="bold" class="services"> SERVICES </qtm-typography>
                @for (service of coreService.getAllServices(); track service) {
                    <ffp-menu-item
                        [value]="service.value"
                        [index]="service.index"
                        [description]="service.description"
                        [isExtended]="isExtended"
                        [route]="service.route"
                        [isActive]="service.isActive"
                        [disabled]="service.index === 'climate-map' || service.index === 'route-estimator'"
                        [submenu]="service.submenu"
                        [menu]="menu"
                    ></ffp-menu-item>
                }
            </div>
        </div>
    }
    <div class="bottom">
        <ffp-profile [isExtended]="isExtended" [menu]="menu"></ffp-profile>
        <div class="divider"></div>
        <div class="logout icon-item" (click)="logout()">
            <mat-icon> power_settings_new </mat-icon>
            @if (isExtended) {
                <qtm-typography fontSize="7" fontWeight="normal" [@in-out-animation]> Log out </qtm-typography>
            }
        </div>
    </div>
</div>
