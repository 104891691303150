import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MapService } from '../../services';
import { Feature } from 'geojson';
import { MixPanelService } from '../../../core/services';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'ffp-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('hotspotsPanel') hotspotsPanel!: ElementRef<MatExpansionPanel>;

    @Input()
    verticalViewEnabled: boolean = false;

    @Input()
    highlightable = false;

    @Input()
    highlighted: string | null = null;

    @Output()
    fullscreen: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    legend: EventEmitter<[number, string][]> = new EventEmitter<[number, string][]>();

    @Output()
    legendName: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    selectedSegment: EventEmitter<{
        feature: Feature;
        firstIndex: number;
        lastIndex: number;
    } | null> = new EventEmitter<{
        feature: Feature;
        firstIndex: number;
        lastIndex: number;
    } | null>();

    @Output()
    highlightedFlight: EventEmitter<string> = new EventEmitter<string>();

    isFullscreen = false;
    selectionEnabled = false;

    windLayerActive = false;
    contrailsLayerActive = false;
    currentLegend: [number, string][] = [];

    constructor(
        private _mapService: MapService,
        private _mixPanelService: MixPanelService,
    ) {}

    get bearing(): number {
        return this._mapService.bearing;
    }

    get legendColors(): string {
        let result = '';
        for (const color of this.currentLegend) {
            result += color[1] + ', ';
        }
        return result.slice(0, result.length - 2);
    }

    get legendValues(): (number | string)[] {
        const result = [];
        for (const color of this.currentLegend) {
            result.push(color[0]);
        }
        return result;
    }

    ngOnInit(): void {
        this.initMap();
    }

    ngAfterViewInit(): void {
        this._mapService.highlightable = this.highlightable;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['highlighted']) {
            this._mapService.highlightFlight(this.highlighted);
        }
    }

    initMap(): void {
        this._mapService.buildMap();
        this._mapService.selectedSegment.subscribe((value) => {
            this.selectedSegment.emit(value);
        });
        this._mapService.highlightedFlight.subscribe((value) => {
            this.highlightedFlight.emit(value);
        });
    }

    reset(): void {
        this.currentLegend = [];
        this.windLayerActive = false;
        this.contrailsLayerActive = false;
        // this.hotspotsPanel.close();
        this.resetBearing();
        this.isFullscreen = false;
        this.fullscreen.emit(this.isFullscreen);
        this.selectionEnabled = false;
        this._mapService.toggleSelectionMode(this.selectionEnabled);
    }

    resetBearing(): void {
        this._mapService.resetBearing();
    }

    zoomIn(): void {
        this._mapService.zoomIn();
    }

    zoomOut(): void {
        this._mapService.zoomOut();
    }

    toggleFullscreen(): void {
        this.isFullscreen = !this.isFullscreen;
        this.fullscreen.emit(this.isFullscreen);
    }

    toggleSelectionMode(): void {
        this.selectionEnabled = !this.selectionEnabled;
        this._mapService.toggleSelectionMode(this.selectionEnabled);
    }

    focusOnFeature(): void {
        this._mapService.zoomOn();
    }

    toggleWeatherLayerVisibility(layerName: string): void {
        let showLegend;
        switch (layerName) {
            case 'wind':
                this.windLayerActive = !this.windLayerActive;
                showLegend = this.windLayerActive;
                this._mapService.weatherLayerActivated = this.windLayerActive ? layerName : '';
                this.contrailsLayerActive = false;
                break;
            case 'contrails':
                this.contrailsLayerActive = !this.contrailsLayerActive;
                showLegend = this.contrailsLayerActive;
                this._mapService.weatherLayerActivated = this.contrailsLayerActive ? layerName : '';
                this.windLayerActive = false;
                break;
            default:
                showLegend = false;
                break;
        }
        if (showLegend) {
            this.currentLegend = this._mapService.getLegend(layerName);
            this.legendName.emit(layerName);
        } else {
            this.currentLegend = [];
            this.legendName.emit();
        }
        this.legend.emit(this.currentLegend);
        this._mapService.toggleLayerVisibility('wind', this.windLayerActive);
        this._mapService.toggleLayerVisibility('contrails', this.contrailsLayerActive);
        this._mixPanelService.track('map-toggle-weather-layers', {
            wind: this.windLayerActive,
            contrails: this.contrailsLayerActive,
        });
    }
}
