import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth/auth.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { CoreService } from './core/services/core/core.service';

@Injectable({
    providedIn: 'root',
})
export class MobileGuard {
    constructor(
        protected readonly _router: Router,
        private _authService: AuthService,
        private _location: Location,
        private _coreService: CoreService,
        @Inject(MSAL_GUARD_CONFIG)
        private _msalGuardConfig: MsalGuardConfiguration,
        private _msalService: MsalService,
    ) {}

    canActivate(): boolean {
        if (this._coreService.isMobile) {
            this._authService.signOut();
            return false;
        } else {
            return true;
        }
    }
}
