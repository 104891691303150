import { Component, EventEmitter, Input, OnInit, Output, booleanAttribute } from '@angular/core';
import { CoreService } from '../../../core/services/core/core.service';
import { validateProps } from '../../utils/validate-props-decorator';
import { Validators } from '@angular/forms';

@Component({
    selector: 'ffp-button',
    templateUrl: './ffp-button.component.html',
    styleUrls: ['./ffp-button.component.scss'],
})
export class FfpButtonComponent implements OnInit {
    @Input()
    size: string = 'small';

    @Input()
    type: string = 'primary';

    @Input()
    disabled: boolean = false;

    @Input({ transform: booleanAttribute })
    ghost: boolean = false;

    @Input({ transform: booleanAttribute })
    neutral: boolean = false;

    @Input({ transform: booleanAttribute })
    set gradient(value: boolean) {
        this._gradient = value;
        this.updateClasses();
    }

    @Input()
    classes = '';

    @Output()
    ffpClick = new EventEmitter();

    @Output()
    ffpMouseover = new EventEmitter();

    @Output()
    ffpMouseleave = new EventEmitter();

    _gradient: boolean = false;

    buttonClasses: string = '';

    constructor(private coreService: CoreService) {}

    clickHandler($event: MouseEvent): void {
        if (!this.disabled) {
            this.ffpClick.emit($event);
        }
        $event.stopPropagation();
    }

    mouseLeaveHandler($event: MouseEvent): void {
        this.ffpMouseleave.emit($event);
        $event.stopPropagation();
    }

    mouseOverHandler($event: MouseEvent): void {
        this.ffpMouseover.emit($event);
        $event.stopPropagation();
    }

    ngOnInit(): void {
        // Set 'size' and 'type' as required properties
        validateProps<FfpButtonComponent>(this, {
            size: [Validators.required],
            type: [Validators.required],
        });
        this.updateClasses();
    }

    updateClasses(): void {
        this.classes = '';
        this.disabled = this.disabled !== undefined ? (typeof this.disabled === 'boolean' ? this.disabled : true) : false;
        this.ghost = this.ghost !== undefined ? (typeof this.ghost === 'boolean' ? this.ghost : true) : false;
        this.neutral = this.neutral !== undefined ? (typeof this.neutral === 'boolean' ? this.neutral : true) : false;
        this._gradient = this._gradient !== undefined ? (typeof this._gradient === 'boolean' ? this._gradient : true) : false;

        let currentService: string | undefined = '';
        if (!this.neutral) {
            currentService = this.coreService.getCurrentService()?.index === undefined ? 'default' : this.coreService.getCurrentService()?.index;
        }

        if (this.ghost) {
            this.classes = [this.classes, 'ghost'].join(' ');
        }

        if (this.neutral) {
            this.classes = [this.classes, 'neutral'].join(' ');
        }

        if (this._gradient) {
            this.classes = [this.classes, 'gradient'].join(' ');
        }
        this.buttonClasses = `${this.classes} ${this.type} ${this.size} ${currentService} `;
    }
}
