import { Component } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { Notification } from '../../models/notification.interface';

@Component({
    selector: 'ffp-notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent {
    hoveredNotification: Notification | null = null;

    constructor(public readonly coreService: CoreService) {}

    setHoveredNotification(notification: Notification | null): void {
        this.hoveredNotification = notification;
    }
}
