import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ForecastFlight } from '../../models/flight.interface';
import { AuthService } from '../../../../core/services/auth/auth.service';
import forecastFlights from '../../../../../assets/mocks/forecast.json';

@Injectable({
    providedIn: 'root',
})
export class ForecastService {
    flightIndexCardHovered: number | null = null;
    flightIndexTagHovered: number | null = null;

    private hoveredCardFlightIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
    private hoveredTagFlightIndex: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {}

    public setHoveredCardFlightIndex(newIndex: number | null): void {
        if (newIndex !== this.flightIndexCardHovered) {
            this.flightIndexCardHovered = newIndex;
            this.hoveredCardFlightIndex.next(newIndex);
        }
    }

    public getHoveredCardFlightIndex(): Observable<number | null> {
        return this.hoveredCardFlightIndex.asObservable();
    }

    public setHoveredTagFlightIndex(newIndex: number | null): void {
        if (newIndex !== this.flightIndexTagHovered) {
            this.flightIndexTagHovered = newIndex;
            this.hoveredTagFlightIndex.next(newIndex);
        }
    }

    public getHoveredTagFlightIndex(): Observable<number | null> {
        return this.hoveredTagFlightIndex.asObservable();
    }

    public getComingFlights(): Observable<ForecastFlight[]> {
        if (this.authService.airline !== 'thales') {
            return this.httpClient.get<ForecastFlight[]>(environment.backendApi + '/api/flights/forecast');
        } else {
            let content = JSON.parse(JSON.stringify(forecastFlights));
            const now = new Date();
            content = content.map((f: ForecastFlight) => {
                let randomStartDate;
                let randomEndDate;
                const realStartDate = new Date(Date.parse(f.initialFlight.startDate + 'T' + f.initialFlight.startTime));
                const realEndDate = new Date(Date.parse(f.initialFlight.endDate + 'T' + f.initialFlight.endTime));
                const flightDuration = realEndDate.getTime() - realStartDate.getTime();
                if (f.index < 3) {
                    randomStartDate = new Date(now);
                    randomStartDate.setMinutes(60 * Math.random());
                    randomStartDate.setHours(randomStartDate.getHours() + 1 + 4 * Math.random());
                    randomEndDate = new Date(randomStartDate.getTime() + flightDuration);
                } else {
                    randomStartDate = new Date(now);
                    randomStartDate.setMinutes(60 * Math.random());
                    randomStartDate.setHours(randomStartDate.getHours() - 2 + 10 * Math.random());
                    randomEndDate = new Date(randomStartDate.getTime() + flightDuration);
                }
                f.initialFlight.startDate = randomStartDate.toISOString().split('T')[0];
                f.initialFlight.endDate = randomEndDate.toISOString().split('T')[0];
                f.initialFlight.startTime = randomStartDate.toISOString().split('T')[1].split('.')[0];
                f.initialFlight.endTime = randomEndDate.toISOString().split('T')[1].split('.')[0];
                return f;
            });

            return of(content as ForecastFlight[]);
        }
    }
}
