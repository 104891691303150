import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CoreService } from '../../../../services/core/core.service';
import { Router } from '@angular/router';
import { SassHelperService } from '../../../../services';
import { AuthService } from '../../../../services/auth/auth.service';
import { menuAnimation } from '../../animations/animations';
import { Subservice } from '../../../../models/subservice.interface';

@Component({
    selector: 'ffp-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    animations: [menuAnimation],
})
export class MenuItemComponent implements OnInit, OnChanges {
    @Input()
    value = String();

    @Input()
    isExtended = false;

    @Input()
    description = String();

    @Input()
    route = '/';

    @Input()
    isActive = false;

    @Input()
    index = String();

    @Input()
    disabled: boolean = false;

    @Input()
    submenu: Subservice[] | null = null;

    @Input()
    menu!: HTMLElement;

    hover = false;
    currentIcon = String();
    icon = {
        default: '',
        hover: '',
        active: '',
        disabled: '',
    };
    submenuItemHovered: number | undefined;

    constructor(
        private _router: Router,
        public coreService: CoreService,
        private _authService: AuthService,
        public sassHelperService: SassHelperService,
    ) {}

    ngOnInit(): void {
        this.icon = {
            default: `assets/icons/menu/${this.value.toLowerCase().replace(/ /g, '-')}.svg`,
            hover: `assets/icons/menu/${this.value.toLowerCase().replace(/ /g, '-')}-hover.svg`,
            active: `assets/icons/menu/${this.value.toLowerCase().replace(/ /g, '-')}-active.svg`,
            disabled: `assets/icons/menu/${this.value.toLowerCase().replace(/ /g, '-')}-disabled.svg`,
        };
        this.isActive ? this.activate() : this.deactivate();
        this.disabled ? (this.currentIcon = this.icon.disabled) : (this.currentIcon = this.icon.default);
    }

    ngOnChanges(): void {
        this.isActive ? this.activate() : this.deactivate();
    }

    deactivate(): void {
        this.disabled ? (this.currentIcon = this.icon.disabled) : (this.currentIcon = this.icon.default);
    }

    activate(): void {
        this.isActive = !this.disabled;
        this.disabled ? (this.currentIcon = this.icon.disabled) : (this.currentIcon = this.icon.active);
    }

    onHover(): void {
        this.disabled ? (this.currentIcon = this.icon.disabled) : (this.currentIcon = this.icon.hover);
        this.hover = true;
    }

    onLeave(): void {
        this.disabled
            ? (this.currentIcon = this.icon.disabled)
            : (this.currentIcon = this.currentIcon = this.isActive ? this.icon.active : this.icon.default);
        this.hover = false;
    }

    onMouseDown(): void {
        this.disabled ? (this.currentIcon = this.icon.disabled) : (this.currentIcon = this.icon.active);
    }

    onSelect(): void {
        if (!this.isActive && this._authService.isLoggedIn && !this.disabled && !this.submenu) {
            this.activate();
            this.coreService.setCurrentService(this.value);
            this._router.navigate([this.route]).then();
        }
    }
}
