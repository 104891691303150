import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { AccountInfo } from '@azure/msal-common/src/account/AccountInfo';
import { RedirectRequest } from '@azure/msal-browser/src/request/RedirectRequest';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: environment.msalSusi,
        editProfile: environment.msalEditProfile,
        resetPassword: environment.msalResetPassword
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://' + environment.msalTenant + '.b2clogin.com/' + environment.msalTenant + '.onmicrosoft.com/'
                + environment.msalSusi,
        },
        editProfile: {
            authority: 'https://' + environment.msalTenant + '.b2clogin.com/' + environment.msalTenant + '.onmicrosoft.com/'
            + environment.msalEditProfile
        },
        resetPassword: {
            authority: 'https://' + environment.msalTenant + '.b2clogin.com/' + environment.msalTenant + '.onmicrosoft.com/'
                + environment.msalResetPassword
        }
    },
    authorityDomain: environment.msalTenant + '.b2clogin.com'
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.msalClientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin,
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string): void {
                console.log(message);
            },
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
};

export const apiConfig = {
    b2cScopes: ['https://' + environment.msalTenant + '.onmicrosoft.com/' + environment.msalScope]
};

export const loginRequest = {
    scopes: ['openid', ...apiConfig.b2cScopes],
};

export const tokenRequest: RedirectRequest = {
    scopes: [...apiConfig.b2cScopes],
    account: undefined,
};
