<div class="main-container" #ref (mouseenter)="isHovered = true" (mouseleave)="isHovered = false" (mousemove)="mouseMoved($event)"></div>
@if (tooltip) {
    <ffp-consumption-details-label
        [displayValues]="true"
        [displayLabel]="true"
        [data]="data"
        [ngClass]="{ show: isHovered, hide: !isHovered }"
        [ngStyle]="{
            top: tooltipPosition.y + 'px',
            left: tooltipPosition.x + 'px',
        }"
    >
    </ffp-consumption-details-label>
}
