import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ffp-flight-info',
    templateUrl: './flight-info.component.html',
    styleUrls: ['./flight-info.component.scss'],
})
export class FlightInfoComponent implements OnInit {
    @Input() info = new Array(8);

    public displayTwoRows: boolean = false;

    constructor() {}

    ngOnInit(): void {
        if (this.info.length > 4) {
            this.displayTwoRows = true;
        }
    }
}
