<div class="welcome-header">
    <div class="logo-and-title">
        <img id="green-aviation-logo" src="assets/icons/green-aviation-light.svg" alt="Green aviation" />
        <div class="header-content">
            <span class="title-secondary">Hello & welcome to</span>
            <span class="title-main">Flights Footprint</span>
        </div>
    </div>
    <div class="divider"></div>
    <img id="thales-logo" src="assets/icons/thales-light.svg" alt="Thales" />
</div>

<div class="maintenance-text">
    <img id="ffp" src="assets/icons/maintenance.svg" alt="Flights Footprint" />
    <div class="title">Website under maintenance</div>

    <div class="coming-soon">Flights Footprint is out of service due to scheduled maintenance and expect to back online in a few minutes.</div>
    <div class="coming-soon">Thank you for your patience.</div>
</div>
