<div
    class="map-container"
    [ngClass]="{
        'display-filter': displayFilter,
        'display-filter-mobile': verticalViewEnabled,
    }"
>
    @if (flightFromCityPairExplorer) {
        <ffp-button type="secondary" size="x-small" neutral (ffpClick)="back()">
            <mat-icon [inline]="true" class="material-icons-round"> arrow_back </mat-icon>
            <qtm-typography fontSize="8" fontWeight="bold"> Back to city pair </qtm-typography>
        </ffp-button>
    }
    @if (!flightFromCityPairExplorer) {
        <div
            class="search-bar"
            [ngClass]="{
                animate: triggerAnim,
                'trajectory-displayed': flightTrajectory !== undefined && flightTrajectory !== null,
            }"
        >
            @if (!flightTrajectory) {
                <qtm-typography fontSize="4" fontWeight="bold"> Which flight would you like to analyze? </qtm-typography>
            }
            <div class="search-bar-content">
                <ffp-analytics-map-search-bar
                    class="loading-element"
                    (triggerSearchBarAnim)="triggerAnim = $event"
                    (submitForm)="handleFormSubmission($event)"
                    (triggerFilter)="displayFilter = $event"
                    [flightDisplayed]="flightDisplayed"
                    [isTrajectoryDisplayed]="flightTrajectory !== undefined && flightTrajectory !== null"
                >
                </ffp-analytics-map-search-bar>
            </div>
        </div>
    }
    <div class="filter"></div>
    @if (flightTrajectory) {
        <div class="right-panel">
            <ffp-flight-info [info]="infoToDisplay"></ffp-flight-info>
            <div class="right-panel-content">
                <ffp-analytics-past-flights-consumption-visualisation
                    [trajId]="flightTrajectory.id"
                    [isBestFlight]="isBestFlight(flightTrajectory)"
                    [selectedFlight]="flightTrajectory"
                    [highlighted]="highlightedFlight === flightTrajectory.id"
                    @cardAnimation
                    (hoveredFlight)="highlightFlight($event)"
                    (changeFlightVisibility)="changeTrajectoryVisibility($event)"
                ></ffp-analytics-past-flights-consumption-visualisation>
                @for (flight of flightTrajectory.references; track flight) {
                    <ffp-analytics-past-flights-consumption-visualisation
                        [trajId]="flight.id"
                        @cardAnimation
                        [isBestFlight]="isBestFlight(flight.trajectory)"
                        [selectedFlight]="flight.trajectory"
                        [highlighted]="highlightedFlight === flight.id"
                        (removeFlight)="removeSimilarFlight(flight)"
                        (hoveredFlight)="highlightFlight($event)"
                        (changeFlightVisibility)="changeTrajectoryVisibility($event)"
                    ></ffp-analytics-past-flights-consumption-visualisation>
                }
                @if (flightTrajectory.references.length < 2) {
                    <ffp-button type="primary" size="medium" gradient (ffpClick)="openCompareSearchModal()" [disabled]="!bestFlight">
                        <qtm-typography fontSize="7" fontWeight="medium"> Compare to other flights </qtm-typography>
                    </ffp-button>
                }
            </div>
        </div>
    }
    <ffp-map
        #mapComponent
        [verticalViewEnabled]="verticalViewEnabled"
        [highlightable]="true"
        (highlightedFlight)="highlightFlight($event)"
        [highlighted]="highlightedFlight"
    ></ffp-map>
    <div class="vertical-display-container">
        @if (flightTrajectory) {
            <ffp-vertical-view
                #verticalDisplayComponent
                [flightFeatureCollection]="flightSource"
                [similarFlightFeatureCollection]="similarFlightSources"
                (expanded)="changeVerticalViewMap($event)"
                [highlightedFlight]="highlightedFlight"
            ></ffp-vertical-view>
        }
    </div>
</div>
