<div class="container">
    <div class="flight-info-item">
        <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
            {{ info[0] }}
        </qtm-typography>
        @if (displayTwoRows) {
            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                {{ info[4] }}
            </qtm-typography>
        }
    </div>
    <div class="flight-info-divider"></div>
    <div class="flight-info-item">
        <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
            {{ info[1] }}
        </qtm-typography>
        @if (displayTwoRows) {
            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                {{ info[5] }}
            </qtm-typography>
        }
    </div>
    <div class="flight-info-divider"></div>
    <div class="flight-info-item">
        <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
            {{ info[2] }}
        </qtm-typography>
        @if (displayTwoRows) {
            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                {{ info[6] }}
            </qtm-typography>
        }
    </div>
    <div class="flight-info-divider"></div>
    <div class="flight-info-item">
        <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
            {{ info[3] }}
        </qtm-typography>
        @if (displayTwoRows) {
            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                {{ info[7] }}
            </qtm-typography>
        }
    </div>
</div>
