import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DisplayedFlight, ForecastFlight } from '../../models/flight.interface';
import { CoreService } from '../../../../core/services/core/core.service';
import { MixPanelService, SassHelperService } from '../../../../core/services';
import { ForecastService } from '../../services/forecast/forecast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ffp-forecast-coming-flight',
    templateUrl: './coming-flights.component.html',
    styleUrls: ['./coming-flights.component.scss'],
})
export class ComingFlightsComponent implements OnInit, OnDestroy {
    @Input() set flights(value: ForecastFlight[]) {
        this._flights = value;
        this.checkpoint.setHours(this.checkpoint.getHours() - 1, 0, 0, 0);
        this.flightWithBestAlternative = this._flights[0];
        this._flights.sort((a, b) => a.initialFlight.realStartDate.getTime() - b.initialFlight.realStartDate.getTime());
        this.computeLineForFlights();
        this.updateDisplay();
        this.isFlightLineComputed = true;
        this.automaticViewModeAdaptation();
    }

    @HostBinding('class.extend') isFullScreen: boolean = false;

    @Output()
    triggerFullScreen = new EventEmitter<boolean>();

    _flights: ForecastFlight[] = [];
    flightsDisplayed: DisplayedFlight[] = [];
    lastNavigation: string | null = null;
    windowTime = 12;
    currentDate = new Date();
    displayedDay = new Date(this.currentDate.valueOf());
    checkpoint = new Date(this.currentDate.valueOf());
    counter = 0;
    displayedHours: { value: Date; class: string }[] = [];
    rightButtonHover = false;
    leftButtonHover = false;
    currentFlight: ForecastFlight | null = null;
    isFlightLineComputed = false;
    flightWithBestAlternative: ForecastFlight | undefined;
    isFilterActive = false;
    private _currentAnimationRef: ReturnType<typeof setTimeout> = setTimeout(() => {});
    private _subscription: Subscription | undefined;
    triggerTimeWindowButtonAnim: boolean = false;

    constructor(
        public coreService: CoreService,
        public sassHelperService: SassHelperService,
        public forecastService: ForecastService,
        private _mixPanelService: MixPanelService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {}

    ngOnInit(): void {
        // Subscribe to event when card is hovered, and apply filter + scroll to corresponding flight if needed
        this._subscription = this.forecastService.getHoveredCardFlightIndex().subscribe((newValue) => {
            this.isFilterActive = newValue !== null;
            if (newValue !== null) {
                const flightTag = document.getElementById('flight-tag-' + newValue);
                if (flightTag) {
                    const container = document.getElementsByClassName('flights')[0];
                    container.scrollTo({
                        top: flightTag.offsetTop,
                        behavior: 'smooth',
                    });
                }
            }
        });

        this._route.queryParams.subscribe((params) => {
            if (params['windowTime']) {
                this.windowTime = +params['windowTime'];
                this.displayedDay.setHours(this.displayedDay.getHours() - this.counter);
                // Reset counter to the right value: smallest multiple of 'newValue' containing current flights
                this.counter = Math.floor(this.counter / this.windowTime) * this.windowTime;
                this.lastNavigation = null;
                this.displayedDay.setHours(this.displayedDay.getHours() + this.counter);
                this.updateDisplay();
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public fullScreen(): void {
        this.isFullScreen = !this.isFullScreen;
        this.triggerFullScreen.emit(this.isFullScreen);
    }

    automaticViewModeAdaptation(): void {
        if (this.coreService.isTablet) {
            this.windowTime = 6;
            this.updateDisplay();
        } else {
            // Set window time to the smallest value containing all flights
            const latestFlight = this.flightsDisplayed.find(
                (el) =>
                    el.initialFlight.realEndDate.getTime() === Math.max(...this.flightsDisplayed.map((o) => o.initialFlight.realEndDate.getTime())),
            );
            if (latestFlight) {
                const deltaWithCheckpoint = (latestFlight.initialFlight.realEndDate.getTime() - this.checkpoint.getTime()) / 3600000;
                this.windowTime = Math.ceil(deltaWithCheckpoint / 6) <= 2 ? Math.ceil(deltaWithCheckpoint / 6) * 6 : 12;
                this.updateDisplay();
            }
        }
    }

    changeViewMode(newValue: number): void {
        this._router
            .navigate([], {
                queryParams: {
                    windowTime: newValue,
                },
            })
            .then();
    }

    computeLineForFlights(): void {
        // Algorithm to display flights on timeline, by default on first line, if not enough space, on the line below, and so on
        let lines: Date[] = [];
        this._flights.forEach((flight) => {
            if (!lines.length) {
                lines.push(flight.initialFlight.realEndDate);
                flight.initialFlight.line = 0;
            } else {
                const temp = lines.map((x) => x);
                for (let i = 0; i <= temp.length; i++) {
                    if (new Date(flight.initialFlight.realStartDate.getTime() - 200) > temp[i]) {
                        temp[i] = flight.initialFlight.realEndDate;
                        flight.initialFlight.line = i;
                        break;
                    } else if (i === temp.length) {
                        temp.push(flight.initialFlight.realEndDate);
                        flight.initialFlight.line = i;
                        break;
                    }
                }
                lines = temp;
            }
        });
    }

    filterFlights(): void {
        // Keep reference to currently displayed flights, in order to play their exit animations
        let currentFlights = this.flightsDisplayed;
        if (this.lastNavigation === 'left') {
            currentFlights.forEach((f) => {
                f.class = 'slide-out-right';
            });
        } else if (this.lastNavigation === 'right') {
            currentFlights.forEach((f) => {
                f.class = 'slide-out-left';
            });
        }

        const min = new Date(this.checkpoint);
        min.setHours(min.getHours() + this.counter);
        const max = new Date(min);
        max.setHours(max.getHours() + this.windowTime);
        this.flightsDisplayed = this._flights
            .filter((el) => el.initialFlight.realStartDate.getTime() < max.getTime() && el.initialFlight.realEndDate.getTime() > min.getTime())
            .map((f) => {
                const displayedFlight: DisplayedFlight = {
                    width: (f.initialFlight.flightDuration * 100) / (this.windowTime * 60),
                    marginLeft:
                        (100 *
                            ((f.initialFlight.realStartDate.getDay() * 24 + f.initialFlight.realStartDate.getHours()) * 60 +
                                f.initialFlight.realStartDate.getMinutes() -
                                (this.checkpoint.getDay() * 24 + this.checkpoint.getHours() + this.counter) * 60)) /
                        (this.windowTime * 60),
                    isOverflowingLeft: false,
                    isOverflowingRight: false,
                    class: '',
                    ...f,
                };
                if (displayedFlight.marginLeft < 0) {
                    displayedFlight.width = displayedFlight.width + displayedFlight.marginLeft;
                    displayedFlight.marginLeft = 0;
                    displayedFlight.isOverflowingLeft = true;
                }
                if (displayedFlight.width > 100) {
                    displayedFlight.isOverflowingRight = true;
                }
                // Set the good class in order to play the entrance animation
                if (this.lastNavigation === 'left') {
                    displayedFlight.class = 'slide-in-left';
                } else if (this.lastNavigation === 'right') {
                    displayedFlight.class = 'slide-in-right';
                }
                return displayedFlight;
            });

        // If currentFlights and flightsDisplayed are the same, cancel the clear
        clearTimeout(this._currentAnimationRef);
        if (this.lastNavigation) {
            // Concat currentFlights to new flights, in order to play their exit animations
            currentFlights = currentFlights.filter((f) => this.flightsDisplayed.indexOf(f) < 0);
            this.flightsDisplayed = this.flightsDisplayed.concat(currentFlights);

            // Delete these flights after 1s, once the animations are done
            this._currentAnimationRef = setTimeout(() => {
                this.flightsDisplayed = this.flightsDisplayed.filter((el) => {
                    return currentFlights.indexOf(el) < 0;
                });
            }, 1000);
        }
    }

    back(delta?: number): void {
        this.lastNavigation = 'left';
        if (this.counter < 0) {
            this.counter = 0;
            this.displayedDay = this.checkpoint;
            this.updateDisplay();
        } else {
            this.counter -= delta ? delta : this.windowTime;
            this.displayedDay.setHours(this.displayedDay.getHours() - (delta ? delta : this.windowTime));
            this.updateDisplay();
        }

        // Reset hover state once button disabled
        if (this.counter === 0) {
            this.leftButtonHover = false;
        }
    }

    next(delta?: number): void {
        this.lastNavigation = 'right';
        if (this.counter > 72) {
            this.counter = 72;
        } else {
            this.counter += delta ? delta : this.windowTime;
            this.displayedDay.setHours(this.displayedDay.getHours() + (delta ? delta : this.windowTime));
            this.updateDisplay();
        }

        // Reset hover state once button disabled
        if (this.counter === 72 || this.counter + this.windowTime === 72) {
            this.rightButtonHover = false;
        }
    }

    createRange(start: number, end: number, tick: number): { value: Date; class: string }[] {
        const items = [];
        for (let i = start; i < end; i = i + tick) {
            const date = new Date();
            date.setHours(i);
            const valueToInsert: { value: Date; class: string } = { value: date, class: '' };
            if (this.lastNavigation === 'left') {
                valueToInsert.class = 'slide-in-left';
            } else if (this.lastNavigation === 'right') {
                valueToInsert.class = 'slide-in-right';
            }
            items.push(valueToInsert);
        }
        return items;
    }

    updateDisplay(): void {
        this.displayedHours = this.createRange(
            this.checkpoint.getHours() + this.counter,
            this.counter + this.checkpoint.getHours() + this.windowTime,
            this.windowTime / 6,
        );
        this.filterFlights();
        const dates = this.displayedHours.map((d) => d.value.toLocaleString('fr-FR'));
        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];
        this._mixPanelService.track('show-flights-between-dates', {
            dates: [firstDate, lastDate],
        });
    }

    closeDetails(): void {
        this.currentFlight = null;
    }

    displayCurrentDay(): void {
        this.lastNavigation = 'left';
        this.counter = 0;
        this.displayedDay = this.coreService.getCurrentDate();
        this.updateDisplay();
    }

    getWidth(): number {
        return document.querySelector('.coming-flights-header')!.clientWidth;
    }

    getElapsedTimeWidth(flight: DisplayedFlight): number {
        const start = Math.max(flight.initialFlight.realStartDate.getTime(), this.checkpoint.getTime());
        const end = Math.min(flight.initialFlight.realEndDate.getTime(), this.checkpoint.getTime() + this.windowTime * 3600000);
        return (100 * (this.coreService.getCurrentDate()?.getTime() - start)) / (end - start);
    }

    get isCurrentDay(): boolean {
        return this.displayedHours[0].value.getDay() === this.coreService.getCurrentDate()?.getDay();
    }

    getFlightTagWidthPx(flight: DisplayedFlight): number {
        return (flight.width * this.getWidth()) / 100;
    }

    setTriggerTimeWindowButtonAnim(value: boolean): void {
        if (value) {
            this.triggerTimeWindowButtonAnim = true;
        } else {
            setTimeout(() => (this.triggerTimeWindowButtonAnim = false), 200);
        }
    }
}
