import { Component } from '@angular/core';
import { MixPanelService } from '../../services';

@Component({
    selector: 'ffp-service',
    templateUrl: './service.component.html',
})
export class ServiceComponent {
    constructor(private readonly _mixPanelService: MixPanelService) {}
}
