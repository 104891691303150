import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SassHelperService {
    private _bodyStyles: CSSStyleDeclaration = window.getComputedStyle(document.body);

    constructor() {}

    public getProperty(propertyName: string): string {
        return this._bodyStyles.getPropertyValue('--ffp-' + propertyName);
    }
}
