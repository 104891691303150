import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ForecastRoutingModule } from './forecast-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ForecastComponent } from './forecast.component';

import { ForecastCardComponent } from './components/forecast-card/forecast-card.component';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { ComingFlightsComponent } from './components/coming-flights/coming-flights.component';
import { FormatCurrentTimePipe, FormatFlightHour, FormatTimePipe, GetDatePipe } from './pipes/format-time.pipe';
import { FlightTagComponent } from './components/coming-flights/components/flight-tag/flight-tag.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        ForecastComponent,
        ForecastCardComponent,
        ComingFlightsComponent,
        FormatTimePipe,
        FormatCurrentTimePipe,
        FlightTagComponent,
        FormatFlightHour,
        GetDatePipe,
    ],
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Green Aviation
        ForecastRoutingModule,
        SharedModule,
        QtmComponentLibraryModule,
        MatSliderModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
    ],
    exports: [GetDatePipe],
    providers: [],
})
export class ForecastModule {}
