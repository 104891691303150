import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Card, ComparisonSearchCard } from '../../models/card.interface';
import { formatDate } from '@angular/common';
import { Flight } from '../../models/flight.interface';
import { Trajectory } from '../../../../shared/models/trajectory.interface';
import { ExplorerTableInterface } from '../../models/explorer-table.interface';

@Injectable({
    providedIn: 'root',
})
export class ClimateAnalyticsService {
    private _visibleTrajectories: string[] = [];

    constructor(private httpClient: HttpClient) {}

    public addVisibleTrajectory(trajId: string) {
        if (trajId === 'flight') {
            this._visibleTrajectories.unshift(trajId);
        } else {
            this._visibleTrajectories.push(trajId);
        }
    }

    public resetVisibleTrajectory(): void {
        this._visibleTrajectories = [];
    }

    public removeVisibleTrajectory(trajId: string) {
        this._visibleTrajectories = this._visibleTrajectories.filter((el) => el !== trajId);
    }

    public getVisibleTrajectories(): string[] {
        return this._visibleTrajectories;
    }

    public getClimateAnalyticsCards(
        date: string | number | Date,
        page: number,
        pageSize: number,
        startAirportCode?: string,
        endAirportCode?: string,
    ): Observable<Card[]> {
        const formattedDate = formatDate(date, 'yyyy-MM-dd', 'en');
        let params = '&date=' + formattedDate + '&page=' + page + '&pageSize=' + pageSize;
        if (startAirportCode) {
            params += '&startAirportCode=' + startAirportCode;
        }
        if (endAirportCode) {
            params += '&endAirportCode=' + endAirportCode;
        }
        return this.httpClient.get<Card[]>(environment.backendApi + '/api/flights/climateAnalytics?' + params);
    }

    public getFlightsFromCityPair(
        startDate: string | number | Date,
        startAirportCode: string,
        endAirportCode: string,
        endDate?: string | number | Date,
    ): Observable<ExplorerTableInterface> {
        const validDate = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
        let formattedStartDate: string;
        try {
            formattedStartDate = formatDate(startDate, 'yyyy-MM-dd', 'en');
            if (!validDate.test(formattedStartDate)) {
                throw new Error();
            }
        } catch (_) {
            throw new ErrorEvent('Malformed parameter', { lineno: 400 });
        }
        let params = `startDate=${formattedStartDate}&startAirportCode=${startAirportCode}&endAirportCode=${endAirportCode}`;

        if (endDate) {
            let formattedEndDate: string;
            try {
                formattedEndDate = formatDate(endDate, 'yyyy-MM-dd', 'en');
                if (!validDate.test(formattedEndDate)) {
                    throw new Error();
                }
            } catch (_) {
                throw new ErrorEvent('Malformed parameter', { lineno: 400 });
            }

            params += `&endDate=${formattedEndDate}`;
        }
        return this.httpClient.get<ExplorerTableInterface>(environment.backendApi + '/api/flights/cityPair?' + params);
    }

    public getFlights(
        flightNumberPrefix: string | Flight,
        startAirport: string,
        endAirport: string,
        acType: string,
        startDate: string | undefined,
        page: number,
        pageSize: number,
    ): Observable<Flight[]> {
        let options = '';
        if (flightNumberPrefix) {
            if (typeof flightNumberPrefix === 'string') {
                options += '&flightNumber=' + flightNumberPrefix;
            } else {
                options += '&flightNumber=' + flightNumberPrefix.flightNumber;
            }
        }
        if (startAirport) {
            options += '&startAirportCode=' + startAirport;
        }
        if (endAirport) {
            options += '&endAirportCode=' + endAirport;
        }
        if (acType) {
            options += '&aircraftType=' + acType;
        }
        if (startDate) {
            options += '&startDate=' + startDate;
        }
        options += '&page=' + page + '&pageSize=' + pageSize;
        return this.httpClient.get<Flight[]>(environment.backendApi + '/api/flights' + '?' + options);
    }

    public computeEmissions(flightNumber: string, date: string, time: string): Observable<Trajectory> {
        return this.httpClient.get<Trajectory>(
            `${environment.backendApi}/api/compute/flight?flightNumber=${flightNumber}&startDate=${date}&startTime=${time}`,
        );
    }

    public getFilteredOptions(filterName: string, startAirport: string, endAirport: string, acType: string, startDate: string): Observable<string[]> {
        let options = '';
        if (startAirport && filterName !== 'startAirportCode') {
            options += '&startAirportCode=' + startAirport;
        }
        if (endAirport && filterName !== 'endAirportCode') {
            options += '&endAirportCode=' + endAirport;
        }
        if (acType && filterName !== 'aircraftType') {
            options += '&aircraftType=' + acType;
        }
        if (startDate && filterName !== 'startDate') {
            options += '&startDate=' + startDate;
        }
        options += '&pageSize=1000';
        return this.httpClient.get<string[]>(environment.backendApi + '/api/flights/filters/' + filterName + '?' + options);
    }

    public getFlightsToCompare(
        startAirport: string,
        endAirport: string,
        pageSize: number,
        page: number,
        sortBy: string,
        seasonFilterStart?: string,
        seasonFilterEnd?: string,
        dateFilterStart?: string,
        dateFilterEnd?: string,
    ) {
        let params = '?startAirportCode=' + startAirport + '&endAirportCode=' + endAirport + '&pageSize=' + pageSize + '&page=' + page;

        if (sortBy) {
            params += '&sort=' + sortBy;
        }

        if (seasonFilterStart && seasonFilterEnd) {
            params += '&seasonFilterStart=' + seasonFilterStart + '&seasonFilterEnd=' + seasonFilterEnd;
        }

        if (dateFilterStart) {
            params += '&dateFilterStart=' + dateFilterStart;
        }

        if (dateFilterEnd) {
            params += '&dateFilterEnd=' + dateFilterEnd;
        }

        return this.httpClient.get<{ flights: ComparisonSearchCard[] }>(environment.backendApi + '/api/flights/cityPair/filter' + params);
    }

    public getBestFlight(startAirport: string, endAirport: string, emissionMeasure: string, seasonFilterStart?: string, seasonFilterEnd?: string) {
        const emission = emissionMeasure === 'Gwp100' ? 'gwp100' : 'ccf';
        let params = '?startAirportCode=' + startAirport + '&endAirportCode=' + endAirport + '&emissionMeasure=' + emission;
        if (seasonFilterStart && seasonFilterEnd) {
            params += '&seasonFilterStart=' + seasonFilterStart + '&seasonFilterEnd=' + seasonFilterEnd;
        }

        return this.httpClient.get<ComparisonSearchCard>(environment.backendApi + '/api/flights/cityPair/best' + params);
    }
}
