import { Component } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ManagementService } from '../../services/management/management.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Flight } from '../../models/flight.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../environments/environment';
import { Observable, concat } from 'rxjs';
import { LoaderComponent } from '../../../../shared/components';
import { MatDialog } from '@angular/material/dialog';
import { toArray } from 'rxjs/operators';

const AVAILABLE_EXTENSIONS = ['csv', 'json', 'kml'];

@Component({
    selector: 'ffp-admin-upload-flights',
    templateUrl: './upload-flights.component.html',
    styleUrls: ['./upload-flights.component.scss'],
})
export class UploadFlightsComponent {
    public hasBaseDropZoneOver = false;
    public uploader: FileUploader = new FileUploader({
        url: environment.backendApi + '/api/flights/new',
    });
    public displayedColumns: string[] = ['select', 'flightNumber', 'startAirportName', 'endAirportName', 'startDate', 'endDate', 'fileName'];
    public flights = new MatTableDataSource<Flight>([]);
    public selection = new SelectionModel<Flight>(true, []);

    private readonly panelClass = {
        WARNING: 'warning-snackbar',
        ERROR: 'error-snackbar',
        SUCCESS: 'success-snackbar',
        LOADER: 'loader-transparent',
    };

    constructor(
        private readonly _managementService: ManagementService,
        private readonly _snackBar: MatSnackBar,
        private readonly _matDialog: MatDialog,
    ) {}

    public fileOverBase(e: boolean): void {
        this.hasBaseDropZoneOver = e;
    }

    public onFileSelected(newFiles: File[]): void {
        const filenamesAlreadyInQueue = this.uploader.queue.map((f) => f._file.name);

        // Keep file name unicity, prevent to upload duplicates
        for (const file of Object.assign(Array.from(newFiles))) {
            const filename = file.name.split('.');
            const extension = filename[filename.length - 1].toLowerCase();

            if (new Set(filenamesAlreadyInQueue.filter((v, i) => filenamesAlreadyInQueue.indexOf(v) !== i)).has(file.name)) {
                this.uploader.queue.splice(this.uploader.queue.map((f) => f.file.name).indexOf(file.name), 1);
                this._snackBar.open(`The file "${file.name}" is already in queue`, 'OK', {
                    panelClass: this.panelClass.WARNING,
                    duration: 5000,
                });
            } else if (file.size > 50000000) {
                this.uploader.queue.splice(this.uploader.queue.map((f) => f.file.name).indexOf(file.name), 1);
                this._snackBar.open(`The file "${file.name}" is larger than limit (50MB)`, 'OK', {
                    panelClass: this.panelClass.ERROR,
                    duration: 5000,
                });
            } else if (!AVAILABLE_EXTENSIONS.includes(extension)) {
                this.uploader.queue.splice(this.uploader.queue.map((f) => f.file.name).indexOf(file.name), 1);
                this._snackBar.open(`The file "${file.name}" is not supported. \nAvailable extensions: ${AVAILABLE_EXTENSIONS.join(', ')}`, 'OK', {
                    panelClass: this.panelClass.ERROR,
                    duration: 5000,
                });
            } else {
                this.parseAndCheckIfValidCsv(file, extension).then((r) => r);
            }
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.flights.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.flights.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Flight): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.flights.data.indexOf(row) + 1}`;
    }

    private async parseAndCheckIfValidCsv(file: File, extension: string): Promise<void> {
        const reader = new FileReader();
        reader.readAsText(file);
        const result = await new Promise((resolve) => {
            reader.onload = () => {
                resolve(reader.result);
            };
        });
        try {
            const fileContent = result as string;
            let flight: Flight;
            switch (extension) {
                case 'kml': {
                    const kml = new DOMParser().parseFromString(fileContent, 'text/xml');
                    const fn = kml.getElementsByTagName('Document')[0].getElementsByTagName('name')[0].childNodes[0].textContent;
                    const route = kml.getElementsByTagName('Document')[0].getElementsByTagName('Folder')[0].getElementsByTagName('Placemark');
                    const startDate = route[0].getElementsByTagName('name')[0].childNodes[0].textContent;
                    const endDate = route[route.length - 1].getElementsByTagName('name')[0].childNodes[0].textContent;
                    const description = kml.getElementsByTagName('Document')[0].getElementsByTagName('description')[0].childNodes[0].textContent;
                    const descriptionAsHtml = new DOMParser().parseFromString(description!, 'text/html');
                    const departure = descriptionAsHtml.getElementsByTagName('h3')[0];
                    const destination = descriptionAsHtml.getElementsByTagName('h3')[1];
                    flight = {
                        startDate: new Date(startDate!),
                        endDate: new Date(endDate!),
                        flightNumber: fn!.split('/')[0],
                        startAirportName: departure.parentElement!.children[0].textContent!,
                        endAirportName: destination.parentElement!.children[0].textContent!,
                        fileName: file.name,
                    };
                    this.flights.data.push(flight);
                    this.flights._updateChangeSubscription();
                    this.selection.select(flight);
                    break;
                }
                case 'json': {
                    const json = JSON.parse(fileContent);
                    Object.keys(json).forEach((key) => {
                        flight = {
                            startDate: new Date(json[key].track4d[0].time * 1000),
                            endDate: new Date(json[key].track4d[0].time * 1000),
                            flightNumber: json[key].flightNumber ? json[key].flightNumber : json[key].callsign,
                            startAirportName: json[key].adep,
                            endAirportName: json[key].ades,
                            fileName: file.name,
                        };
                        this.flights.data.push(flight);
                        this.flights._updateChangeSubscription();
                        this.selection.select(flight);
                    });
                    break;
                }
                case 'csv': {
                    const flightNumber = file.name.split('fn=')[1].split('dt')[0].trim();
                    const startAirportName = file.name.split('dep=')[1].split('arr')[0].trim().substring(0, 3);
                    const endAirportName = file.name.split('arr=')[1].split('fn')[0].trim().substring(0, 3);
                    const startDateQAR = file.name.split('dt=')[1].split('.csv')[0].trim();
                    const startYear = startDateQAR.substring(0, 4);
                    const startMonth = startDateQAR.substring(4, 6);
                    const startDay = startDateQAR.substring(6, 8);
                    flight = {
                        startDate: new Date(startYear + '-' + startMonth + '-' + startDay),
                        endDate: new Date(startYear + '-' + startMonth + '-' + startDay),
                        flightNumber,
                        startAirportName,
                        endAirportName,
                        fileName: file.name,
                    };
                    this.flights.data.push(flight);
                    this.flights._updateChangeSubscription();
                    this.selection.select(flight);

                    break;
                }
            }
        } catch (error) {
            this.uploader.queue.splice(this.uploader.queue.map((f) => f.file.name).indexOf(file.name), 1);
            this._snackBar.open(`The file "${file.name}" is malformed`, 'OK', {
                panelClass: this.panelClass.ERROR,
                duration: 5000,
            });
            console.error(error);
        }
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.flights.filter = filterValue.trim().toLowerCase();
    }

    import(): void {
        const loaderRef = this._matDialog.open(LoaderComponent, {
            panelClass: this.panelClass.LOADER,
            disableClose: true,
        });
        const filesToRemove: FileItem[] = [];
        const filenamesToUpload = this.selection.selected.map((f) => f.fileName);
        const requests: Observable<{ message: string }>[] = [];
        this.uploader.queue.forEach((f) => {
            if (filenamesToUpload.includes(f.file.name)) {
                const flightIndex = this.flights.data.findIndex((ff) => ff.fileName === f.file.name);
                f.index = flightIndex;
                filesToRemove.push(f);
                requests.push(
                    this._managementService.postFlights(
                        f._file,
                        this.flights.data[flightIndex].startAirportName + ' - ' + this.flights.data[flightIndex].endAirportName,
                    ),
                );
            }
        });

        concat(...requests)
            .pipe(toArray())
            .subscribe(
                () => {
                    const flightIndexes = filesToRemove
                        .map((f) => f.index)
                        .sort((a, b) => a! - b!)
                        .reverse();
                    const filesIndexes = filesToRemove
                        .map((f) => this.uploader.queue.indexOf(f))
                        .sort((a, b) => a - b)
                        .reverse();
                    loaderRef.close();
                    this._snackBar.open('Successfully uploaded', '', {
                        panelClass: this.panelClass.SUCCESS,
                        duration: 2000,
                    });

                    filesIndexes.forEach((i) => {
                        this.uploader.queue.splice(i, 1);
                    });

                    flightIndexes.forEach((i) => {
                        this.selection.deselect(this.flights.data[i!]);
                        this.flights.data.splice(i!, 1);
                    });
                    this.flights._updateChangeSubscription();
                },
                (error) => {
                    loaderRef.close();
                    if (error.status === 400) {
                        this._snackBar.open('Flight already uploaded', '', {
                            panelClass: this.panelClass.ERROR,
                            duration: 2000,
                        });
                    } else {
                        this._snackBar.open('An error occurred on server side', '', {
                            panelClass: this.panelClass.ERROR,
                            duration: 2000,
                        });
                    }
                },
            );
    }

    get flightsToImport(): number {
        return this.selection.selected.length;
    }
}
