import { Component, Input, OnInit } from '@angular/core';
import { Card } from '../../../../models/card.interface';
import { CoreService } from '../../../../../../core/services/core/core.service';
import { AutoConvert, ConvertGramsToTons, ConvertToPicoKelvin } from '../../../../../../shared/pipes/format-data.pipe';

@Component({
    selector: 'ffp-analytics-city-pair-card',
    templateUrl: './city-pair-card.component.html',
    styleUrls: ['./city-pair-card.component.scss'],
    providers: [AutoConvert, ConvertGramsToTons, ConvertToPicoKelvin],
})
export class CityPairCardComponent implements OnInit {
    @Input()
    data!: Card;
    public values!: {
        co2: number;
        h2o: number;
        nox: number;
        contrails: number;
    };
    public potentialSavingRatio!: string;
    public emissions!: {
        total: number;
        totalCo2: number;
        totalH2o: number;
        totalContrails: number;
        totalNox: number;
        potentialSaving: number;
        mean: number;
        monthDelta: number;
    };

    constructor(
        public coreService: CoreService,
        private _autoConvert: AutoConvert,
        private _convertGramsToTons: ConvertGramsToTons,
        private _convertToPicoKelvin: ConvertToPicoKelvin,
    ) {}

    ngOnInit(): void {
        this.emissions = {
            total: this.data[('total' + this.coreService.emissionMeasure) as keyof Card] as number,
            totalCo2: this.data[('total' + this.coreService.emissionMeasure + 'Co2') as keyof Card] as number,
            totalH2o: this.data[('total' + this.coreService.emissionMeasure + 'H2o') as keyof Card] as number,
            totalContrails: this.data[('total' + this.coreService.emissionMeasure + 'Contrails') as keyof Card] as number,
            totalNox: this.data[('total' + this.coreService.emissionMeasure + 'Nox') as keyof Card] as number,
            potentialSaving: this.data[('potential' + this.coreService.emissionMeasure + 'Saving') as keyof Card] as number,
            mean: this.data[('mean' + this.coreService.emissionMeasure) as keyof Card] as number,
            monthDelta: this.data[(this.coreService.emissionMeasure.toLowerCase() + 'MonthDelta') as keyof Card] as number,
        };
        if (this.coreService.emissionMeasure.toLowerCase() === 'ccf') {
            // in pK
            this.values = {
                co2: parseFloat(this._convertToPicoKelvin.transform(this.emissions.totalCo2 / this.data.nbFlights)) || 0,
                h2o: parseFloat(this._convertToPicoKelvin.transform(this.emissions.totalH2o / this.data.nbFlights)) || 0,
                nox: parseFloat(this._convertToPicoKelvin.transform(this.emissions.totalNox / this.data.nbFlights)) || 0,
                contrails: parseFloat(this._convertToPicoKelvin.transform(this.emissions.totalContrails / this.data.nbFlights)) || 0,
            };
        } else {
            // in t
            this.values = {
                co2: parseFloat(this._convertGramsToTons.transform(this.emissions.totalCo2 / this.data.nbFlights)) || 0,
                h2o: parseFloat(this._convertGramsToTons.transform(this.emissions.totalH2o / this.data.nbFlights)) || 0,
                nox: parseFloat(this._convertGramsToTons.transform(this.emissions.totalNox / this.data.nbFlights)) || 0,
                contrails: parseFloat(this._convertGramsToTons.transform(this.emissions.totalContrails / this.data.nbFlights)) || 0,
            };
        }

        this.potentialSavingRatio = Math.ceil((this.emissions.potentialSaving / this.emissions.total) * 100).toFixed();
    }
}
