import { Component, Input } from '@angular/core';

@Component({
    selector: 'ffp-data-delta',
    templateUrl: './data-delta.component.html',
    styleUrls: ['./data-delta.component.scss'],
})
export class DataDeltaComponent {
    @Input() set value(value: number) {
        this.isPositive = value >= 0;
    }

    @Input() filled: boolean = false;

    public isPositive: boolean = false;

    constructor() {}
}
