import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Flight } from '../../../../models/flight.interface';
import { MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { FormatDate } from '../../../../../../shared/pipes/format-data.pipe';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SassHelperService } from '../../../../../../core/services';
import { ClimateAnalyticsService } from '../../../../services/climate-analytics/climate-analytics.service';
import { CoreService } from '../../../../../../core/services/core/core.service';

@Component({
    selector: 'ffp-analytics-map-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss', '../../../../../../shared/style/search-bar/search-bar-shared.scss'],
    providers: [
        FormatDate,
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'search-bar-select-overlay-panel' },
        },
    ],
})
export class SearchBarComponent implements OnInit, AfterViewInit {
    public focusedElement: string | null = null;
    public areInputShown = false;
    public isLoading = false;
    public isAdvancedSearchEnable = false;

    public departureAirportsLoaded = false;
    public departureAirports: string[] = [];
    public departureAirportsFiltered: string[] = [];
    public arrivalAirportsLoaded = false;
    public arrivalAirports: string[] = [];
    public arrivalAirportsFiltered: string[] = [];
    public aircraftTypesLoaded = false;
    public aircraftTypes: string[] = [];
    public aircraftTypesFiltered: string[] = [];
    public startDatesLoaded = false;
    public startDates: string[] = [];
    public flightsLoaded = false;
    public moreFlightsLoading = false;
    public flights: Flight[] = [];

    public flightForm!: FormGroup;
    public initialFlightFormValues:
        | {
              departure: string;
              arrival: string;
              aircraftType: string;
              date: string;
              flightNumber: string;
          }
        | undefined;

    public openSearchMode = false;
    public errors = null;
    public flightsPage = 1;
    public flightsPageSize = 30;
    private tempInput: string | null = null;

    @Input() isTrajectoryDisplayed = false;
    @Input() flightDisplayed: {
        startTime: string;
        startDate: string;
        flightNumber: string;
    } | null = null;

    @Output() triggerSearchBarAnim = new EventEmitter<boolean>();
    @Output() submitForm = new EventEmitter<{
        departure: string;
        arrival: string;
        aircraftType: string;
        date: string;
        flightNumber: Flight;
    }>();
    @Output() triggerFilter = new EventEmitter<boolean>();

    @ViewChild('autoFlightNumber') flightNumberAutocomplete!: MatAutocomplete;
    @ViewChild('autoFlightArrival') flightArrivalAutocomplete!: MatAutocomplete;
    @ViewChild('autoFlightDep') flightDepAutocomplete!: MatAutocomplete;
    @ViewChild('autoAircraftType') flightAircraftTypeAutocomplete!: MatAutocomplete;
    @ViewChild('triggerFlightNumber')
    flightNumberTrigger!: MatAutocompleteTrigger;
    @ViewChild('triggerFlightDeparture')
    flightDepTrigger!: MatAutocompleteTrigger;
    @ViewChild('triggerFlightArrival')
    flightArrivalTrigger!: MatAutocompleteTrigger;
    @ViewChild('triggerAircraftType')
    aircraftTypeTrigger!: MatAutocompleteTrigger;
    @ViewChild('picker') datePicker!: MatDatepicker<Date>;

    public animSearchBar = false;

    constructor(
        private readonly _formBuilder: FormBuilder,
        private _formatDate: FormatDate,
        private _coreService: CoreService,
        private _sassHelperService: SassHelperService,
        private readonly _climateAnalyticsService: ClimateAnalyticsService,
    ) {}

    get disableClearButton(): boolean {
        return (
            this.flightForm.get('departure')!.value === '' &&
            this.flightForm.get('arrival')!.value === '' &&
            this.flightForm.get('aircraftType')!.value === '' &&
            this.flightForm.get('date')!.value === ''
        );
    }

    ngOnInit(): void {
        this.initializeForm();
        this.flightForm
            .get('departure')!
            .valueChanges.pipe(debounceTime(300))
            .subscribe((value) => {
                this.departureAirportsFiltered = this.departureAirports.filter((airport) => {
                    return airport.toLowerCase().includes(value.toLowerCase());
                });
            });
        this.flightForm
            .get('arrival')!
            .valueChanges.pipe(debounceTime(300))
            .subscribe((value) => {
                this.arrivalAirportsFiltered = this.arrivalAirports.filter((airport) => {
                    return airport.toLowerCase().includes(value.toLowerCase());
                });
            });
        this.flightForm
            .get('aircraftType')
            ?.valueChanges.pipe(debounceTime(300))
            .subscribe((value) => {
                this.aircraftTypesFiltered = this.aircraftTypes.filter((ac) => {
                    return ac.toLowerCase().includes(value.toLowerCase());
                });
            });
        this.flightForm
            .get('flightNumber')
            ?.valueChanges.pipe(debounceTime(300))
            .subscribe(() => {
                this.flightsPage = 1;
                this.refreshFlights();
            });

        if (this.flightDisplayed) {
            this.refreshFlights(true);
        }
    }

    ngAfterViewInit(): void {
        document.querySelectorAll('input')?.forEach((el) => {
            el.setAttribute('size', (el.getAttribute('placeholder') ? el.getAttribute('placeholder')!.length : 22).toString());
        });
    }

    initializeForm(): void {
        this.flightForm = this._formBuilder.group({
            departure: '',
            arrival: '',
            aircraftType: '',
            date: '',
            flightNumber: ['', [Validators.required, this._autocompleteValidator()]],
        });
        this.initialFlightFormValues = this.flightForm.value;
    }

    setFocusedElement(value: string | null, trigger: MatAutocompleteTrigger | null = null): void {
        this.focusedElement = value;
        if (value) {
            this.tempInput = this.flightForm.get(value)!.value;
        }

        if (value === null && trigger !== null) {
            setTimeout(() => trigger.closePanel(), 200);

            if (trigger === this.flightDepTrigger && this.flightForm.get('departure')!.value) {
                const options = this.flightDepAutocomplete.options.filter(
                    (o: MatOption) => o.value?.toLowerCase() === this.flightForm.get('departure')!.value.toLowerCase(),
                );
                if (options.length === 1) {
                    options[0].select();
                } else if (this.departureAirportsLoaded) {
                    this.flightForm.get('departure')!.reset('');
                } else {
                    this.flightForm.get('departure')!.reset(this.tempInput);
                }
            }
            if (trigger === this.flightArrivalTrigger && this.flightForm.get('arrival')!.value) {
                const options = this.flightArrivalAutocomplete.options.filter(
                    (o: MatOption) => o.value?.toLowerCase() === this.flightForm.get('arrival')!.value.toLowerCase(),
                );
                if (options.length === 1) {
                    options[0].select();
                } else if (this.arrivalAirportsLoaded) {
                    this.flightForm.get('arrival')!.reset('');
                } else {
                    this.flightForm.get('arrival')!.reset(this.tempInput);
                }
            }
            if (trigger === this.aircraftTypeTrigger && this.flightForm.get('aircraftType')!.value) {
                const options = this.flightAircraftTypeAutocomplete.options.filter(
                    (o) => o.value?.toLowerCase() === this.flightForm.get('aircraftType')!.value.toLowerCase(),
                );
                if (options.length === 1) {
                    options[0].select();
                } else if (this.aircraftTypesLoaded) {
                    this.flightForm.get('aircraftType')!.reset('');
                } else {
                    this.flightForm.get('aircraftType')!.reset(this.tempInput);
                }
            }
            this.tempInput = null;
        }
    }

    public getDateClass(): MatCalendarCellClassFunction<Date> {
        return (cellDate, view) => {
            const flightsDateArray = this.startDates.map((f) => this.getDateFromString(f));
            if (flightsDateArray.length === 0) {
                return 'date-disabled';
            }

            if (view === 'multi-year') {
                const date = cellDate.getFullYear();
                const flightsYearArray = [...new Set(flightsDateArray.map((d) => d.getFullYear()))];
                return flightsYearArray.indexOf(date) > -1 ? 'date-containing-flight' : 'date-disabled';
            } else if (view === 'year') {
                const month = cellDate.getMonth();
                const year = cellDate.getFullYear();
                const flightsMonthYearArray = [...new Set(flightsDateArray.map((d) => d.getMonth() + ';' + d.getFullYear()))];
                return flightsMonthYearArray.indexOf(month + ';' + year) > -1 ? 'date-containing-flight' : 'date-disabled';
            } else if (view === 'month') {
                const month = cellDate.getMonth();
                const year = cellDate.getFullYear();
                const date = cellDate.getDate();
                const flightsDayMonthYearArray = [...new Set(flightsDateArray.map((d) => d.getDate() + ';' + d.getMonth() + ';' + d.getFullYear()))];
                return flightsDayMonthYearArray.indexOf(date + ';' + month + ';' + year) > -1 ? 'date-containing-flight' : 'date-disabled';
            }

            return '';
        };
    }

    public getDateFromString(dateString: string): Date {
        return new Date(dateString);
    }

    public submit(): void {
        this.openSearchMode = false;
        this.triggerFilter.emit(false);
        this.submitForm.emit(this.flightForm.value);
    }

    public formatAutocompleteOptionText(option: Flight): string {
        return option ? option.flightNumber : '';
    }

    public resetAutoInput(auto: MatAutocomplete | null, elementName: string, trigger: MatAutocompleteTrigger | MatDatepicker<Date>): void {
        if (elementName !== 'date') {
            auto!.options.forEach((item) => {
                item.deselect();
            });
        }
        this.flightForm.get(elementName)!.reset('');

        if (this.focusedElement === elementName && elementName !== 'date') {
            (trigger as MatAutocompleteTrigger).openPanel();
        } else if (this.focusedElement === elementName && elementName === 'date') {
            (trigger as MatDatepicker<Date>).open();
        }
    }

    public handleInput(event: Event, auto: MatAutocomplete, trigger: MatAutocompleteTrigger): void {
        // Reset input if empty input
        if ((event.target as HTMLInputElement)?.value === '') {
            this.resetAutoInput(auto, (event.target as HTMLInputElement)!.getAttribute('formControlName')!, trigger);
            trigger.openPanel();
        }
    }

    public handleKeyDown($event: KeyboardEvent, auto: MatAutocomplete, trigger: MatAutocompleteTrigger): void {
        // Deselect selected item if user modify input value
        const selectedOption = auto.options.find((el) => el.selected);
        if (selectedOption !== undefined && $event.key !== 'Enter' && $event.key !== 'Tab') {
            selectedOption.deselect();
            trigger.openPanel();
        }
    }

    public clearForm(): void {
        this.flightForm.get('departure')!.reset('');
        this.flightForm.get('arrival')!.reset('');
        this.flightForm.get('aircraftType')!.reset('');
        this.flightForm.get('date')!.reset('');
    }

    fieldsAutocomplete(): void {
        if (this.flightForm.get('flightNumber')!.value && typeof this.flightForm.get('flightNumber')!.value !== 'string') {
            this.flightForm.get('departure')!.setValue(this.flightForm.get('flightNumber')!.value.startAirportCode);
            this.flightForm.get('arrival')!.setValue(this.flightForm.get('flightNumber')!.value.endAirportCode);
            this.flightForm.get('aircraftType')!.setValue(this.flightForm.get('flightNumber')!.value.aircraftType);
            this.flightForm.get('date')!.setValue(this.flightForm.get('flightNumber')!.value.startDate);
        }
    }

    enableAdvancedSearch(): void {
        this.isAdvancedSearchEnable = !this.isAdvancedSearchEnable;
        setTimeout(() => {
            document
                .querySelector('.filter')
                ?.querySelectorAll('input')
                ?.forEach((el) => {
                    el.setAttribute('size', Math.max(el.getAttribute('placeholder')!.length, 22).toString());
                });
            this.fieldsAutocomplete();
        }, 1);
    }

    handleClickOnSearchBar(): void {
        if (this.isTrajectoryDisplayed && !this.openSearchMode) {
            this.openSearchMode = true;
            this.animSearchBar = true;
            this.enableAdvancedSearch();
            document
                .querySelector('body')!
                .style.setProperty(
                    '--past-flight-analysis-form-width',
                    (document.querySelector('.form-item.flightNumber') as HTMLElement).offsetWidth + 'px',
                );
            this.triggerFilter.emit(true);
        }
    }

    handleSelection(): void {
        if (typeof this.flightForm.get('flightNumber')!.value !== 'string') {
            if (this.flightForm.get('departure')!.value) {
                if (!this.flightForm.get('flightNumber')!.value.startAirportCode.includes(this.flightForm.get('departure')!.value)) {
                    this.flightForm.get('flightNumber')!.setValue('');
                }
            }
        }
        if (typeof this.flightForm.get('flightNumber')!.value !== 'string') {
            if (this.flightForm.get('arrival')!.value) {
                if (!this.flightForm.get('flightNumber')!.value.endAirportCode.includes(this.flightForm.get('arrival')!.value)) {
                    this.flightForm.get('flightNumber')!.setValue('');
                }
            }
        }
        if (typeof this.flightForm.get('flightNumber')!.value !== 'string') {
            if (this.flightForm.get('aircraftType')!.value) {
                if (!this.flightForm.get('flightNumber')!.value.aircraftType.includes(this.flightForm.get('aircraftType')!.value)) {
                    this.flightForm.get('flightNumber')!.setValue('');
                }
            }
        }
        if (typeof this.flightForm.get('flightNumber')!.value !== 'string') {
            if (this.flightForm.get('date')!.value) {
                if (!this.flightForm.get('flightNumber')!.value.startDate.includes(this.flightForm.get('date')!.value)) {
                    this.flightForm.get('flightNumber')!.setValue('');
                }
            }
        }
    }

    private _autocompleteValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const flights = this.flights;
            if (
                control.value != null &&
                control.value !== '' &&
                flights.findIndex((f) => f.flightNumber === control.value.flightNumber && f.startDate === control.value.startDate) === -1
            ) {
                return { invalidOption: true };
            }
            return null;
        };
    }

    refreshFlightFilter(filter: string): void {
        switch (filter) {
            case 'startAirportCode':
                this.departureAirports = [];
                this.departureAirportsFiltered = [];
                this.departureAirportsLoaded = false;
                break;
            case 'endAirportCode':
                this.arrivalAirportsLoaded = false;
                this.arrivalAirports = [];
                this.arrivalAirportsFiltered = [];
                break;
            case 'aircraftType':
                this.aircraftTypesLoaded = false;
                this.aircraftTypes = [];
                this.aircraftTypesFiltered = [];
                break;
            case 'startDate':
                this.startDatesLoaded = false;
                this.startDates = [];
                break;
            default:
                break;
        }
        this._climateAnalyticsService
            .getFilteredOptions(
                filter,
                this.flightForm.get('departure')!.value,
                this.flightForm.get('arrival')!.value,
                this.flightForm.get('aircraftType')!.value,
                this._formatDate.transform(this.flightForm.get('date')!.value, 'yyyy-mm-dd')!,
            )
            .subscribe(
                (result) => {
                    switch (filter) {
                        case 'startAirportCode':
                            this.departureAirports = result;
                            this.departureAirportsFiltered = result;
                            if (this.flightForm.get('departure')!.value) {
                                // Re apply filter
                                this.departureAirportsFiltered = this.departureAirports.filter((airport) => {
                                    return airport.toLowerCase().includes(this.flightForm.get('departure')!.value.toLowerCase());
                                });

                                // Re select option
                                setTimeout(() => {
                                    const option = this.flightDepAutocomplete.options.find(
                                        (f: MatOption) => f.value === this.flightForm.get('departure')!.value,
                                    );
                                    if (option != null && !option.selected) {
                                        // @ts-expect-error: No other way to update 'selected' property
                                        option._selected = true;
                                    }
                                }, 100);
                            }
                            this.departureAirportsLoaded = true;
                            break;
                        case 'endAirportCode':
                            this.arrivalAirports = result;
                            this.arrivalAirportsFiltered = result;
                            if (this.flightForm.get('arrival')!.value) {
                                // Re apply filter
                                this.arrivalAirportsFiltered = this.arrivalAirports.filter((airport) => {
                                    return airport.toLowerCase().includes(this.flightForm.get('arrival')!.value.toLowerCase());
                                });

                                // Re select option
                                setTimeout(() => {
                                    const option = this.flightArrivalAutocomplete.options.find(
                                        (f: MatOption) => f.value === this.flightForm.get('arrival')!.value,
                                    );
                                    if (option != null && !option.selected) {
                                        // @ts-expect-error: No other way to update 'selected' property
                                        option._selected = true;
                                    }
                                }, 100);
                            }
                            this.arrivalAirportsLoaded = true;
                            break;
                        case 'aircraftType':
                            this.aircraftTypes = result;
                            this.aircraftTypesFiltered = result;
                            if (this.flightForm.get('aircraftType')!.value) {
                                // Re apply filter
                                this.aircraftTypesFiltered = this.aircraftTypes.filter((airport) => {
                                    return airport.toLowerCase().includes(this.flightForm.get('aircraftType')!.value.toLowerCase());
                                });

                                // Re select option
                                setTimeout(() => {
                                    const option = this.flightAircraftTypeAutocomplete.options.find(
                                        (f: MatOption) => f.value === this.flightForm.get('aircraftType')!.value,
                                    );
                                    if (option != null && !option.selected) {
                                        // @ts-expect-error: No other way to update 'selected' property
                                        option._selected = true;
                                    }
                                }, 100);
                            }
                            this.aircraftTypesLoaded = true;
                            break;
                        case 'startDate':
                            this.startDates = result;
                            /* FIXME: Ugly block to refresh datepicker classes one results arrived */
                            (document.querySelector('.mat-calendar-next-button') as HTMLElement).click();
                            setTimeout(() => {
                                (document.querySelector('.mat-calendar-previous-button') as HTMLElement).click();
                            }, 0);
                            /* FIXME: End of block to rework */
                            this.startDatesLoaded = true;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.errors = error;
                    this._coreService.pushNotification({
                        title: 'Past flight analysis',
                        description: 'An error occurred during filter handling, please refresh and try again',
                        icon: 'error',
                        lifeDuration: 'infinite',
                        info: '',
                        index: 0,
                        type: 'ERROR',
                    });
                },
            );
    }

    refreshFlights(init = false): void {
        this.flightsLoaded = false;
        this.flightsPage = 1;
        this.flights = [];
        this._climateAnalyticsService
            .getFlights(
                init ? this.flightDisplayed?.flightNumber : this.flightForm.get('flightNumber')?.value,
                this.flightForm.get('departure')!.value,
                this.flightForm.get('arrival')!.value,
                this.flightForm.get('aircraftType')!.value,
                init ? this.flightDisplayed?.startDate : this._formatDate.transform(this.flightForm.get('date')!.value, 'yyyy-mm-dd'),
                this.flightsPage,
                this.flightsPageSize,
            )
            .subscribe(
                (result) => {
                    this.flights = result;

                    if (this.flightForm.get('flightNumber')!.value) {
                        // Re select option
                        setTimeout(() => {
                            const option = this.flightNumberAutocomplete.options.find(
                                (f: MatOption) => JSON.stringify(f.value) === JSON.stringify(this.flightForm.get('flightNumber')!.value),
                            );
                            if (option != null && !option.selected) {
                                // @ts-expect-error: No other way to update 'selected' property
                                option._selected = true;
                            }
                        }, 100);
                    } else if (init && this.flightDisplayed) {
                        const f = result.find((o) => {
                            return (
                                o.startTime === this.flightDisplayed!.startTime &&
                                o.startDate === this.flightDisplayed!.startDate &&
                                o.flightNumber === this.flightDisplayed!.flightNumber
                            );
                        });
                        this.flightForm.get('flightNumber')!.setValue(f);
                    }

                    if (result.length === this.flightsPageSize) {
                        this.flightsPage += 1;
                    } else {
                        this.flightsPage = 1;
                    }
                    this.flightsLoaded = true;
                    this.errors = null;
                },
                (error) => {
                    this.errors = error;
                    this._coreService.pushNotification({
                        title: 'Past flight analysis',
                        description: 'An error occurred during flights fetching, please refresh and try again.',
                        icon: 'error',
                        lifeDuration: 'infinite',
                        info: '',
                        index: 0,
                        type: 'ERROR',
                    });
                    this.flightNumberTrigger.closePanel();
                },
            );
    }

    loadMoreFlights(): void {
        if (this.moreFlightsLoading || this.flightsPage === 1) {
            return;
        } else {
            this.moreFlightsLoading = true;

            this._climateAnalyticsService
                .getFlights(
                    this.flightForm.get('flightNumber')?.value,
                    this.flightForm.get('departure')?.value,
                    this.flightForm.get('arrival')?.value,
                    this.flightForm.get('aircraftType')?.value,
                    this._formatDate.transform(this.flightForm.get('date')?.value, 'yyyy-mm-dd'),
                    this.flightsPage,
                    this.flightsPageSize,
                )
                .subscribe(
                    (result) => {
                        this.flights.push(...result);
                        if (result.length === this.flightsPageSize) {
                            this.flightsPage += 1;
                        } else {
                            this.flightsPage = 1;
                        }
                        this.moreFlightsLoading = false;
                        this.errors = null;
                    },
                    (error) => {
                        this.errors = error;
                        this._coreService.pushNotification({
                            title: 'Past flight analysis',
                            description: 'An error occurred during while we tried to load more flights, please refresh and try again',
                            icon: 'error',
                            lifeDuration: 'infinite',
                            info: '',
                            index: 0,
                            type: 'ERROR',
                        });
                        this.flightNumberTrigger.closePanel();
                    },
                );
        }
    }
}
