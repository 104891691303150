/* Variable content */
export const svgWidth = 145;
export const svgHeight = 40;
export const heightOverview = 0;
export const margin = {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
};

/* Computed values: DO NOT TOUCH */
export const width = svgWidth - margin.left - margin.right;
export const height = svgHeight - heightOverview - margin.top - margin.bottom;
export const extent = [
    [margin.left, margin.top],
    [width, height],
];
