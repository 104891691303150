import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClimateMapComponent } from './climate-map.component';
import { AuthGuard } from '../../auth.guard';
import { MobileGuard } from '../../mobile.guard';

const routes: Routes = [
    {
        path: '',
        component: ClimateMapComponent,
        canActivate: [MobileGuard, AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClimateMapRoutingModule {}
