import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    override format(date: Date, displayFormat: string): string {
        if (displayFormat === 'input') {
            return date.toLocaleDateString('fr');
        }
        if (displayFormat === 'monthYearLabel') {
            return date.toLocaleString('en-US', {
                month: 'short',
                year: 'numeric',
            });
        }
        return date.toDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric' },
    },
    display: {
        dateInput: 'input',
        monthYearLabel: 'monthYearLabel',
        dateA11yLabel: { year: 'numeric', month: 'long' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    },
};
