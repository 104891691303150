<div class="container" [ngClass]="{ extended: isExtended }">
    <div
        class="content"
        (mouseover)="onHover()"
        (mouseleave)="onLeave()"
        (mousedown)="onMouseDown()"
        (click)="onSelect()"
        [ngClass]="{ disabled: disabled }"
        [ngStyle]="{
            'background-color':
                hover && !disabled
                    ? sassHelperService.getProperty('ffp-' + index + '-5')
                    : isActive
                      ? sassHelperService.getProperty('ffp-' + index + '-10')
                      : '',
        }"
    >
        <img [src]="currentIcon" alt="Menu icon" />
        @if (isExtended) {
            <qtm-typography
                [ngStyle]="{
                    color: (hover && !disabled) || (isActive && !disabled) ? sassHelperService.getProperty('ffp-' + index + '-170') : '',
                }"
                component="body-2"
                fontSize="7"
                [fontWeight]="isActive ? 'bold' : 'normal'"
                [@in-out-animation]
            >
                {{ value }}
            </qtm-typography>
        }
    </div>
    @if (!submenu) {
        <div class="info" [ngClass]="{ disabled: disabled }">
            <div class="title">
                <qtm-typography
                    [ngStyle]="{
                        color: sassHelperService.getProperty('ffp-' + index + '-170'),
                    }"
                    component="body-1"
                    fontSize="6"
                    fontWeight="bold"
                >
                    {{ value }}
                </qtm-typography>
                @if (disabled) {
                    <qtm-typography component="caption-1" fontSize="8" fontWeight="medium" class="coming-soon" tag="p"> COMING SOON </qtm-typography>
                }
            </div>
            <qtm-typography component="caption-1" fontSize="8" fontWeight="medium" class="description" tag="p">
                {{ description }}
            </qtm-typography>
        </div>
    }
    @if (submenu) {
        <div class="submenu" [ngStyle]="{ 'margin-left': menu.offsetWidth - 16 + 'px' }" (mouseover)="onHover()" (mouseleave)="onLeave()">
            <div class="submenu-content">
                <qtm-typography
                    [ngStyle]="{
                        color: sassHelperService.getProperty('ffp-' + index + '-170'),
                    }"
                    fontSize="6"
                    fontWeight="bold"
                    class="submenu-title"
                >
                    {{ value }}
                </qtm-typography>
                @for (item of submenu; track item; let i = $index) {
                    <div
                        class="submenu-item"
                        (mouseover)="submenuItemHovered = i"
                        (mouseout)="submenuItemHovered = -1"
                        (click)="this.coreService.navigate(this.route + item['route'])"
                        [ngStyle]="{
                            'background-color':
                                submenuItemHovered === i
                                    ? sassHelperService.getProperty('ffp-' + index + '-5')
                                    : item['isActive']
                                      ? sassHelperService.getProperty('ffp-' + index + '-10')
                                      : '',
                        }"
                    >
                        <mat-icon
                            class="material-icons-round"
                            [ngStyle]="{
                                color: item['isActive'] || submenuItemHovered === i ? sassHelperService.getProperty('ffp-' + index + '-170') : '',
                            }"
                        >
                            {{ item["icon"] }}
                        </mat-icon>
                        <qtm-typography
                            fontSize="7"
                            fontWeight="normal"
                            [ngStyle]="{
                                color: item['isActive'] || submenuItemHovered === i ? sassHelperService.getProperty('ffp-' + index + '-170') : '',
                            }"
                        >
                            {{ item["value"] }}
                        </qtm-typography>
                    </div>
                }
            </div>
        </div>
    }
</div>
