import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CityPairExplorerComponent } from './components/city-pair-explorer/city-pair-explorer.component';
import { AuthGuard } from '../../auth.guard';
import { MobileGuard } from '../../mobile.guard';
import { PastFlightAnalysisComponent } from './components/past-flights-analysis/past-flight-analysis.component';

const routes: Routes = [
    { path: '', redirectTo: 'city-pair-explorer', pathMatch: 'full' },
    {
        path: 'city-pair-explorer',
        component: CityPairExplorerComponent,
        canActivate: [MobileGuard, AuthGuard],
    },
    {
        path: 'past-flight-analysis',
        component: PastFlightAnalysisComponent,
        canActivate: [MobileGuard, AuthGuard],
    },
    {
        path: 'city-pair-explorer/map',
        component: PastFlightAnalysisComponent,
        canActivate: [MobileGuard, AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClimateAnalyticsRoutingModule {}
