import { Component } from '@angular/core';

@Component({
    selector: 'ffp-warning-dialog',
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent {
    constructor() {}
}
