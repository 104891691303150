<ffp-service></ffp-service>

<div class="green-aviation" [ngClass]="{ lp: !authService.isLoggedIn }">
    @if (coreService.getCurrentService()?.value !== "default" || (authService.isLoggedIn && coreService.getCurrentService()?.value === "default")) {
        <ffp-top-bar></ffp-top-bar>
    }
    <div class="content">
        <ffp-notification-panel></ffp-notification-panel>
        @if (
            coreService.getCurrentService()?.value !== "default" || (authService.isLoggedIn && coreService.getCurrentService()?.value === "default")
        ) {
            <ffp-menu></ffp-menu>
        }
        @if (!isUnderMaintenance) {
            <router-outlet></router-outlet>
        } @else {
            <ffp-maintenance></ffp-maintenance>
        }
    </div>
</div>

<ng-template #CookieInfo>
    <h1 mat-dialog-title>
        Cookies
        <mat-icon mat-dialog-close>close</mat-icon>
    </h1>
    <mat-dialog-content class="mat-typography">
        <strong>
            In compliance with the French National Commission for Data Protection and Liberties (CNIL) Directive of 5 December 2013 concerning
            cookies, and Article 5(3) of European Directive 2002/58/CE amended by Directive 2009/136/CE, Thales Digital Factory informs visitors that
            cookies are used on its website. </strong
        ><br /><br />
        <strong>What are cookies?</strong><br />
        Cookies are small text files, implementing tracking mechanisms, which are, for example, placed and/or read on your computer when visiting a
        website, reading an email, or installing or using software or a mobile application.
        <br /><br />
        <strong>How Flights Footprint uses cookies?</strong><br />
        Flights Footprint does not use cookies to identify you or to track you individually, but in order to operate the website as requested, to
        facilitate navigation thereon as well as for the use of its features, so as to provide you with a seamless experience in the frame of our
        communication purposes.
        <br /><br />
        Flights Footprint uses Analytics tools to measure traffic and usage statistics in order to understand how visitors access and use its website.
        Flights Footprint uses information collected through cookies to improve its website and to compile aggregate statistics about the way its
        website is browsed and used by the visitors. In this respect, please be aware that website collects and process your personal data through the
        use of the Analytics tool on Flights Footprint website.
        <br /><br />
        <strong>Adjust your Internet browser settings</strong><br />
        You can choose to disable the use of cookies at any time. You can also set your browser to notify you when cookies are placed on your computer
        and ask your permission to accept or block them.
        <br /><br />
        You can accept or block cookies on a case-by-case basis or block all cookies completely.
        <br /><br />
        Please remember that changing these settings could affect your ability to access our content and services that require the use of cookies.
        <br />
        If your browser is set to block all cookies, you will not be able to access some of our services. In order to manage cookies in the way that
        best meets your needs, please keep the purpose of cookies in mind when choosing your browser settings.
        <br /><br />
        <strong>Internet Explorer</strong><br />
        Click the Tools button, and then click Internet Options.
        <br />
        Click the Privacy tab, and then, under Settings, move the slider to the top to block all cookies or to the bottom to allow all cookies, and
        then click OK.
        <br /><br />
        <strong>Firefox</strong><br />
        From the Tools menu, select Options.
        <br />
        In the new window, select Privacy and adjust settings.
        <br /><br />
        <strong>Safari</strong><br />
        Choose Preferences, and then click Privacy.
        <br />
        In the "Block cookies" section, specify if and when Safari should accept cookies from websites.
        <br />
        To see an explanation of the options, click the Help button (question mark).
        <br />
        If you want to see which websites store cookies on your computer, click Details.
        <br /><br />
        <strong>Google Chrome</strong><br />
        Click the Chrome menu icon
        <br />
        Select Settings.
        <br />
        Near the bottom of the page, click Show advanced settings.
        <br />
        In the "Privacy" section, click Content settings.
        <br />
        Enable or disable cookies:
        <br />
        To enable cookies, select Allow local data to be set (recommended).
        <br />
        To disable cookies, select Block sites from setting any data.
        <br />
        Click Done to save.
        <br /><br />
        In addition, you may wish to visit
        <a href="http://www.aboutcookies.org">www.aboutcookies.org</a>, which contains comprehensive information on how to do this on a wide variety
        of browsers. <br /><br />
        Moreover, you can use another online service enabling you to manage the advertisement cookies placed our your devices:
        <a href="http://www.youronlinechoices.com/"> http://www.youronlinechoices.com/ </a>
    </mat-dialog-content>
</ng-template>
