import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Trajectory } from '../../../../../../shared/models/trajectory.interface';
import { CoreService } from '../../../../../../core/services/core/core.service';
import { MapService } from '../../../../../../shared/services';
import { ClimateAnalyticsService } from '../../../../services/climate-analytics/climate-analytics.service';
import { AuthService } from '../../../../../../core/services/auth/auth.service';

@Component({
    selector: 'ffp-analytics-past-flights-consumption-visualisation',
    templateUrl: './consumption-visualisation.component.html',
    styleUrls: ['./consumption-visualisation.component.scss'],
})
export class ConsumptionVisualisationComponent implements OnChanges {
    @Input()
    selectedFlight!: Trajectory;

    @Input()
    isBestFlight: boolean = false;

    @Input()
    trajId!: string;

    @Input()
    highlighted = false;

    @Output()
    hoveredFlight: EventEmitter<string | null> = new EventEmitter<string | null>();

    @Output()
    removeFlight: EventEmitter<void> = new EventEmitter();

    @Output()
    changeFlightVisibility: EventEmitter<string> = new EventEmitter<string>();

    public flightDuration: number | null = null;
    public flightDistance: number | null = null;
    public flightMaxAltitude: string | null = null;
    public flightAvgSpeed: number | null = null;
    public percentageUnderContrails: number | null = null;
    public isHovered = false;

    public totalCO2: number | null = null;
    public totalCO2PerPAX: number | null = null;

    AIRCRAFT_PAX = {
        '738': { min: 162, max: 189, '90%': 158, '80%': 140 },
        '73F': { min: 110, max: 132, '90%': 109, '80%': 97 },
        '73H': { min: 162, max: 189, '90%': 158, '80%': 140 },
        A19N: { min: 140, max: 160, '90%': 135, '80%': 120 },
        A20N: { min: 150, max: 194, '90%': 155, '80%': 138 },
        A21N: { min: 180, max: 244, '90%': 191, '80%': 170 },
        A318: { min: 107, max: 136, '90%': 109, '80%': 97 },
        A319: { min: 124, max: 160, '90%': 128, '80%': 114 },
        A320: { min: 150, max: 180, '90%': 149, '80%': 132 },
        A321: { min: 185, max: 220, '90%': 182, '80%': 162 },
        A332: { min: 246, max: 406, '90%': 293, '80%': 261 },
        A333: { min: 277, max: 440, '90%': 323, '80%': 287 },
        A339: { min: 287, max: 440, '90%': 327, '80%': 291 },
        A343: { min: 295, max: 295, '90%': 266, '80%': 236 },
        A359: { min: 315, max: 315, '90%': 284, '80%': 252 },
        A35K: { min: 369, max: 369, '90%': 332, '80%': 295 },
        A388: { min: 525, max: 853, '90%': 620, '80%': 551 },
        AT45: { min: 42, max: 50, '90%': 41, '80%': 37 },
        AT75: { min: 74, max: 78, '90%': 68, '80%': 61 },
        AT76: { min: 74, max: 78, '90%': 68, '80%': 61 },
        B38M: { min: 162, max: 200, '90%': 163, '80%': 145 },
        B39M: { min: 180, max: 180, '90%': 162, '80%': 144 },
        B733: { min: 128, max: 149, '90%': 125, '80%': 111 },
        B734: { min: 146, max: 189, '90%': 151, '80%': 134 },
        B735: { min: 108, max: 132, '90%': 108, '80%': 96 },
        B737: { min: 120, max: 149, '90%': 121, '80%': 108 },
        B738: { min: 162, max: 189, '90%': 158, '80%': 140 },
        B739: { min: 180, max: 215, '90%': 178, '80%': 158 },
        B744: { min: 416, max: 660, '90%': 484, '80%': 430 },
        B748: { min: 467, max: 605, '90%': 482, '80%': 429 },
        B752: { min: 200, max: 239, '90%': 198, '80%': 176 },
        B753: { min: 243, max: 289, '90%': 239, '80%': 213 },
        B763: { min: 218, max: 350, '90%': 256, '80%': 227 },
        B772: { min: 301, max: 440, '90%': 333, '80%': 296 },
        B773: { min: 365, max: 550, '90%': 412, '80%': 366 },
        B77L: { min: 301, max: 440, '90%': 333, '80%': 296 },
        B77W: { min: 365, max: 550, '90%': 412, '80%': 366 },
        B788: { min: 242, max: 252, '90%': 222, '80%': 198 },
        B789: { min: 280, max: 292, '90%': 257, '80%': 229 },
        B78X: { min: 323, max: 323, '90%': 291, '80%': 258 },
        C550: { min: 8, max: 8, '90%': 7, '80%': 6 },
        C56X: { min: 9, max: 9, '90%': 8, '80%': 7 },
        CRJ9: { min: 89, max: 89, '90%': 80, '80%': 71 },
        CRJX: { min: 100, max: 104, '90%': 92, '80%': 82 },
        DH8A: { min: 37, max: 82, '90%': 54, '80%': 48 },
        E135: { min: 37, max: 37, '90%': 33, '80%': 30 },
        E145: { min: 50, max: 50, '90%': 45, '80%': 40 },
        E170: { min: 66, max: 78, '90%': 65, '80%': 58 },
        E190: { min: 96, max: 100, '90%': 88, '80%': 78 },
        E195: { min: 100, max: 116, '90%': 97, '80%': 86 },
        E75L: { min: 76, max: 88, '90%': 74, '80%': 66 },
        FA7X: { min: 12, max: 16, '90%': 13, '80%': 11 },
        FA8X: { min: 19, max: 19, '90%': 17, '80%': 15 },
    };

    constructor(
        public coreService: CoreService,
        public climateAnalyticsService: ClimateAnalyticsService,
        private _authService: AuthService,
        private _mapService: MapService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedFlight']) {
            const startDate = new Date(this.selectedFlight.startDate + ' ' + this.selectedFlight.startTime);
            const endDate = new Date(this.selectedFlight.endDate + ' ' + this.selectedFlight.endTime);
            this.flightDuration = endDate.getTime() - startDate.getTime();
            this.flightDistance = this.selectedFlight.points[this.selectedFlight.points.length - 1].groundDistanceToStart;
            const maxAlt = Math.max(...this.selectedFlight.points.map((o) => o.coordinates[2]));
            this.flightMaxAltitude = 'FL' + (Math.round((maxAlt * 3.281) / 1000) * 1000) / 100;
            this.flightAvgSpeed = this.flightDistance / this.flightDuration;
            this.percentageUnderContrails = Math.round(100 * (this.selectedFlight.totalMetersContrails / this.flightDistance));

            this.totalCO2 = this.selectedFlight.totalCo2Emitted;
            // @ts-expect-error: AIRCRAFT_PAX will be replaced with backend call
            if (this.AIRCRAFT_PAX[this.selectedFlight.aircraftType]['90%']) {
                // @ts-expect-error: AIRCRAFT_PAX will be replaced with backend call
                this.totalCO2PerPAX = this.selectedFlight.totalCo2Emitted / this.AIRCRAFT_PAX[this.selectedFlight.aircraftType]['90%'];
            }
        }
    }

    toggleVisibility() {
        if (this.isVisible) {
            this.mouseleave();
        }
        this.isVisible = !this.isVisible;
        this.changeFlightVisibility.emit(this.trajId);
    }

    @HostListener('mouseover', ['$event'])
    mouseover() {
        this.isHovered = true;
        if (this.isVisible) {
            this.hoveredFlight.emit(this.trajId);
        }
    }

    @HostListener('mouseleave', ['$event'])
    mouseleave() {
        this.isHovered = false;
        if (this.isVisible) {
            this.hoveredFlight.emit(null);
        }
    }

    removeCard() {
        this.climateAnalyticsService.removeVisibleTrajectory(this.trajId);
        this.removeFlight.emit();
    }

    public get isVisible(): boolean {
        return this.selectedFlight.visible;
    }

    public set isVisible(b: boolean) {
        this.selectedFlight.visible = b;
    }

    get displayCO2Info(): boolean {
        return !!this._authService.airport;
    }
}
