import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToggleItem } from '../../models/toggle-item.interface';

@Component({
    selector: 'ffp-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
    @Input()
    toggleItems: Array<ToggleItem> = [
        {
            name: 'gwp100',
            display: 'GWP<sub>100</sub>',
        },
        {
            name: 'atr20',
            display: 'ATR<sub>20</sub>',
        },
    ];

    @Input()
    toggleValue: ToggleItem = {
        name: 'gwp100',
        display: 'GWP<sub>100</sub>',
    };

    @Input()
    backgroundColor: string = 'grey';

    @Output()
    toggledTo = new EventEmitter<string>();

    allowAnim = false;

    constructor() {}

    ngOnInit(): void {
        if (!this.toggleValue) {
            this.toggleValue = this.toggleItems[0];
        }
    }

    toggled(newValue: ToggleItem): void {
        this.allowAnim = true;
        this.toggleValue = newValue;
        this.toggledTo.emit(this.toggleValue.name);
    }
}
