<div class="section" [ngClass]="{ big: flights.data.length === 0 }">
    <span class="section-title">Select the file(s) you want to upload</span>
    <div class="section-content">
        <div
            ng2FileDrop
            [ngClass]="{ 'drop-zone-file-over': hasBaseDropZoneOver }"
            (fileOver)="fileOverBase($event)"
            (onFileDrop)="onFileSelected($event)"
            [uploader]="uploader"
            class="drop-zone"
        >
            <mat-icon>file_upload</mat-icon>
            Drag and drop files here to upload

            <button (click)="fileInput.click()" mat-raised-button color="primary">Or select files to upload</button>
        </div>
    </div>

    <span style="display: none">
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple (onFileSelected)="onFileSelected($event)" /><br />
    </span>
</div>

@if (flights.data.length !== 0) {
    <div class="section">
        <span class="section-title">{{ flightsToImport }} new flight(s) to import:</span>
        <div class="section-content">
            <div class="records-table">
                <table mat-table [dataSource]="flights" aria-label="Flights' list to upload">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef id="selectColumn">
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <!-- Flight Number Column -->
                    <ng-container matColumnDef="flightNumber">
                        <th mat-header-cell *matHeaderCellDef id="flightColumn">Flight Number</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.flightNumber }}
                        </td>
                    </ng-container>
                    <!-- Start Airport Column -->
                    <ng-container matColumnDef="startAirportName">
                        <th mat-header-cell *matHeaderCellDef id="departureColumn">Departure</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startAirportName }}
                        </td>
                    </ng-container>
                    <!-- End Airport Column -->
                    <ng-container matColumnDef="endAirportName">
                        <th mat-header-cell *matHeaderCellDef id="destinationColumn">Destination</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.endAirportName }}
                        </td>
                    </ng-container>
                    <!-- Start Date Column -->
                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef id="startDateColumn">Start Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.startDate | date: "MM/dd/yyyy" }}
                        </td>
                    </ng-container>
                    <!-- End Date Column -->
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef id="endDateColumn">End Date</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.endDate | date: "MM/dd/yyyy" }}
                        </td>
                    </ng-container>
                    <!-- Filename Column -->
                    <ng-container matColumnDef="fileName">
                        <th mat-header-cell *matHeaderCellDef id="fileNameColumn">File Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.fileName }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                </table>
            </div>
        </div>
    </div>
}

<div class="buttons">
    <button mat-raised-button color="accent" [disabled]="flights.data.length === 0 || flightsToImport === 0" (click)="import()">Import</button>
</div>
