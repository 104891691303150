/* Variable content */
export const svgWidth = 850;
export const svgHeight = 240;
export const margin = {
    top: 50,
    bottom: 50,
    right: 55,
    left: 50,
};

/* Computed values: DO NOT TOUCH */
export const width = svgWidth - margin.left - margin.right;
export const height = svgHeight - margin.top - margin.bottom;
export const extent: [[number, number], [number, number]] = [
    [0, 0],
    [width, height],
];
