import { AfterViewInit, Component } from '@angular/core';
import { CoreService } from '../../services/core/core.service';
import { Location } from '@angular/common';
import { MixPanelService, SassHelperService } from '../../services';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { menuAnimation } from './animations/animations';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../../../shared/components';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'ffp-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    animations: [menuAnimation],
})
export class MenuComponent implements AfterViewInit {
    isExtended = false;
    isExtending = false;

    constructor(
        public coreService: CoreService,
        private _location: Location,
        private _mixPanelService: MixPanelService,
        private _router: Router,
        private _authService: AuthService,
        public sassHelperService: SassHelperService,
        private readonly _matDialog: MatDialog,
    ) {
        this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && Object.prototype.hasOwnProperty.call(val, 'urlAfterRedirects')) {
                this.coreService.setCurrentServiceFromRoute(val.urlAfterRedirects);
            }
        });
    }

    ngAfterViewInit(): void {
        const img = document.querySelector('#airline-icon')!;
        img.addEventListener('error', (event) => {
            (event.target as HTMLImageElement).src = '../../../../assets/icons/airlines/thales.svg';
        });
    }

    extend(): void {
        this.isExtended = !this.isExtended;
    }

    get airline(): string {
        return this._authService.airline;
    }

    async logout(): Promise<void> {
        const dialogRef = this._matDialog.open(LogoutDialogComponent);

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'yes') {
                this._mixPanelService.logout();
                this._authService.signOut();
            }
        });
    }

    protected readonly environment = environment;
}
