import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {
    public isUnderMaintenance = false;

    constructor() {}
}
