import { Component, Input } from '@angular/core';
import { CoreService } from '../../../core/services/core/core.service';

@Component({
    selector: 'ffp-consumption-details-label',
    templateUrl: './consumption-details-label.component.html',
    styleUrls: ['./consumption-details-label.component.scss'],
})
export class ConsumptionDetailsLabelComponent {
    constructor(public coreService: CoreService) {}

    @Input()
    displayValues: boolean = false;

    @Input()
    displayLabel: boolean = false;

    @Input()
    data!: {
        co2: number;
        nox: number;
        h2o: number;
        contrails: number;
    };
}
