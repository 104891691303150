<div id="map" class="map-container" [ngClass]="{ 'vertical-view-enabled': verticalViewEnabled }"></div>

<div class="buttons-container" [ngClass]="{ 'vertical-view-enabled': verticalViewEnabled }">
    <!--    <ffp-map-button [iconClass]="'compass'" (clicked)="resetBearing()"-->
    <!--                   [bearing]="bearing"></ffp-map-button>-->
    <!--    <ffp-map-button [iconClass]="'zoom_in'" (clicked)="zoomIn()"></ffp-map-button>-->
    <!--    <ffp-map-button [iconClass]="'zoom_out'" (clicked)="zoomOut()"></ffp-map-button>-->
    <!--    <ffp-map-button [iconClass]="'center_focus_strong'" (clicked)="focusOnFeature()"></ffp-map-button>-->
    <!--    <ffp-map-button *ngIf="!selectionEnabled" [iconClass]="'border_color'" (clicked)="toggleSelectionMode()"></ffp-map-button>-->
    <!--&lt;!&ndash;    <ffp-map-button *ngIf="selectionEnabled" [iconClass]="'edit_off'" (clicked)="toggleSelectionMode()"></ffp-map-button>&ndash;&gt;-->
    <!--    <ffp-map-button *ngIf="!isFullscreen" [iconClass]="'fullscreen'" (clicked)="toggleFullscreen()"-->
    <!--                   [bearing]="bearing"></ffp-map-button>-->
    <!--    <ffp-map-button *ngIf="isFullscreen" [iconClass]="'fullscreen_exit'"-->
    <!--                   (clicked)="toggleFullscreen()" [bearing]="bearing"></ffp-map-button>-->

    <div class="zoom-button">
        <ffp-button type="secondary" size="x-small" neutral (ffpClick)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
        </ffp-button>
        <div class="zoom-button-divider"></div>
        <ffp-button type="secondary" size="x-small" neutral (ffpClick)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
        </ffp-button>
    </div>
    <!--    <ffp-button-->
    <!--        type="secondary"-->
    <!--        size="x-small"-->
    <!--        neutral-->
    <!--    >-->
    <!--        <mat-icon>thermostat</mat-icon>-->
    <!--    </ffp-button>-->
    <ffp-button type="secondary" size="x-small" neutral (ffpClick)="resetBearing()">
        <div
            class="compass"
            [ngStyle]="{
                transform: bearing ? 'rotate(' + -1 * bearing + 'deg)' : '',
            }"
        ></div>
    </ffp-button>
    <ffp-button type="secondary" size="x-small" neutral (ffpClick)="focusOnFeature()">
        <mat-icon>filter_center_focus</mat-icon>
    </ffp-button>
</div>

<!--<div class="weather-panel">-->
<!--    <mat-expansion-panel #hotspotsPanel>-->
<!--        <mat-expansion-panel-header>-->
<!--            <mat-panel-title>-->
<!--                <mat-icon>layers</mat-icon>-->
<!--                Hotspots-->
<!--            </mat-panel-title>-->
<!--        </mat-expansion-panel-header>-->
<!--        &lt;!&ndash; WEATHER EFFECTS &ndash;&gt;-->
<!--        <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                    <mat-icon>thermostat</mat-icon>-->
<!--                    Weather Effects-->
<!--                </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div class="weather-layer">-->
<!--            <span (click)="toggleWeatherLayerVisibility('wind')"-->
<!--                  [ngClass]="{'selected': windLayerActive}">Wind</span>-->
<!--            </div>-->
<!--            <div class="weather-layer">-->
<!--            <span (click)="toggleWeatherLayerVisibility('contrails')"-->
<!--                  [ngClass]="{'selected': contrailsLayerActive}">Contrails</span>-->
<!--            </div>-->
<!--            <div class="legend-divider" *ngIf="currentLegend"></div>-->
<!--            <div class="legend" *ngIf="currentLegend">-->
<!--                <span>Legend</span>-->
<!--                <div class="gradient-line" [ngStyle]="{'background': 'linear-gradient(to right, ' + legendColors + ')'}"></div>-->
<!--                <div class="tick-container">-->
<!--                    <div class="tick" *ngFor="let value of legendValues" [ngStyle]="{'left': 'calc(' + ((value - legendValues[0]) / (legendValues[legendValues.length - 1] - legendValues[0])) * 100 + '% - 20px)'}">-->
<!--                        {{value}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </mat-expansion-panel>-->
<!--        &lt;!&ndash; AIRCRAFT EFFECTS &ndash;&gt;-->
<!--        <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                    <mat-icon>flight</mat-icon>-->
<!--                    Aircraft Effects-->
<!--                </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div class="weather-layer disabled" matTooltip="Coming soon!"-->
<!--                 [matTooltipPosition]="'right'">-->
<!--&lt;!&ndash;                <span (click)="toggleWeatherLayerVisibility('wind')"&ndash;&gt;-->
<!--&lt;!&ndash;                      [ngClass]="{'selected': windLayerActive}">Non-optimal Mach</span>&ndash;&gt;-->
<!--                <span>Non-optimal Mach</span>-->

<!--            </div>-->
<!--            <div class="weather-layer disabled" matTooltip="Coming soon!"-->
<!--                 [matTooltipPosition]="'right'">-->
<!--&lt;!&ndash;                <span (click)="toggleWeatherLayerVisibility('contrails')"&ndash;&gt;-->
<!--&lt;!&ndash;                      [ngClass]="{'selected': contrailsLayerActive}">Steps in Climb</span>&ndash;&gt;-->
<!--                <span>Steps in Climb</span>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="legend-divider" *ngIf="currentLegend"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="legend" *ngIf="currentLegend">&ndash;&gt;-->
<!--&lt;!&ndash;                <span>Legend</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="gradient-line" [ngStyle]="{'background': 'linear-gradient(to right, ' + legendColors + ')'}"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="tick-container">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="tick" *ngFor="let value of legendValues" [ngStyle]="{'left': 'calc(' + ((value - legendValues[0]) / (legendValues[legendValues.length - 1] - legendValues[0])) * 100 + '% - 20px)'}">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{value}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </mat-expansion-panel>-->
<!--        &lt;!&ndash; ATC EFFECTS &ndash;&gt;-->
<!--        <mat-expansion-panel>-->
<!--            <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                    <mat-icon>podcasts</mat-icon>-->
<!--                    ATC Effects-->
<!--                </mat-panel-title>-->
<!--            </mat-expansion-panel-header>-->
<!--            <div class="weather-layer disabled" matTooltip="Coming soon!"-->
<!--                 [matTooltipPosition]="'right'">-->
<!--&lt;!&ndash;            <span (click)="toggleWeatherLayerVisibility('wind')"&ndash;&gt;-->
<!--&lt;!&ndash;                  [ngClass]="{'selected': windLayerActive}">Holding</span>&ndash;&gt;-->
<!--                <span>Holding</span>-->
<!--            </div>-->
<!--            <div class="weather-layer disabled" matTooltip="Coming soon!"-->
<!--                 [matTooltipPosition]="'right'">-->
<!--&lt;!&ndash;            <span (click)="toggleWeatherLayerVisibility('contrails')"&ndash;&gt;-->
<!--&lt;!&ndash;                  [ngClass]="{'selected': contrailsLayerActive}">Major Vectoring</span>&ndash;&gt;-->
<!--                <span>Major Vectoring</span>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="legend-divider" *ngIf="currentLegend"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="legend" *ngIf="currentLegend">&ndash;&gt;-->
<!--&lt;!&ndash;                <span>Legend</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="gradient-line" [ngStyle]="{'background': 'linear-gradient(to right, ' + legendColors + ')'}"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="tick-container">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="tick" *ngFor="let value of legendValues" [ngStyle]="{'left': 'calc(' + ((value - legendValues[0]) / (legendValues[legendValues.length - 1] - legendValues[0])) * 100 + '% - 20px)'}">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{value}}&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </mat-expansion-panel>-->
<!--    </mat-expansion-panel>-->

<!--</div>-->
