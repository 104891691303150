import { Component } from '@angular/core';
import { SassHelperService } from '../../../../../../core/services';

@Component({
    selector: 'ffp-analytics-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
})
export class ComingSoonComponent {
    constructor(public sassHelperService: SassHelperService) {}
}
