import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Alternative, ForecastFlight } from '../../../../models/flight.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreService } from '../../../../../../core/services/core/core.service';
import { SassHelperService } from '../../../../../../core/services';
import { ForecastService } from '../../../../services/forecast/forecast.service';

@Component({
    selector: 'ffp-forecast-flight-tag',
    templateUrl: './flight-tag.component.html',
    styleUrls: ['./flight-tag.component.scss'],
})
export class FlightTagComponent implements OnDestroy, OnInit {
    @Input()
    flight!: ForecastFlight;

    @Input()
    isPassed!: boolean;

    @Input()
    elapsedTimeWidth!: number;

    @Output()
    displayFlightEvent = new EventEmitter<ForecastFlight>();

    bestAlternative: Alternative | undefined;
    isHovered = false;
    color: string = 'forecast';
    private _subscription;

    constructor(
        private readonly _snackBar: MatSnackBar,
        public coreService: CoreService,
        private forecastService: ForecastService,
        public sassHelperService: SassHelperService,
    ) {
        this._subscription = this.forecastService.getHoveredCardFlightIndex().subscribe((newValue) => {
            this.isHovered = this.flight?.index === newValue;
        });
    }

    ngOnInit(): void {
        this.bestAlternative = this.flight.alternatives.find((el) => el.isBestAlternative);
        this.color = this.flight.index > 2 ? 'route-estimator' : 'forecast';
    }

    tagIsHovered(): void {
        this.forecastService.setHoveredTagFlightIndex(this.flight.index);
    }

    tagIsNoLongerHovered(): void {
        this.forecastService.setHoveredTagFlightIndex(null);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
