import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ffp-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit {
    @Input()
    title: string | undefined;

    @Input()
    description: string | undefined;

    @Input()
    button: boolean = false;

    @Input()
    buttonIcon: string | undefined;

    @Input()
    buttonText: string | undefined;

    @Output()
    buttonCallback: EventEmitter<string> = new EventEmitter();

    @Input()
    service: string | undefined;

    @Input()
    errorCode: number | undefined;

    constructor() {}

    ngOnInit(): void {
        if (this.errorCode) {
            if (this.errorCode >= 500) {
                this.description = 'Our server didn’t respond, please refresh the page.';
            } else if (this.errorCode === 404) {
                this.description = "Not found: the resource you're looking for does not exist.";
            } else if (this.errorCode === 401 || this.errorCode === 403) {
                this.description = "Restricted access: you don't have sufficient permissions.";
            } else if (this.errorCode === 400) {
                this.description = 'Bad request: server cannot process the request.';
            }
        }
    }

    triggerCallback(): void {
        this.buttonCallback.emit();
    }
}
