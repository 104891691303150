import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { svgHeight } from './config/consumption-details-config';
import { scaleLinear, select, sum } from 'd3';
import { SassHelperService } from '../../../core/services';

@Component({
    selector: 'ffp-consumption-details',
    templateUrl: './consumption-details.component.html',
    styleUrls: ['./consumption-details.component.scss'],
})
export class ConsumptionDetailsComponent implements OnInit, AfterViewInit, OnChanges {
    @Input()
    data!: {
        co2: number;
        nox: number;
        h2o: number;
        contrails: number;
    };

    @Input()
    svgId!: string;

    @Input()
    parentWidth!: number;

    @Input()
    tooltip: boolean = false;

    public tooltipPosition = {
        x: 0,
        y: 0,
    };
    public isHovered = false;

    @ViewChild('ref')
    private ref!: ElementRef;

    /***************
     D3 variables
     **************/
    // @ts-expect-error: D3.js stuff
    private x;
    // @ts-expect-error: D3.js stuff
    private y;
    // @ts-expect-error: D3.js stuff
    private svgChart;
    // Feature management and selection
    private emissionsDataSample: { label: string; value: number }[] = [];

    constructor(public sassHelperService: SassHelperService) {}

    ngAfterViewInit(): void {
        this.buildGraph();
    }

    ngOnInit(): void {
        this.buildGraph();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['parentWidth']?.currentValue !== changes['parentWidth']?.previousValue) {
            this.buildGraph();
        }
    }

    buildGraph(): void {
        this.emissionsDataSample = [];

        this.emissionsDataSample = [
            { label: 'co2', value: Math.abs(this.data.co2) },
            { label: 'nox', value: Math.abs(this.data.nox) },
            {
                label: 'contrails',
                value: Math.abs(this.data.contrails),
            },
            { label: 'h2o', value: Math.abs(this.data.h2o) },
        ];

        select('#chart').remove();
        this.svgChart?.remove();

        this.drawChart();
    }

    drawChart(): void {
        this.svgChart = select(this.ref?.nativeElement).append('svg').attr('id', this.svgId);
        this.svgChart
            .attr('viewBox', `0 0 ${this.parentWidth} ${svgHeight}`)
            .attr('preserveAspectRatio', `xMinYMin meet`)
            .attr('width', `${this.parentWidth}`)
            .append('g');

        const total = sum(this.emissionsDataSample, (d) => d.value);
        const _data = this.filterData(this.emissionsDataSample, total).reverse();
        const xScale = scaleLinear().domain([0, total]).range([0, this.parentWidth]);

        const initialChart = this.svgChart
            .select('g')
            .selectAll('rect')
            .data(_data)
            .enter()
            .append('rect')
            .attr(
                'class',
                (d: { value: number; cumulative: number; label: string; percent: number; initialIndex: number }) => 'rect-stacked ' + d.label,
            )
            .attr('width', (d: { value: number; cumulative: number; label: string; percent: number; initialIndex: number }) => {
                if (d.initialIndex === 0) {
                    return this.parentWidth / 4;
                } else {
                    return this.parentWidth / 4 + 3;
                }
            })
            .attr('x', (d: { value: number; cumulative: number; label: string; percent: number; initialIndex: number }) => {
                if (d.initialIndex === 0) {
                    return d.initialIndex * (this.parentWidth / 4);
                } else {
                    return d.initialIndex * (this.parentWidth / 4) - 3;
                }
            })
            .attr('y', svgHeight / 2 - 2)
            .attr('ry', '3px')
            .attr('rx', '3px')
            .attr('height', '5px')
            .style('stroke', '#FFFFFF')
            .style('stroke-width', '0.5px');

        const isAllPartsNull = this.emissionsDataSample.every((v) => v.value === 0);
        if (!isAllPartsNull) {
            initialChart
                .transition()
                .duration(2000)
                .attr('width', (d: { value: number; cumulative: number; label: string; percent: number; initialIndex: number }) => {
                    if (d.initialIndex === 0) {
                        return xScale(d.value);
                    } else {
                        return xScale(d.value) + 3;
                    }
                })
                .attr('x', (d: { value: number; cumulative: number; label: string; percent: number; initialIndex: number }) => {
                    if (d.initialIndex === 0) {
                        return xScale(d.cumulative);
                    } else {
                        return xScale(d.cumulative) - 3;
                    }
                });
        } else {
            this.svgChart.select('g').attr('style', 'opacity: 0.2');
        }
    }

    filterData(
        data: { label: string; value: number }[],
        total: number,
    ): {
        value: number;
        cumulative: number;
        label: string;
        percent: number;
        initialIndex: number;
    }[] {
        const percent = scaleLinear().domain([0, total]).range([0, 100]);
        // filter out data that has zero values
        // also get mapping for next placement
        // (save having to format data for d3 stack)
        let cumulative = 0;
        return data.map((d, i) => {
            cumulative += d.value;
            return {
                value: d.value,
                // want the cumulative to prior value (start of rect)
                cumulative: cumulative - d.value,
                label: d.label,
                percent: percent(d.value),
                initialIndex: i,
            };
        });
    }

    mouseMoved($event: MouseEvent): void {
        // TODO : get height properly
        if ($event.y > window.innerHeight - +this.sassHelperService.getProperty('consumption-bar-tooltip-height-numeric') - 20) {
            this.tooltipPosition = {
                x: $event.x + 10,
                y: $event.y - 60,
            };
        } else {
            this.tooltipPosition = {
                x: $event.x + 10,
                y: $event.y + 15,
            };
        }
    }
}
