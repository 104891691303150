import { Component, Inject } from '@angular/core';
import { DialogContainer, DialogContent } from '../../../models/dialog.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'ffp-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./help-dialog.component.scss'],
})
export class HelpDialogComponent {
    title: string;
    tabs: DialogContent[];

    selected = new FormControl(0);

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { selectedIndex?: number; content: DialogContainer },
    ) {
        this.title = data.content.theme;
        this.tabs = data.content.tabs;
        if (data.selectedIndex) {
            this.selected.setValue(data.selectedIndex);
        }
    }
}
