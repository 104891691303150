import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ffp-map-button',
    templateUrl: './map-button.component.html',
    styleUrls: ['./map-button.component.scss'],
})
export class MapButtonComponent {
    @Output()
    public clicked: EventEmitter<string> = new EventEmitter();

    @Input()
    public iconClass: string | undefined;

    @Input()
    public bearing: number | undefined;

    constructor() {}
}
