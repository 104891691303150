import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ExplorerFlight } from '../../../../models/flight.interface';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CityPair } from '../../../../models/city-pair.interface';
import { Filters } from '../../../../models/filters.interface';
import { CoreService } from '../../../../../../core/services/core/core.service';
import { timer } from 'rxjs';

@Component({
    selector: 'ffp-analytics-flight-table',
    templateUrl: './flight-table.component.html',
    styleUrls: ['./flight-table.component.scss'],
})
export class FlightTableComponent implements OnChanges {
    @Input()
    data!: CityPair;

    @Input()
    currentlySelectedFlights: ExplorerFlight[] = [];

    // filters data from user filters options
    @Input()
    inputFilters!: Filters;

    @Input()
    isHeaderOpened: boolean = false;

    @Output()
    currentlySelectedFlightsChange = new EventEmitter<ExplorerFlight[]>();

    // filters data to initiate filter dialog box
    @Output()
    initialDataDialogBox = new EventEmitter<Filters>();

    @Output()
    noData = new EventEmitter<boolean>(true);

    public displayedColumns: string[] = [
        'select',
        'date',
        'flightNumber',
        'aircraftType',
        'flightLevel',
        'consumptionDetails',
        'fuel',
        'atr20',
        'gwp100',
    ];

    public displayedFlights = new MatTableDataSource<ExplorerFlight>([]);
    public displayedFlightsFiltered = new MatTableDataSource<ExplorerFlight>([]);
    public selection = new SelectionModel<ExplorerFlight>(true, []);

    public errors: Error | undefined;

    public rowHovered: MatRow | 'header' | null = null;
    public headerCellHovered: string | null = null;
    public sortingOverlayIsClosed = true;
    public sorting: {
        column: string | null;
        ascending: boolean | null;
    } = {
        column: null,
        ascending: null,
    };

    constructor(public coreService: CoreService) {}

    @Input() set inputFlights(flights: ExplorerFlight[]) {
        this.displayedFlights.data = flights;
        this.displayedFlightsFiltered = this.displayedFlights;
        // send input data for the dialog box initial state
        this.eventOutputFilters();
        this.displayedFlightsFiltered.data.length === 0 ? this.noData.emit(true) : this.noData.emit(false);
    }

    get cellWidth(): number {
        return document.querySelector('.mat-mdc-table .mat-column-consumptionDetails')!.getBoundingClientRect().width;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.inputFilters === undefined) {
            this.displayedFlightsFiltered = this.displayedFlights;
        } else {
            switch (this.inputFilters.filter) {
                case 'display':
                    this.displayedFlightsFiltered = new MatTableDataSource<ExplorerFlight>([]);
                    this.displayedFlightsFiltered.data = this.displayedFlights.data;
                    this.displayedFlightsFiltered.data = this.displayedFlights.data.filter(
                        (row) =>
                            (this.inputFilters.immatriculations.length === 0 ||
                                this.inputFilters.immatriculations.includes(row.aircraftRegistration)) &&
                            (this.inputFilters.aircraftTypes.length === 0 || this.inputFilters.aircraftTypes.includes(row.aircraftType)) &&
                            row.cruiseFl >= this.inputFilters.flightLevelMin &&
                            row.cruiseFl <= this.inputFilters.flightLevelMax,
                    );
                    this.displayedFlightsFiltered.data.length === 0 ? this.noData.emit(true) : this.noData.emit(false);
                    break;
                case 'clear':
                    this.displayedFlightsFiltered = this.displayedFlights;
                    break;
                case 'close':
                    break; // do nothing on displayedFlightsFiltered for closing window case
                default:
                    console.warn('WARNING: wrong value for inputFilters => filter property ');
                    break;
            }
        }

        if (changes['currentlySelectedFlights']) {
            const flightsToRemove: ExplorerFlight[] = [];
            this.currentlySelectedFlights.forEach((el) => {
                const flightToSelect = this.displayedFlights.data.find(
                    (displayedFlight) =>
                        displayedFlight.flightNumber === el.flightNumber &&
                        displayedFlight.startDate === el.startDate &&
                        displayedFlight.startTime === el.startTime,
                );
                if (flightToSelect) {
                    this.selection.select(flightToSelect);
                } else {
                    flightsToRemove.push(el);
                }
            });
            this.currentlySelectedFlights = this.currentlySelectedFlights.filter((el) => !flightsToRemove.includes(el));
            timer(0).subscribe(() => {
                this.currentlySelectedFlightsChange.emit(this.currentlySelectedFlights);
            });
        }
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.displayedFlights.data.length;
        return numSelected === numRows;
    }

    eventOutputFilters(): void {
        const acTypes: string[] = [];
        const imma: string[] = [];
        const flightLevels: number[] = [];
        this.displayedFlights.data.forEach((row) => {
            acTypes.push(row.aircraftType);
            imma.push(row.aircraftRegistration);
            flightLevels.push(row.cruiseFl);
        });

        const min = Math.min.apply(null, flightLevels);
        const max = Math.max.apply(null, flightLevels);

        this.initialDataDialogBox.emit({
            aircraftTypes: Array.from(new Set(acTypes)),
            immatriculations: Array.from(new Set(imma)),
            flightLevelMin: min,
            flightLevelMax: max,
        } as Filters);
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.currentlySelectedFlightsChange.emit(this.selection.selected);
            return;
        }

        this.selection.select(...this.displayedFlights.data);
        this.currentlySelectedFlightsChange.emit(this.selection.selected);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ExplorerFlight): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.displayedFlights.data.indexOf(row) + 1}`;
    }

    handleCheckboxChange(row: ExplorerFlight): void {
        this.selection.toggle(row);
        this.currentlySelectedFlightsChange.emit(this.selection.selected);
    }

    sortData(column: string, ascending: boolean): void {
        this.sorting = {
            column,
            ascending,
        };

        // check whether sorting is needed and, if so, sort flights displayed
        if (new Set(this.displayedFlightsFiltered.data.map((el) => el[column as keyof ExplorerFlight])).size > 1) {
            this.displayedFlightsFiltered.data = this.displayedFlightsFiltered.data.slice().sort((a, b) => {
                return this.compare(a[column as keyof ExplorerFlight], b[column as keyof ExplorerFlight], ascending);
            });
        }

        this.sortingOverlayIsClosed = true;
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
