import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Filters } from '../../../../models/filters.interface';
import { MatSlider } from '@angular/material/slider';

export interface DialogData {
    inputDialogBox: Filters;
    outputDialogBox: Filters;
}

@Component({
    selector: 'ffp-analytics-filter-pop-up',
    templateUrl: './filter-pop-up.component.html',
    styleUrls: ['./filter-pop-up.component.scss'],
})
export class FilterPopUpComponent implements OnInit {
    @ViewChild('matSlider')
    slider!: MatSlider;

    aircraftTypes = new Map<string, boolean>();
    immatriculations = new Map<string, boolean>();

    immatriculationsSelected: string[] = [];
    aircraftTypesSelected: string[] = [];

    minFlightLevel: number = 0;
    maxFlightLevel: number = 600;
    middleFlightLevel: number = 300;

    minFlightLevelSelected: number = 0;
    maxFlightLevelSelected: number = 600;

    // temp variables : backuped only for case display after user filters
    aircraftTypesTemp = new Map<string, boolean>();
    immatriculationsTemp = new Map<string, boolean>();
    minFlightLevelSelectedTemp!: number;
    maxFlightLevelSelectedTemp!: number;

    sliderStep = 10;
    sliderEvenSteps: boolean = false;
    popUpIsLoading = true;

    constructor(
        public dialogRef: MatDialogRef<FilterPopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngOnInit(): void {
        const immatriculationsList = this.data.inputDialogBox.immatriculations;
        const aircraftTypesList = this.data.inputDialogBox.aircraftTypes;

        // loop over cards and set up immatriculations, aircraft types and flight level options
        immatriculationsList.forEach((imma) => {
            this.immatriculationsTemp.set(imma, false);
            this.immatriculations.set(imma, false);
        });

        aircraftTypesList.forEach((actype) => {
            this.aircraftTypesTemp.set(actype, false);
            this.aircraftTypes.set(actype, false);
        });

        this.minFlightLevel = this.data.inputDialogBox.flightLevelMin;
        this.maxFlightLevel = this.data.inputDialogBox.flightLevelMax;
        this.middleFlightLevel =
            this.minFlightLevel + Math.round(Math.round((this.maxFlightLevel - this.minFlightLevel) / this.sliderStep) / 2) * this.sliderStep;

        this.minFlightLevelSelected = this.data.inputDialogBox.flightLevelMin;
        this.maxFlightLevelSelected = this.data.inputDialogBox.flightLevelMax;
        this.minFlightLevelSelectedTemp = this.data.inputDialogBox.flightLevelMin;
        this.maxFlightLevelSelectedTemp = this.data.inputDialogBox.flightLevelMax;

        // check if there is a previous user filters state (from a previous dialog box state):
        if (this.data.outputDialogBox !== undefined) {
            // set up immatriculations and actype buttons to true state (i.e enable state)"
            this.data.outputDialogBox.immatriculations.forEach((imma) => {
                this.immatriculationsTemp.set(imma, true);
                this.immatriculations.set(imma, true);
            });

            this.data.outputDialogBox.aircraftTypes.forEach((actype) => {
                this.aircraftTypesTemp.set(actype, true);
                this.aircraftTypes.set(actype, true);
            });

            // update flight levels
            this.minFlightLevelSelected = this.data.outputDialogBox.flightLevelMin;
            this.maxFlightLevelSelected = this.data.outputDialogBox.flightLevelMax;
            this.minFlightLevelSelectedTemp = this.data.outputDialogBox.flightLevelMin;
            this.maxFlightLevelSelectedTemp = this.data.outputDialogBox.flightLevelMax;
        }

        // to know if nb of steps on sliders are odd or even :
        const nbSteps = Math.round((this.maxFlightLevel - this.minFlightLevel) / this.sliderStep);
        this.sliderEvenSteps = nbSteps % 2 === 0;

        setTimeout(() => {
            this.popUpIsLoading = false;
        }, 0);
    }

    _changeCheckboxState(map: Map<string, boolean>, key: string): Map<string, boolean> {
        map.set(key, !map.get(key));
        return map;
    }

    _unselectAll(map: Map<string, boolean>): Map<string, boolean> {
        for (const key of map.keys()) {
            map.set(key, false);
        }
        return map;
    }

    clearFilter(): void {
        // put all buttons to unselected state
        this.aircraftTypes = this._unselectAll(this.aircraftTypes);
        this.immatriculations = this._unselectAll(this.immatriculations);

        this.immatriculationsSelected = [];
        this.aircraftTypesSelected = [];

        this.maxFlightLevelSelected = this.maxFlightLevel;
        this.minFlightLevelSelected = this.minFlightLevel;

        this.dialogRef.close({
            immatriculations: this.immatriculationsSelected,
            aircraftTypes: this.aircraftTypesSelected,
            flightLevelMin: this.minFlightLevelSelected,
            flightLevelMax: this.maxFlightLevelSelected,
            filter: 'clear',
        });
    }

    onClose(): void {
        this.immatriculationsSelected = this._update_selection_list(this.immatriculations);
        this.aircraftTypesSelected = this._update_selection_list(this.aircraftTypes);

        this.dialogRef.close({
            immatriculations: this.immatriculationsSelected,
            aircraftTypes: this.aircraftTypesSelected,
            flightLevelMin: this.minFlightLevelSelected,
            flightLevelMax: this.maxFlightLevelSelected,
            filter: 'close',
        });
    }

    _update_selection_list(map: Map<string, boolean>): Array<string> {
        const result = [];
        for (const [k, v] of map) {
            if (v) {
                result.push(k);
            }
        }
        return result;
    }

    onClick(val: string, filterType: string): void {
        if (filterType === 'immatriculations') {
            this.immatriculationsTemp = this._changeCheckboxState(this.immatriculationsTemp, val);
        } else if (filterType === 'aircraft type') {
            this.aircraftTypesTemp = this._changeCheckboxState(this.aircraftTypesTemp, val);
        } else {
            console.error('filterType must be immatriculation or aircraft type');
        }
    }

    onDisplayFlights(): void {
        this.immatriculations = this.immatriculationsTemp;
        this.aircraftTypes = this.aircraftTypesTemp;
        this.minFlightLevelSelected = this.minFlightLevelSelectedTemp;
        this.maxFlightLevelSelected = this.maxFlightLevelSelectedTemp;

        this.immatriculationsSelected = this._update_selection_list(this.immatriculations);
        this.aircraftTypesSelected = this._update_selection_list(this.aircraftTypes);

        this.dialogRef.close({
            immatriculations: this.immatriculationsSelected,
            aircraftTypes: this.aircraftTypesSelected,
            flightLevelMin: this.minFlightLevelSelected,
            flightLevelMax: this.maxFlightLevelSelected,
            filter: 'display',
        });
    }

    getKeys(map: Map<string, boolean>): string[] {
        return Array.from(map.keys());
    }
}
