import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Card } from '../../models/card.interface';
import { ClimateAnalyticsService } from '../../services/climate-analytics/climate-analytics.service';
import { FormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { Subject, switchMap } from 'rxjs';
import csvDownload from 'json-to-csv-export';
import { MatDialog } from '@angular/material/dialog';
import { FilterPopUpComponent } from './components/filter-pop-up/filter-pop-up.component';
import { Filters } from '../../models/filters.interface';
import { CoreService } from '../../../../core/services/core/core.service';
import { MixPanelService } from '../../../../core/services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ExplorerFlight } from '../../models/flight.interface';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { formatDate } from '@angular/common';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'ffp-city-pair-explorer',
    templateUrl: './city-pair-explorer.component.html',
    styleUrls: ['./city-pair-explorer.component.scss'],
})
export class CityPairExplorerComponent implements OnInit {
    @ViewChild('auto') icaoAutoComplete!: MatAutocomplete;

    cards: Card[] = [];
    currentCityPair: Card | null = null;

    filters = new Map<string, string[]>();

    formControlDeparture = new FormControl('');
    formControlArrival = new FormControl('');

    currentDate = new Date();
    dateDisplayed = this.currentDate;

    errors: HttpErrorResponse | null = null;

    isExported = false;
    isTableHeaderOpen = true;
    isData: boolean = false;
    triggerExportButtonAnim = false;
    isDashboardLoading = false;
    isTableLoading = false;

    flightsTableFlights: ExplorerFlight[] = [];
    currentlySelectedFlights: ExplorerFlight[] = [];
    cityPairAircraftTypeCounter: { name: string; number: number }[] = [];
    loadingCards = new Array(60);

    public moreCardsLoading = false;
    public cardsPage = 1;
    public cardsPageSize = 50;

    public departureAirportsLoaded = false;
    public departureAirports: string[] = [];
    public departureAirportsFiltered: string[] = [];
    public arrivalAirportsLoaded = false;
    public arrivalAirports: string[] = [];
    public arrivalAirportsFiltered: string[] = [];
    @ViewChild('autoFlightArrival') flightArrivalAutocomplete!: MatAutocomplete;
    @ViewChild('autoFlightDep') flightDepAutocomplete!: MatAutocomplete;

    flightFromPastFlightAnalysis = null;

    // initial state of filters data on dialog box
    inputDialogBoxData: Filters | undefined;

    // filters data after user filtering options (dialog box user output)
    @Output()
    outputDialogBoxData: Filters | undefined;

    private _pendingCardsHttpRequest: Subject<Date | void> = new Subject<Date | void>();
    private _pendingFlightsHttpRequest: Subject<Date | void> = new Subject<Date | void>();
    private _currentQueryParams: Params | undefined;

    constructor(
        private readonly _cli: ClimateAnalyticsService,
        public dialog: MatDialog,
        public coreService: CoreService,
        private _mixPanelService: MixPanelService,
        private _authService: AuthService,
        private _route: ActivatedRoute,
        private router: Router,
    ) {
        coreService.setDisplayWarning(!this._authService.airport);
        this._handleRequests();
        if (
            this.coreService.getRouterExtras() &&
            this.coreService.getRouterExtras().state &&
            this.coreService.getRouterExtras().state!['selectedFlights'] &&
            this.router.getCurrentNavigation()!.previousNavigation
        ) {
            this.currentlySelectedFlights = this.coreService.getRouterExtras().state!['selectedFlights'];
        }
    }

    public handleEmissionMeasureChange(emissionMeasure: string): void {
        this.isDashboardLoading = true;
        this.coreService.emissionMeasure = emissionMeasure === 'atr20' ? 'Ccf' : 'Gwp100';
        setTimeout(() => (this.isDashboardLoading = false), 100);
    }

    public updateData(newDate: Date): void {
        this.cardsPage = 1;
        this.router.navigate(['/climate-analytics/city-pair-explorer'], {
            queryParams: {
                date: `${newDate.getFullYear()}-${('0' + (newDate.getMonth() + 1)).slice(-2)}-${('0' + newDate.getDate()).slice(-2)}`,
                emissionMeasure: this.coreService.emissionMeasure,
            },
            queryParamsHandling: 'merge',
        });
    }

    ngOnInit(): void {
        // by default the day of the date displayed is set to 1
        // to get data for the whole month when the back is requested
        this.dateDisplayed.setDate(1);

        // options autocomplete
        this.formControlDeparture.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
            this.departureAirportsFiltered = this.departureAirports.filter((airport) => {
                return airport.toLowerCase().includes(value!.toLowerCase());
            });
        });
        this.formControlArrival.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
            this.arrivalAirportsFiltered = this.arrivalAirports.filter((airport) => {
                return airport.toLowerCase().includes(value!.toLowerCase());
            });
        });

        // handle query params
        this._route.queryParams
            .pipe(
                filter((params) => {
                    if (this._currentQueryParams) {
                        const copy = Object.assign({}, params);
                        delete copy['emissionMeasure'];

                        return JSON.stringify(copy) !== JSON.stringify(this._currentQueryParams);
                    }
                    return true;
                }),
            )
            .subscribe((params) => {
                this._currentQueryParams = Object.assign({}, params);
                delete this._currentQueryParams['emissionMeasure'];
                this.isDashboardLoading = true;
                this.errors = null;
                this.cards = [];
                this.flightsTableFlights = [];
                this.currentCityPair = null;

                if (params['date']) {
                    this.dateDisplayed = new Date(params['date']);
                }

                if (params['emissionMeasure']) {
                    this.coreService.emissionMeasure = params['emissionMeasure'];
                }

                // reset dialog box data
                this.inputDialogBoxData = undefined;
                this.outputDialogBoxData = undefined;

                if (params['cityPair']) {
                    this.isTableLoading = true;
                    const [startAirportCode, endAirportCode] = params['cityPair'].split(/-/);
                    this.currentCityPair = {
                        startAirportCode,
                        endAirportCode,
                        meanCcf: 0,
                        totalCcf: 0,
                        totalCcfCo2: 0,
                        totalCcfH2o: 0,
                        totalCcfNox: 0,
                        totalCcfContrails: 0,
                        ccfMonthDelta: 0,
                        potentialCcfSaving: 0,
                        meanGwp100: 0,
                        totalGwp100: 0,
                        totalGwp100Co2: 0,
                        totalGwp100H2o: 0,
                        totalGwp100Nox: 0,
                        totalGwp100Contrails: 0,
                        gwp100MonthDelta: 0,
                        potentialGwp100Saving: 0,
                        nbFlights: 0,
                    };
                    this._pendingFlightsHttpRequest.next();
                    this._mixPanelService.track('select-city-pair', {
                        cityPair: this.currentCityPair.startAirportCode + '-' + this.currentCityPair.endAirportCode,
                    });
                } else {
                    this._pendingCardsHttpRequest.next();
                }
            });
    }

    public createNewDate(delta: number): Date {
        const date = new Date(this.dateDisplayed);
        date.setMonth(this.dateDisplayed.getMonth() + delta);
        return date;
    }

    public refreshCards(): void {
        this.cards = [];
        this.isDashboardLoading = true;
        this.cardsPage = 1;
        this._pendingCardsHttpRequest.next();
    }

    resetArrivalAutoInput(): void {
        this.formControlArrival.setValue('');
        this.refreshCards();
    }

    resetDepartureAutoInput(): void {
        this.formControlDeparture.setValue('');
        this.refreshCards();
    }

    selectCityPair(card: Card): void {
        this.router.navigate(['/climate-analytics/city-pair-explorer'], {
            queryParams: {
                cityPair: `${card.startAirportCode}-${card.endAirportCode}`,
                emissionMeasure: this.coreService.emissionMeasure,
            },
            queryParamsHandling: 'merge',
        });
    }

    showOnMap(): void {
        const flights = [...this.currentlySelectedFlights];
        flights.splice(0, 1);
        this.router.navigate(['/climate-analytics/city-pair-explorer/map'], {
            queryParams: {
                date: this.currentlySelectedFlights[0].startDate,
                fn: this.currentlySelectedFlights[0].flightNumber,
                time: this.currentlySelectedFlights[0].startTime,
                emissionMeasure: this.coreService.emissionMeasure,
            },
            state: {
                flightsToCompare: flights,
            },
        });
    }

    exportAsCsv(): void {
        const dataToExport = this.currentlySelectedFlights;
        const dataToExportSorted = JSON.parse(
            JSON.stringify(
                dataToExport,
                [
                    'flightNumber',
                    'startAirportCode',
                    'endAirportCode',
                    'startDate',
                    'startTime',
                    'endDate',
                    'endTime',
                    'aircraftRegistration',
                    'aircraftType',
                    'cruiseFl',
                    'distanceMeter',
                    'durationSecond',
                    'totalFuelBurnt',
                    'totalCcf',
                    'totalGwp100',
                    'totalCcfNox',
                    'totalCcfH2o',
                    'totalCcfCo2',
                    'totalCcfContrails',
                ],
                4,
            ),
        );
        const dataToConvert = {
            data: dataToExportSorted,
            filename:
                'FFP_' +
                dataToExportSorted[0].startAirportCode +
                '_' +
                dataToExportSorted[0].endAirportCode +
                '_' +
                this.currentDate.getMonth() +
                this.currentDate.getFullYear(),
            delimiter: ';',
            headers: [
                'Flight number',
                'Start airport code',
                'End airport code',
                'Start date',
                'Start time',
                'End date',
                'End time',
                'Aircraft Registration',
                'Aircraft Type',
                'Flight level',
                'Flight distance (m)',
                'Flight duration (s)',
                'Fuel (g)',
                'ATR20 (K)',
                'GWP100 (g)',
                'ATR20 NoX(K)',
                'ATR20 H20 (K)',
                'ATR20 CO2 (K)',
                'ATR20 Contrails (K)',
            ],
        };
        csvDownload(dataToConvert);

        // Trigger anim
        this.triggerExportButtonAnim = true;
        setTimeout(() => {
            this.isExported = true;
        }, 500);
        setTimeout(() => {
            setTimeout(() => {
                this.isExported = false;
            }, 500);
            this.triggerExportButtonAnim = false;
        }, 5000);

        this._mixPanelService.track('export-flights-as-csv');
    }

    back(): void {
        this.currentlySelectedFlights = [];
        this.flightsTableFlights = [];
        this.currentCityPair = null;
        // reset dialog box data
        this.inputDialogBoxData = undefined;
        this.outputDialogBoxData = undefined;
        this.cardsPage = 1;

        const date =
            `${this.dateDisplayed.getFullYear()}-` +
            `${('0' + (this.dateDisplayed.getMonth() + 1)).slice(-2)}-` +
            `${('0' + this.dateDisplayed.getDate()).slice(-2)}`;
        this.router.navigate(['/climate-analytics/city-pair-explorer'], {
            queryParams: {
                date,
                cityPair: null,
                emissionMeasure: this.coreService.emissionMeasure,
            },
            queryParamsHandling: 'merge',
        });
        this._mixPanelService.track('back-to-dashboard');
    }

    openDialog(): void {
        this._mixPanelService.track('open-filters');
        const dialogRef = this.dialog.open(FilterPopUpComponent, {
            // data contains inputDialogBox but also outputDialogBox to keep previous dialog
            // box state (for close instruction)
            data: {
                inputDialogBox: this.inputDialogBoxData,
                outputDialogBox: this.outputDialogBoxData,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.outputDialogBoxData = result;
            }
            this._mixPanelService.track('close-filters');
        });
    }

    setOutputFilters(event: Filters): void {
        this.inputDialogBoxData = event;
    }

    checkData($event: boolean): void {
        this.isData = $event;
    }

    getElementWidth(selector: string): number {
        return (document.querySelector(selector) as HTMLElement).offsetWidth;
    }

    loadMoreCards(event: Event): void {
        const threshold = (0.8 * 100 * (event.target as HTMLElement).scrollHeight) / 100;
        const current = (event.target as HTMLElement).scrollTop + (event.target as HTMLElement).clientHeight;

        if (current > threshold) {
            if (this.moreCardsLoading || this.cardsPage === 1) {
                return;
            } else {
                this.moreCardsLoading = true;

                this._cli
                    .getClimateAnalyticsCards(
                        this.dateDisplayed,
                        this.cardsPage,
                        this.cardsPageSize,
                        this.formControlDeparture.value!,
                        this.formControlArrival.value!,
                    )
                    .subscribe({
                        next: (results) => {
                            this.extractData(results);
                            this.isDashboardLoading = false;

                            if (results.length === this.cardsPageSize) {
                                this.cardsPage += 1;
                            } else {
                                this.cardsPage = 1;
                            }
                            this.moreCardsLoading = false;
                            this.errors = null;
                        },
                        error: (error) => {
                            this.errors = error;
                            console.error(error);
                            this._handleRequests();
                            this.isDashboardLoading = false;
                        },
                    });
            }
        }
    }

    refreshFlightFilter(filter: string): void {
        switch (filter) {
            case 'startAirportCode':
                this.departureAirports = [];
                this.departureAirportsFiltered = [];
                this.departureAirportsLoaded = false;
                break;
            case 'endAirportCode':
                this.arrivalAirportsLoaded = false;
                this.arrivalAirports = [];
                this.arrivalAirportsFiltered = [];
                break;
            default:
                break;
        }
        this._cli
            .getFilteredOptions(
                filter,
                this.formControlDeparture.value!,
                this.formControlArrival.value!,
                '',
                formatDate(this.dateDisplayed, 'yyyy-MM', 'en'),
            )
            .subscribe(
                (result) => {
                    switch (filter) {
                        case 'startAirportCode':
                            this.departureAirports = result;
                            this.departureAirportsFiltered = result;
                            if (this.formControlDeparture.value) {
                                // Re apply filter
                                this.departureAirportsFiltered = this.departureAirports.filter((airport) => {
                                    return airport.toLowerCase().includes(this.formControlDeparture.value!.toLowerCase());
                                });

                                // Re select option
                                setTimeout(() => {
                                    const option = this.flightDepAutocomplete.options.find(
                                        (f: MatOption) => f.value === this.formControlDeparture.value,
                                    );
                                    if (option != null && !option.selected) {
                                        // @ts-expect-error: No other way to update 'selected' property
                                        option._selected = true;
                                    }
                                }, 100);
                            }
                            this.departureAirportsLoaded = true;
                            break;
                        case 'endAirportCode':
                            this.arrivalAirports = result;
                            this.arrivalAirportsFiltered = result;
                            if (this.formControlArrival.value) {
                                // Re apply filter
                                this.arrivalAirportsFiltered = this.arrivalAirports.filter((airport) => {
                                    return airport.toLowerCase().includes(this.formControlArrival.value!.toLowerCase());
                                });

                                // Re select option
                                setTimeout(() => {
                                    const option = this.flightArrivalAutocomplete.options.find(
                                        (f: MatOption) => f.value === this.formControlArrival.value,
                                    );
                                    if (option != null && !option.selected) {
                                        // @ts-expect-error: No other way to update 'selected' property
                                        option._selected = true;
                                    }
                                }, 100);
                            }
                            this.arrivalAirportsLoaded = true;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.errors = error;
                    this.coreService.pushNotification({
                        title: 'City pair explorer',
                        description: 'An error occurred during filter handling, please refresh and try again',
                        icon: 'error',
                        lifeDuration: 'infinite',
                        info: '',
                        index: 0,
                        type: 'ERROR',
                    });
                },
            );
    }

    private extractData(result: Card[]): void {
        if (result) {
            result.forEach((el) => {
                const card: Card = {
                    startAirportCode: el.startAirportCode,
                    endAirportCode: el.endAirportCode,
                    meanCcf: el.meanCcf,
                    totalCcf: el.totalCcf,
                    totalCcfCo2: el.totalCcfCo2,
                    totalCcfH2o: el.totalCcfH2o,
                    totalCcfNox: el.totalCcfNox,
                    totalCcfContrails: el.totalCcfContrails,
                    ccfMonthDelta: el.ccfMonthDelta,
                    potentialCcfSaving: el.potentialCcfSaving,
                    meanGwp100: el.meanGwp100,
                    totalGwp100: el.totalGwp100,
                    totalGwp100Co2: el.totalGwp100Co2,
                    totalGwp100H2o: el.totalGwp100H2o,
                    totalGwp100Nox: el.totalGwp100Nox,
                    totalGwp100Contrails: el.totalGwp100Contrails,
                    gwp100MonthDelta: el.gwp100MonthDelta,
                    potentialGwp100Saving: el.potentialGwp100Saving,
                    nbFlights: el.nbFlights,
                };
                this.cards.push(card);
            });
        }
    }

    private _handleRequests(): void {
        this._pendingCardsHttpRequest = new Subject<Date | void>();
        this._pendingCardsHttpRequest
            .pipe(
                switchMap(() =>
                    this._cli.getClimateAnalyticsCards(
                        this.dateDisplayed,
                        this.cardsPage,
                        this.cardsPageSize,
                        this.formControlDeparture.value!,
                        this.formControlArrival.value!,
                    ),
                ),
            )
            .subscribe({
                next: (results) => {
                    this.extractData(results);
                    this.isDashboardLoading = false;

                    if (results.length === this.cardsPageSize) {
                        this.cardsPage += 1;
                    } else {
                        this.cardsPage = 1;
                    }
                },
                error: (error) => {
                    this.errors = error;
                    console.error(error);
                    this._handleRequests();
                    this.isDashboardLoading = false;
                },
            });

        this._pendingFlightsHttpRequest = new Subject<Date | void>();
        this._pendingFlightsHttpRequest
            .pipe(
                switchMap(() =>
                    this._cli.getFlightsFromCityPair(
                        this.dateDisplayed,
                        this.currentCityPair!.startAirportCode,
                        this.currentCityPair!.endAirportCode,
                    ),
                ),
            )
            .subscribe({
                next: (results) => {
                    // If no flight, go back to dashboard
                    this.cityPairAircraftTypeCounter = [];
                    this.flightsTableFlights = [];
                    if (results.flights.length === 0) {
                        this.currentlySelectedFlights = [];
                        this.isTableLoading = false;
                        this.isTableHeaderOpen = false;
                    } else {
                        this.currentCityPair = results;
                        this.flightsTableFlights = results.flights;
                        this.flightsTableFlights.sort(
                            (a, b) => new Date(a.startDate + ' ' + a.startTime).getTime() - new Date(b.startDate + ' ' + b.startTime).getTime(),
                        );
                        this.isTableLoading = false;
                        [...new Set(this.flightsTableFlights.map((el) => el.aircraftType))]?.forEach((el) =>
                            this.cityPairAircraftTypeCounter.push({
                                name: el,
                                number: this.flightsTableFlights.filter((el2) => el2.aircraftType === el).length,
                            }),
                        );
                    }
                },
                error: (error) => {
                    this.errors = error;
                    console.error(error);
                    this._handleRequests();
                    this.isTableLoading = false;
                },
            });
    }
}
