import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Flight } from '../../models/flight.interface';
import { ClimateAnalyticsService } from '../../services/climate-analytics/climate-analytics.service';
import { MapService } from '../../../../shared/services';
import { catchError, concatAll, filter, timeout } from 'rxjs/operators';
import { Observable, TimeoutError, of, throwError } from 'rxjs';
import { Reference, Trajectory } from '../../../../shared/models/trajectory.interface';
import { CoreService } from '../../../../core/services/core/core.service';
import { LoadingDialogComponent, MapComponent } from '../../../../shared/components';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixPanelService } from '../../../../core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatDate, FormatHoursAndMinutes, FormatPartialDate, GetSeason } from '../../../../shared/pipes/format-data.pipe';
import { ComparisonModalComponent } from './components/comparison/comparison-modal/comparison-modal.component';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ComparisonSearchCard } from '../../models/card.interface';
import { animate, style, transition, trigger } from '@angular/animations';
import { Feature, FeatureCollection } from 'geojson';
import { Flight as FlightCollection } from 'src/app/shared/components/vertical-view/models/vertical-view.interface';

@Component({
    selector: 'ffp-analytics-past-flight-analysis',
    templateUrl: './past-flight-analysis.component.html',
    styleUrls: ['./past-flight-analysis.component.scss'],
    providers: [FormatHoursAndMinutes, FormatPartialDate, FormatDate, GetSeason],
    animations: [
        trigger('cardAnimation', [
            transition(':enter', [style({ opacity: 0, scale: 0.9 }), animate(200)]),
            transition(':leave', animate(200, style({ opacity: 0, scale: 0.9 }))),
        ]),
    ],
})
export class PastFlightAnalysisComponent implements OnInit, OnDestroy {
    public triggerAnim = false;
    public flights: Flight[] = [];
    public selectedFlight:
        | {
              flightNumber: {
                  startTime: string;
                  startDate: string;
                  flightNumber: string;
              };
          }
        | undefined;
    public flightTrajectory!: Trajectory;
    public flightFromCityPairExplorer: boolean = false;

    public mapIsLoading = true;
    public displayFilter = false;
    public errors = null;

    @ViewChild('mapComponent') mapComponent!: MapComponent;
    @ViewChild('verticalDisplayComponent') verticalDisplayComponent!: ElementRef;

    public infoToDisplay: string[] = [];
    public flightDisplayed: {
        startTime: string;
        startDate: string;
        flightNumber: string;
    } | null = null;
    public flightsToCompare: ComparisonSearchCard[] = [];
    public highlightedFlight: string | null = null;
    public bestFlight: ComparisonSearchCard | undefined;
    private _currentQueryParams: object = {};

    constructor(
        private readonly _climateAnalyticsService: ClimateAnalyticsService,
        private _mapService: MapService,
        private _coreService: CoreService,
        private _matDialog: MatDialog,
        private readonly _snackBar: MatSnackBar,
        private _mixPanelService: MixPanelService,
        private _authService: AuthService,
        private _route: ActivatedRoute,
        private _router: Router,
        public dialog: MatDialog,
        private _formatHoursAndMinutes: FormatHoursAndMinutes,
        private _formatPartialDate: FormatPartialDate,
        private _getSeason: GetSeason,
        private _formatDate: FormatDate,
    ) {
        _coreService.setDisplayWarning(!this._authService.airport);
        setTimeout(() => this.mapComponent.initMap(), 500);
        if (
            this._coreService.getRouterExtras().state &&
            this._coreService.getRouterExtras().state!['flightsToCompare'] &&
            this._router.getCurrentNavigation()!.previousNavigation
        ) {
            this.flightsToCompare = this._coreService.getRouterExtras().state!['flightsToCompare'];
        }
    }

    get flightSource(): FlightCollection {
        return {
            flightNumber: this.flightTrajectory.flightNumber,
            startDate: this.flightTrajectory.startDate,
            startTime: this.flightTrajectory.startTime,
            id: 'flight',
            visible: this.flightTrajectory.visible,
            source: this._mapService.getSource('flight--data-at-points'),
        };
    }

    get similarFlightSources(): {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    }[] {
        const sources: {
            flightNumber: string;
            startDate: string;
            startTime: string;
            source: Feature | FeatureCollection;
            visible: boolean;
            id: string;
        }[] = [];
        this.flightTrajectory.references
            .filter((r) => r.id.includes('similar-flight'))
            .forEach((s) => {
                if (s && this._mapService.getSource(s.id + '--data-at-points')) {
                    const similarFlightSource = {
                        flightNumber: s.trajectory.flightNumber,
                        startDate: s.trajectory.startDate,
                        startTime: s.trajectory.startTime,
                        source: this._mapService.getSource(s.id + '--data-at-points'),
                        visible: s.visible,
                        id: s.id,
                    };
                    sources.push(similarFlightSource);
                }
            });
        return sources;
    }

    get verticalViewEnabled(): boolean {
        return this._mapService.verticalViewEnabled;
    }

    ngOnInit(): void {
        this.flightFromCityPairExplorer = this._router.url.includes('city-pair-explorer');

        this._route.queryParams
            .pipe(
                filter((params) => {
                    if (this._currentQueryParams) {
                        const copy = Object.assign({}, params);
                        delete copy['emissionMeasure'];

                        if (this.flightTrajectory) {
                            this._climateAnalyticsService
                                .getBestFlight(
                                    this.flightTrajectory?.startAirportCode,
                                    this.flightTrajectory?.endAirportCode,
                                    params['emissionMeasure'],
                                )
                                .subscribe((bestFlight) => {
                                    this.bestFlight = bestFlight;
                                });
                        }

                        return JSON.stringify(copy) !== JSON.stringify(this._currentQueryParams);
                    }
                    return true;
                }),
            )
            .subscribe((params) => {
                this._currentQueryParams = Object.assign({}, params);
                if ('emissionMeasure' in this._currentQueryParams) {
                    delete this._currentQueryParams['emissionMeasure'];
                }
                if (params['fn'] && params['time'] && params['date']) {
                    this.triggerAnim = true;
                    this.flightDisplayed = {
                        flightNumber: params['fn'],
                        startDate: params['date'],
                        startTime: params['time'],
                    };
                    this.computeFlight({
                        flightNumber: this.flightDisplayed,
                    });

                    if (params['emissionMeasure']) {
                        this._coreService.emissionMeasure = params['emissionMeasure'];
                    }
                }
            });
    }

    computeFlight(flight: {
        flightNumber: {
            startTime: string;
            startDate: string;
            flightNumber: string;
        };
    }): void {
        if (this.flightTrajectory !== undefined) {
            this._mixPanelService.track('back-to-selection');
            this.mapComponent.reset();
            this._mapService.sources = [];
            this._mapService.verticalViewEnabled = false;
            this._mapService.resetSelectedFeature();
            this._mapService.buildMap();
        }

        this.selectedFlight = flight;
        this.mapIsLoading = true;
        const dialogRef = this._matDialog.open(LoadingDialogComponent, {
            panelClass: 'loader',
            disableClose: true,
            data: {
                steps: ['Flight'],
            },
        });

        this._climateAnalyticsService
            .computeEmissions(
                this.selectedFlight.flightNumber.flightNumber,
                this.selectedFlight.flightNumber.startDate,
                this.selectedFlight.flightNumber.startTime,
            )
            .pipe(
                timeout(90000),
                catchError((e) => {
                    return throwError(e);
                }),
            )
            .subscribe(
                (result) => {
                    this.flightTrajectory = result;
                    this.flightTrajectory.visible = true;

                    this.infoToDisplay = [
                        this.flightTrajectory.startAirportCode,
                        this.flightTrajectory.endAirportCode,
                        this.flightTrajectory.flightNumber,
                        this._formatPartialDate.transform(this.flightTrajectory.startDate)!,
                        this._formatHoursAndMinutes.transform(this.flightTrajectory.startTime)!,
                        this._formatHoursAndMinutes.transform(this.flightTrajectory.endTime)!,
                        this.flightTrajectory.aircraftType,
                        this._getSeason.transform(this.flightTrajectory.startDate)!,
                    ];

                    this.flightTrajectory.references = [];
                    this.flightTrajectory.id = 'flight';
                    this._climateAnalyticsService.resetVisibleTrajectory();
                    this._climateAnalyticsService.addVisibleTrajectory('flight');

                    this._mapService.formatTrajectory(this.flightTrajectory);
                    this._mapService.addOnMap(
                        this.flightTrajectory,
                        'flight',
                        {
                            start: this.flightTrajectory.startAirportCode,
                            end: this.flightTrajectory.endAirportCode,
                        },
                        this.mapIsLoading,
                    );

                    // compute city pair best flight
                    this._climateAnalyticsService
                        .getBestFlight(
                            this.flightTrajectory.startAirportCode,
                            this.flightTrajectory.endAirportCode,
                            this._coreService.emissionMeasure,
                        )
                        .subscribe((bestFlight) => {
                            this.bestFlight = bestFlight;
                        });

                    this.flightTrajectory.comparisonCriteriaCcf = Math.round(
                        ((this.flightTrajectory?.totalCcfAbs - this.flightTrajectory?.cityPairAvgCcf) / this.flightTrajectory?.cityPairAvgCcf) * 100,
                    );

                    this.flightTrajectory.comparisonCriteriaGwp100 = Math.round(
                        ((this.flightTrajectory?.totalGwp100 - this.flightTrajectory?.cityPairAvgGwp100) / this.flightTrajectory?.cityPairAvgGwp100) *
                            100,
                    );

                    if (this.flightsToCompare?.length > 0) {
                        this.computeFlightsToCompare(this.flightsToCompare);
                    }
                    dialogRef.close();
                    this.mapIsLoading = false;
                },
                (error) => {
                    dialogRef.close();
                    let errorMsg = 'An error occurred on server side.';
                    if (error instanceof TimeoutError) {
                        errorMsg = 'Server did not respond within a reasonable delay.';
                    } else if (error.statusText === 'BAD REQUEST') {
                        errorMsg = 'Bad request : no flights were found with the given parameters, please try with other parameters.';
                    }
                    this._coreService.pushNotification({
                        title: 'Past flight analysis',
                        description: errorMsg,
                        icon: 'error',
                        lifeDuration: 'infinite',
                        info: '',
                        index: 0,
                        type: 'ERROR',
                    });
                },
            );
    }

    handleFormSubmission(flight: { departure: string; arrival: string; aircraftType: string; date: string; flightNumber: Flight }): void {
        this._mixPanelService.track('compute-flight', {
            selectedFlight: flight,
        });

        this._router.navigate([], {
            queryParams: {
                date: flight.flightNumber.startDate,
                fn: flight.flightNumber.flightNumber,
                time: flight.flightNumber.startTime,
                emissionMeasure: this._coreService.emissionMeasure,
            },
            queryParamsHandling: 'merge',
        });
    }

    public back(): void {
        this._mixPanelService.track('back-to-selection');
        this._mapService.sources = [];
        this._mapService.verticalViewEnabled = false;
        this._mapService.resetSelectedFeature();
        this._mapService.buildMap();
        this.mapIsLoading = true;
        if (this._router.url.includes('city-pair-explorer')) {
            const date = new Date(this.flightTrajectory.startDate);
            date.setDate(1);
            this._router.navigate(['/climate-analytics/city-pair-explorer'], {
                queryParams: {
                    date: this._formatDate.transform(date.toString(), 'yyyy-mm-dd'),
                    cityPair: this.flightTrajectory.startAirportCode + '-' + this.flightTrajectory.endAirportCode,
                    emissionMeasure: this._coreService.emissionMeasure,
                },
                state: {
                    selectedFlights: [this.flightTrajectory, ...this.flightsToCompare],
                },
            });
        }
    }

    public changeVerticalViewMap(verticalViewEnabled: boolean): void {
        this._mapService.verticalViewEnabled = verticalViewEnabled;
        this._mixPanelService.track('map-toggle-vertical-view', {
            enabled: this._mapService.verticalViewEnabled,
        });
        setTimeout(() => {
            this._mapService.resize();
            this._mapService.zoomOn();
        }, 310);
    }

    public openCompareSearchModal(): void {
        const dialogRef = this.dialog.open(ComparisonModalComponent, {
            data: {
                referenceFlight: this.flightTrajectory,
                flightsToCompare: [...this.flightsToCompare],
                bestFlight: this.bestFlight,
            },
            backdropClass: 'comparison-custom-dialog-backdrop',
            panelClass: 'comparison-custom-dialog-container',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.flights?.length > 0) {
                this.computeFlightsToCompare(result.flights);
            }
        });
    }

    public removeSimilarFlight(similarFlight: Reference): void {
        this._mapService.removeFromMap(similarFlight.id);
        this.flightTrajectory.references = this.flightTrajectory.references.filter((el) => el.id !== similarFlight.id);
        this.flightsToCompare = this.flightsToCompare.filter(
            (el) => el.startTime !== similarFlight.trajectory.startTime || el.startDate !== similarFlight.trajectory.startDate,
        );

        if (!this.flightTrajectory.references.filter((el) => el.trajectory.visible).length && !this.flightTrajectory.visible) {
            this.flightTrajectory.visible = true;
            this.changeTrajectoryVisibility('flight');
        }
    }

    public isBestFlight(flight: Trajectory): boolean {
        return flight.startDate === this.bestFlight?.startDate && flight.startTime === this.bestFlight?.startTime;
    }

    public highlightFlight(layerName: string | null) {
        this.highlightedFlight = layerName;
    }

    public changeTrajectoryVisibility(layerName: string) {
        if (layerName === 'flight') {
            if (!this.flightTrajectory.visible) {
                this._climateAnalyticsService.removeVisibleTrajectory(layerName);
            } else {
                this._climateAnalyticsService.addVisibleTrajectory(layerName);
            }
            this._mapService.toggleLayerVisibility(layerName, this.flightTrajectory.visible);
        } else if (layerName.includes('similar')) {
            const traj = this.flightTrajectory.references.find((el) => el.id === layerName)!;
            traj.visible = traj.trajectory.visible;

            if (!traj.trajectory.visible) {
                this._climateAnalyticsService.removeVisibleTrajectory(layerName);
            } else {
                this._climateAnalyticsService.addVisibleTrajectory(layerName);
            }
            this._mapService.toggleLayerVisibility(layerName, traj.trajectory.visible);
        }
    }

    computeFlightsToCompare(flightsToCompare: ComparisonSearchCard[]) {
        const requests: Observable<Trajectory>[] = [];
        let currentStep = 0;

        flightsToCompare.forEach((el) => {
            if (
                this.flightsToCompare.find((flight) => flight.startDate === el.startDate && flight.startTime === el.startTime) === undefined ||
                this.flightTrajectory.references.length === 0
            ) {
                requests.push(
                    this._climateAnalyticsService.computeEmissions(el.flightNumber, el.startDate, el.startTime).pipe(
                        timeout(90000),
                        catchError((e) => {
                            return throwError(e);
                        }),
                    ),
                );
            }
        });

        let loader: MatDialogRef<LoadingDialogComponent>;
        if (requests.length) {
            this.mapIsLoading = true;

            loader = this._matDialog.open(LoadingDialogComponent, {
                panelClass: 'loader',
                disableClose: true,
                data: {
                    steps: ['Flight'],
                },
            });
        }

        // if new flights to compare are different then the flights to compare already computed
        // we remove them from the map
        const trajToRemove = this.flightsToCompare.filter((x) => !flightsToCompare.map((el) => JSON.stringify(el)).includes(JSON.stringify(x)));
        const trajToRemoveId = trajToRemove.map(
            (el) =>
                this.flightTrajectory.references.find(
                    (el2) => el2.trajectory.startDate === el.startDate && el2.trajectory.startTime === el.startTime,
                )!.id,
        );
        trajToRemoveId.forEach((el) => {
            this._mapService.removeFromMap(el);
        });

        this.flightsToCompare = flightsToCompare;
        this.flightTrajectory.references = this.flightTrajectory?.references.filter((ref) => !trajToRemoveId.includes(ref.id));

        of(...requests)
            .pipe(concatAll())
            .subscribe((value: Trajectory) => {
                const similarFlight: Trajectory = value;
                const referencesId = this.flightTrajectory.references.map((el) => el.id);
                for (let i = 1; i <= 2; i++) {
                    if (!referencesId.includes('similar-flight-' + i)) {
                        similarFlight.id = 'similar-flight-' + i;
                        similarFlight.visible = true;
                        break;
                    }
                }

                this._climateAnalyticsService.addVisibleTrajectory(similarFlight.id);

                // Get comparison criteria : ratio between displayed flight emission and city pair average
                similarFlight.comparisonCriteriaCcf = Math.round(
                    ((similarFlight.totalCcfAbs - similarFlight.cityPairAvgCcf) / similarFlight.cityPairAvgCcf) * 100,
                );

                similarFlight.comparisonCriteriaGwp100 = Math.round(
                    ((similarFlight.totalGwp100 - similarFlight.cityPairAvgGwp100) / similarFlight.cityPairAvgGwp100) * 100,
                );

                this._mapService.formatTrajectory(similarFlight);

                this.flightTrajectory.references.push({
                    id: similarFlight.id,
                    name: `Similar flight-${similarFlight.flightNumber} ${similarFlight.startDate} ${similarFlight.startTime}`,
                    visible: true,
                    trajectory: similarFlight,
                });

                this._mapService.addOnMap(
                    similarFlight,
                    similarFlight.id,
                    {
                        start: similarFlight.startAirportCode,
                        end: similarFlight.endAirportCode,
                    },
                    this.mapIsLoading,
                );

                this.mapIsLoading = false;

                currentStep++;
                if (currentStep === requests.length && loader) {
                    loader.close();
                }
            });
    }

    ngOnDestroy(): void {
        this._mapService.sources = [];
        this._mapService.verticalViewEnabled = false;
        this._mapService.resetSelectedFeature();
    }
}
