<mat-dialog-content class="mat-typography">
    <qtm-typography fontWeight="bold" fontSize="5" cdkFocusInitial> You are about to leave Flights Footprint </qtm-typography>
    <qtm-typography fontWeight="normal" fontSize="7"> Do you really want to logout ? </qtm-typography>
</mat-dialog-content>

<mat-dialog-actions>
    <ffp-button type="secondary" size="small" ghost (ffpClick)="closeDialog()">
        <qtm-typography fontWeight="medium" fontSize="7"> Back </qtm-typography>
    </ffp-button>
    <ffp-button type="primary" gradient size="small" (ffpClick)="closeDialog('yes')">
        <qtm-typography fontWeight="medium" fontSize="7"> Confirm </qtm-typography>
    </ffp-button>
</mat-dialog-actions>
