<mat-icon class="warning-icon">warning</mat-icon>
<div mat-dialog-title class="title">Warning!</div>
<mat-dialog-content class="mat-typography">
    <span class="content">
        You are about to clear all fields from the current form.
        <br />
        Do you really want to proceed ?
    </span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button cdkFocusInitial>NBSP;</button>
    <button mat-raised-button mat-dialog-close class="back-btn">Back</button>
    <button mat-raised-button [mat-dialog-close]="'clear'" color="accent">Confirm</button>
</mat-dialog-actions>
