import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ComparisonSearchCard } from '../../../../../models/card.interface';
import { CoreService } from '../../../../../../../core/services/core/core.service';
import { ConvertGramsToTons, ConvertToPicoKelvin } from '../../../../../../../shared/pipes/format-data.pipe';

@Component({
    selector: 'ffp-analytics-comparison-search-card',
    templateUrl: './search-card.component.html',
    styleUrls: ['./search-card.component.scss'],
    providers: [ConvertGramsToTons, ConvertToPicoKelvin],
})
export class SearchCardComponent {
    @Input() set flight(value: ComparisonSearchCard) {
        this.data = value;
        if (this._coreService.emissionMeasure.toLowerCase() === 'ccf') {
            this.emissionDistributions = {
                co2: parseFloat(this._convertToPicoKelvin.transform(this.data.totalCcfCo2)),
                h2o: parseFloat(this._convertToPicoKelvin.transform(this.data.totalCcfH2o)),
                nox: parseFloat(this._convertToPicoKelvin.transform(this.data.totalCcfNox)),
                contrails: parseFloat(this._convertToPicoKelvin.transform(this.data.totalCcfContrails)),
            };
        } else {
            this.emissionDistributions = {
                co2: parseFloat(this._convertGramsToTons.transform(this.data.totalGwp100Co2)),
                h2o: parseFloat(this._convertGramsToTons.transform(this.data.totalGwp100H2o)),
                nox: parseFloat(this._convertGramsToTons.transform(this.data.totalGwp100Nox)),
                contrails: parseFloat(this._convertGramsToTons.transform(this.data.totalGwp100Contrails)),
            };
        }
    }

    @Input() isBestFlight = false;

    @Input() climateImpactComparison: number = 0;

    @Input() isSelected = false;

    @Output() closeButtonCallback = new EventEmitter();

    public emissionDistributions: {
        co2: number;
        h2o: number;
        nox: number;
        contrails: number;
    } = {
        co2: 0,
        h2o: 0,
        nox: 0,
        contrails: 0,
    };
    public data: ComparisonSearchCard | undefined;
    public isHovered = false;

    constructor(
        public _coreService: CoreService,
        private _convertGramsToTons: ConvertGramsToTons,
        private _convertToPicoKelvin: ConvertToPicoKelvin,
    ) {}

    @HostListener('mouseover', ['$event'])
    mouseover() {
        this.isHovered = true;
    }

    @HostListener('mouseleave', ['$event'])
    mouseleave() {
        this.isHovered = false;
    }

    close() {
        this.closeButtonCallback.emit();
    }
}
