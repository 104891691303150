<div class="map-button" (click)="clicked.emit()" [ngClass]="{ red: iconClass === 'edit_off' }">
    @if (iconClass === "compass") {
        <em
            class="{{ iconClass }}"
            [ngStyle]="{
                transform: bearing ? 'rotate(' + -1 * bearing + 'deg)' : '',
            }"
        ></em>
    }
    @if (iconClass !== "compass") {
        <mat-icon>{{ iconClass }}</mat-icon>
    }
</div>
