import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ManagementService } from '../../services/management/management.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Flight } from '../../models/flight.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderComponent } from '../../../../shared/components';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
    selector: 'ffp-admin-remove-flights',
    templateUrl: './remove-flights.component.html',
    styleUrls: ['./remove-flights.component.scss'],
})
export class RemoveFlightsComponent implements OnInit, OnChanges {
    public displayedColumns: string[] = ['select', 'flightNumber', 'startAirportName', 'endAirportName', 'startDate', 'endDate'];
    public flights = new MatTableDataSource<Flight>([]);
    public selection = new SelectionModel<Flight>(true, []);
    @Input() reloading = false;

    constructor(
        private readonly _managementService: ManagementService,
        private readonly _snackBar: MatSnackBar,
        private readonly _matDialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this._managementService.getFlights().subscribe((flights) => {
            flights.forEach((flight) => this.flights.data.push(flight));
            this.sortFlightsList(this.flights.data);
            this.flights._updateChangeSubscription();
        });
    }

    sortFlightsList(flights: Flight[]): void {
        flights.sort((flightA, flightB) => {
            return flightA.flightNumber.localeCompare(flightB.flightNumber);
        });
    }

    ngOnChanges(): void {
        if (this.reloading) {
            const temp: Flight[] = [];
            this._managementService.getFlights().subscribe((flights) => {
                flights.forEach((flight) => temp.push(flight));
                this.sortFlightsList(temp);
                if (JSON.stringify(temp) !== JSON.stringify(this.flights.data)) {
                    this.flights.data = temp;
                }
            });
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.flights.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.flights.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Flight): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.flights.data.indexOf(row) + 1}`;
    }

    updateFlightsList(): void {
        this.selection.selected.forEach((item) => {
            this.flights.data = this.flights.data.filter((x) => x !== item);
        });
        this.selection.clear();
        this.isAllSelected();
    }

    filter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.flights.filter = filterValue.trim().toLowerCase();
    }

    remove(): void {
        const loaderRef = this._matDialog.open(LoaderComponent, {
            panelClass: 'loader-transparent',
            disableClose: true,
        });
        const requests: Observable<{ message: string }>[] = [];
        this.selection.selected.forEach((item) => {
            requests.push(this._managementService.removeFlight(item.flightNumber, item.startDate.toString(), item.startTime!.toString()));
        });
        requests.forEach((request) => {
            request.subscribe(
                () => {
                    this._snackBar.open('Successfully removed', '', {
                        panelClass: 'success-snackbar',
                        duration: 2000,
                    });
                    this.updateFlightsList();
                    loaderRef.close();
                    this.flights._updateChangeSubscription();
                },
                () => {
                    loaderRef.close();
                    this._snackBar.open('An error occurred on server side', '', {
                        panelClass: 'error-snackbar',
                        duration: 2000,
                    });
                },
            );
        });
    }

    get flightsToImport(): number {
        return this.selection.selected.length;
    }
}
