import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlternativeInfo, Card } from '../../models/card.interface';
import { ForecastService } from '../../services/forecast/forecast.service';
import { AutoConvert } from '../../../../shared/pipes/format-data.pipe';

@Component({
    selector: 'ffp-forecast-card',
    templateUrl: './forecast-card.component.html',
    styleUrls: ['./forecast-card.component.scss'],
    providers: [AutoConvert],
})
export class ForecastCardComponent implements OnInit, OnDestroy {
    @HostBinding('class.is-best-alternative')
    isBestAlternative = false;

    @Input()
    data!: Card;

    bestAlternative: AlternativeInfo | undefined;
    displayedAlternative: AlternativeInfo | undefined;
    isActive = false;
    hideShadow = false;
    private _subscription;

    @Output()
    public hovered: EventEmitter<string> = new EventEmitter();

    constructor(
        private forecastService: ForecastService,
        private _autoConvert: AutoConvert,
    ) {
        this._subscription = this.forecastService.getHoveredTagFlightIndex().subscribe((newValue) => {
            this.isActive = this.data?.flightIndex === newValue;
            this.hideShadow = newValue !== null ? this.data?.flightIndex !== newValue : false;
        });

        this._subscription = this.forecastService.getHoveredCardFlightIndex().subscribe((newValue) => {
            this.hideShadow = newValue !== null ? this.data?.flightIndex !== newValue : false;
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    cardIsHovered(): void {
        this.forecastService.setHoveredCardFlightIndex(this.data.flightIndex);
    }

    cardIsNoLongerHovered(): void {
        this.forecastService.setHoveredCardFlightIndex(null);
    }

    ngOnInit(): void {
        this.isBestAlternative = this.data?.hasBestAlternative;
        this.bestAlternative = this.data?.alternatives.find((el) => el.isBestAlternative);
        this.displayedAlternative = this.bestAlternative;
        this.data?.alternatives.sort((a, b) => a.flightLevel.localeCompare(b.flightLevel));
    }
}
