import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as mixpanel from 'mixpanel-browser';
import { AuthService } from '../../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class MixPanelService {
    // Params used to track user origin
    public utmSource: string | undefined;
    public utmCampaign: string | undefined;

    private lastTrackedEvent: string | undefined;
    private lastTrackedProperties: object | undefined;
    private pageViewed: string | undefined;

    get isProduction(): boolean {
        return environment.appUrl === 'https://flights-footprint.com';
    }

    constructor(private readonly _authService: AuthService) {
        if (this.isProduction) {
            mixpanel.init('0b42edaa14b5fba95e91eeab4b7d65e8');
        }
    }

    public identify(): void {
        const userId = this._authService.isLoggedIn
            ? this._authService.userMail
                ? this._authService.userMail
                : this._authService.firstName!.toLowerCase()
            : 'anonymous';
        if (this.isProduction) {
            mixpanel.identify(userId);
            mixpanel.people.set({
                $name: this._authService.firstName + ' ' + this._authService.lastName,
                $email: userId,
                Company: this._authService.airline !== 'thales' ? this._authService.airline : 'public',
                Plan: 'free',
            });

            // Only if utm_source parameter filled in URL
            if (this.utmSource) {
                mixpanel.people.set({
                    Source: this.utmSource,
                });
            }
            // Only if utm_campaign parameter filled in URL
            if (this.utmCampaign) {
                mixpanel.people.set({
                    Campaign: this.utmCampaign,
                });
            }
        }
    }

    public track(event: string, data?: object): void {
        const originalEvent = event;
        if (this.isProduction) {
            // If event includes '-viewed' it means that it is a navigation to new page
            if (originalEvent.includes('-viewed') || originalEvent.includes('log')) {
                // If previous page already visited, then emit 'duration event' in order to have time on page
                if (this.pageViewed) {
                    mixpanel.track(this.pageViewed + '-duration');
                }
                // Update current page
                this.pageViewed = event.split('-viewed')[0];
                event = event.split('-viewed')[0];
            }

            if (event !== this.lastTrackedEvent || JSON.stringify(data) !== JSON.stringify(this.lastTrackedProperties)) {
                this.lastTrackedEvent = event;
                this.lastTrackedProperties = data ? Object.assign({}, data) : undefined;
                if (data) {
                    mixpanel.track(event, data);
                } else {
                    mixpanel.track(event);
                }
                if (originalEvent.includes('-viewed') || originalEvent.includes('log')) {
                    mixpanel.time_event(this.pageViewed + '-duration');
                }
            }
        }
    }

    public logout(): void {
        if (this.isProduction) {
            mixpanel.track('logout');
            mixpanel.reset();
        }
    }
}
