<div
    class="content"
    [ngStyle]="{
        'background-color': sassHelperService.getProperty('ffp-' + coreService.getCurrentService()?.index + '-150'),
    }"
>
    <div class="left">
        <img src="/assets/icons/ffp-white.svg" alt="FFP LOGO" (click)="coreService.navigate('/')" />
        <qtm-typography fontSize="6" fontWeight="bold" (click)="coreService.navigate('/')"> Flights Footprint </qtm-typography>
        @if (coreService.getCurrentService()?.value !== "default") {
            <qtm-typography fontSize="6" fontWeight="thin">
                {{ coreService.getCurrentService()?.value?.toUpperCase() }}
            </qtm-typography>
        }
    </div>

    <div class="middle"></div>

    <div class="right">
        <qtm-typography
            fontSize="7"
            fontWeight="bold"
            [ngStyle]="{
                color: sassHelperService.getProperty('ffp-' + coreService.getCurrentService()?.index + '-25'),
            }"
        >
            {{ coreService.getCurrentDate() | formatFullDate }}
        </qtm-typography>
        @if (coreService.getCurrentDate()) {
            <qtm-typography fontSize="7" fontWeight="bold" class="time"> {{ coreService.getCurrentDate() | formatFullTime }} UTC </qtm-typography>
        }
    </div>
</div>
