@if (!errors && displayedFlightsFiltered.data.length > 0) {
    <table mat-table [dataSource]="displayedFlightsFiltered" aria-label="Flights' list" [ngClass]="{ 'header-opened': isHeaderOpened }">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef id="selectColumn">
                <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                    disableRipple
                    [ngClass]="{
                        hide: rowHovered !== 'header' && !coreService.isTactile,
                        show: rowHovered === 'header' || selection.selected.length !== 0 || coreService.isTactile,
                    }"
                >
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? handleCheckboxChange(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)"
                        [ngClass]="{
                            hide: rowHovered !== row && !coreService.isTactile,
                            show: rowHovered === row || selection.isSelected(row) || coreService.isTactile,
                        }"
                    >
                    </mat-checkbox>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef id="dateColumn">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'dateHeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'dateHeaderCell' || sorting.column === 'startDate',
                        ascending: sorting.ascending && sorting.column === 'startDate',
                        descending: !sorting.ascending && sorting.column === 'startDate',
                    }"
                >
                    <qtm-typography fontSize="9" fontWeight="medium"> Date </qtm-typography>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'dateHeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'dateHeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('startDate', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'startDate',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('startDate', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'startDate',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="multi-line-cell">
                    <qtm-typography fontSize="9" fontWeight="medium" class="top">
                        {{ element.startDate | formatDate }}
                    </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="medium" class="below">
                        {{ element.startTime | formatHoursAndMinutes }} UTC
                    </qtm-typography>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Flight Number Column -->
        <ng-container matColumnDef="flightNumber">
            <th mat-header-cell *matHeaderCellDef id="flightNumberColumn">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'flightNumberHeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'flightNumberHeaderCell' || sorting.column === 'flightNumber',
                        ascending: sorting.ascending && sorting.column === 'flightNumber',
                        descending: !sorting.ascending && sorting.column === 'flightNumber',
                    }"
                >
                    <div class="header-cell-text">
                        <qtm-typography fontSize="9" fontWeight="medium"> Flight Number </qtm-typography>
                        <qtm-typography component="body-2" fontSize="9" class="legend"> Registration </qtm-typography>
                    </div>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'flightNumberHeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'flightNumberHeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('flightNumber', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'flightNumber',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('flightNumber', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'flightNumber',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="multi-line-cell">
                    <qtm-typography fontSize="9" fontWeight="medium">
                        {{ element.flightNumber }}
                    </qtm-typography>
                    @if (element.aircraftRegistration !== "TODO") {
                        <div class="icon-and-text below">
                            <qtm-typography fontSize="9" fontWeight="medium">
                                {{ element.aircraftRegistration }}
                            </qtm-typography>
                        </div>
                    }
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Aircraft Type Column -->
        <ng-container matColumnDef="aircraftType">
            <th mat-header-cell *matHeaderCellDef id="aircraftTypeColumn">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'aircraftTypeHeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'aircraftTypeHeaderCell' || sorting.column === 'aircraftType',
                        ascending: sorting.ascending && sorting.column === 'aircraftType',
                        descending: !sorting.ascending && sorting.column === 'aircraftType',
                    }"
                >
                    <qtm-typography fontSize="9" fontWeight="medium"> Ac type </qtm-typography>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'aircraftTypeHeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'aircraftTypeHeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('aircraftType', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'aircraftType',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('aircraftType', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'aircraftType',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <qtm-typography fontSize="9" fontWeight="medium">
                    {{ element.aircraftType }}
                </qtm-typography>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- FL Column -->
        <ng-container matColumnDef="flightLevel">
            <th mat-header-cell *matHeaderCellDef id="flightLevelColumn">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'flightLevelHeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'flightLevelHeaderCell' || sorting.column === 'cruiseFl',
                        ascending: sorting.ascending && sorting.column === 'cruiseFl',
                        descending: !sorting.ascending && sorting.column === 'cruiseFl',
                    }"
                >
                    <div class="header-cell-text">
                        <qtm-typography fontSize="9" fontWeight="medium"> FL </qtm-typography>
                        <qtm-typography component="body-2" fontSize="9" class="legend"> MAX </qtm-typography>
                    </div>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'flightLevelHeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'flightLevelHeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('cruiseFl', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'cruiseFl',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('cruiseFl', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'cruiseFl',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <qtm-typography fontSize="9" fontWeight="medium">
                    {{ element.cruiseFl }}
                </qtm-typography>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Consumption Details Column -->
        <ng-container matColumnDef="consumptionDetails">
            <th mat-header-cell *matHeaderCellDef id="consumptionDetailsColumn">
                <qtm-typography fontSize="9" fontWeight="medium"> Climate effects details </qtm-typography>
                @if (this.coreService.emissionMeasure === "Ccf") {
                    <qtm-typography component="body-2" fontSize="9" class="legend"> ATR<sub>20</sub> </qtm-typography>
                } @else {
                    <qtm-typography component="body-2" fontSize="9" class="legend"> GWP<sub>100</sub> </qtm-typography>
                }
            </th>
            <td mat-cell *matCellDef="let element">
                @if (this.coreService.emissionMeasure === "Ccf") {
                    <ffp-consumption-details
                        [tooltip]="true"
                        [data]="{
                            co2: element.totalCcfCo2 / 1e-12 || 0,
                            h2o: element.totalCcfH2o / 1e-12 || 0,
                            nox: element.totalCcfNox / 1e-12 || 0,
                            contrails: element.totalCcfContrails / 1e-12 || 0,
                        }"
                        [svgId]="element.startAirportCode + '-' + element.endAirportCode + '-' + element.startDate"
                        [parentWidth]="cellWidth"
                    >
                    </ffp-consumption-details>
                } @else {
                    <ffp-consumption-details
                        [tooltip]="true"
                        [data]="{
                            co2: element.totalGwp100Co2 / 1000000 || 0,
                            h2o: element.totalGwp100H2o / 1000000 || 0,
                            nox: element.totalGwp100Nox / 1000000 || 0,
                            contrails: element.totalGwp100Contrails / 1000000 || 0,
                        }"
                        [svgId]="element.startAirportCode + '-' + element.endAirportCode + '-' + element.startDate"
                        [parentWidth]="cellWidth"
                    >
                    </ffp-consumption-details>
                }
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Fuel Column -->
        <ng-container matColumnDef="fuel">
            <th mat-header-cell *matHeaderCellDef id="fuelColumn">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'fuelHeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'fuelHeaderCell' || sorting.column === 'totalFuelBurnt',
                        ascending: sorting.ascending && sorting.column === 'totalFuelBurnt',
                        descending: !sorting.ascending && sorting.column === 'totalFuelBurnt',
                    }"
                >
                    <div class="header-cell-text">
                        <qtm-typography fontSize="9" fontWeight="medium"> Fuel </qtm-typography>
                        <qtm-typography component="body-2" fontSize="9" class="legend"> KILOGRAMS </qtm-typography>
                    </div>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'fuelHeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'fuelHeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalFuelBurnt', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'totalFuelBurnt',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalFuelBurnt', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'totalFuelBurnt',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <qtm-typography fontSize="9" fontWeight="medium">
                    {{ element.totalFuelBurnt / 1000 | formatNumber }}
                </qtm-typography>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- Atr20 Column -->
        <ng-container matColumnDef="atr20">
            <th mat-header-cell *matHeaderCellDef id="atr20Column">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'atr20HeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'atr20HeaderCell' || sorting.column === 'totalCcf',
                        ascending: sorting.ascending && sorting.column === 'totalCcf',
                        descending: !sorting.ascending && sorting.column === 'totalCcf',
                    }"
                >
                    <div class="header-cell-text">
                        <qtm-typography fontSize="9" fontWeight="medium"> Atr20 </qtm-typography>
                        <qtm-typography component="body-2" fontSize="9" class="legend"> PICOKELVIN </qtm-typography>
                    </div>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'atr20HeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'atr20HeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalCcf', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'totalCcf',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalCcf', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'totalCcf',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <qtm-typography fontSize="9" fontWeight="medium">
                    {{ element.totalCcf | convertToPicoKelvin | formatNumber }}
                </qtm-typography>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <!-- GWP100 Column -->
        <ng-container matColumnDef="gwp100">
            <th mat-header-cell *matHeaderCellDef id="gwp100Column">
                <div
                    class="header-cell"
                    (click)="headerCellHovered = 'gwp100HeaderCell'; sortingOverlayIsClosed = false"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [ngClass]="{
                        'is-active': headerCellHovered === 'gwp100HeaderCell' || sorting.column === 'totalGwp100',
                        ascending: sorting.ascending && sorting.column === 'totalGwp100',
                        descending: !sorting.ascending && sorting.column === 'totalGwp100',
                    }"
                >
                    <div class="header-cell-text">
                        <qtm-typography fontSize="9" fontWeight="medium"> GWP100 </qtm-typography>
                        <qtm-typography component="body-2" fontSize="9" class="legend"> KG CO<sub>2</sub>-eq </qtm-typography>
                    </div>
                    <ng-template
                        cdkConnectedOverlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="headerCellHovered === 'gwp100HeaderCell' && sortingOverlayIsClosed === false"
                        (overlayOutsideClick)="headerCellHovered = null"
                    >
                        <div
                            class="city-pair-explorer-drop-down-container"
                            (mouseenter)="headerCellHovered = 'gwp100HeaderCell'"
                            (mouseleave)="headerCellHovered = null"
                        >
                            <div class="city-pair-explorer-drop-down">
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalGwp100', true)"
                                    [ngClass]="{
                                        'is-selected': sorting.ascending && sorting.column === 'totalGwp100',
                                    }"
                                >
                                    <mat-icon> arrow_upward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort ascending </qtm-typography>
                                </div>
                                <div
                                    class="drop-down-item"
                                    (click)="sortData('totalGwp100', false)"
                                    [ngClass]="{
                                        'is-selected': !sorting.ascending && sorting.column === 'totalGwp100',
                                    }"
                                >
                                    <mat-icon> arrow_downward </mat-icon>
                                    <qtm-typography fontSize="9" fontWeight="normal"> Sort descending </qtm-typography>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <qtm-typography fontSize="9" fontWeight="medium">
                    {{ element.totalGwp100 / 1000 | formatNumber }}
                </qtm-typography>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            (mouseover)="rowHovered = 'header'"
            (mouseleave)="rowHovered = null"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="handleCheckboxChange(row)"
            [ngClass]="{
                selected: selection.isSelected(row),
                'reference-flight': selection.selected[0] === row,
            }"
            (mouseover)="rowHovered = row"
            [matTooltip]="selection.selected[0] === row ? 'Other flights selected\n will be compared to this one.' : ''"
            [matTooltipPosition]="'right'"
            matTooltipClass="climate-analytics-tooltip"
            (mouseleave)="rowHovered = null"
        ></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
}
