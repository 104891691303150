import { AfterViewInit, Component } from '@angular/core';
import { ForecastService } from './services/forecast/forecast.service';
import { ForecastFlight } from './models/flight.interface';
import { AlternativeInfo, Card } from './models/card.interface';
import { CoreService } from '../../core/services/core/core.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'ffp-forecast',
    templateUrl: './forecast.component.html',
    styleUrls: ['./forecast.component.scss'],
})
export class ForecastComponent implements AfterViewInit {
    flights!: ForecastFlight[];
    cards: Card[] = [];
    errors: HttpErrorResponse | null = null;
    isLoading = false;
    isComingFlightFullScreen: boolean = false;

    constructor(
        private readonly _forecastService: ForecastService,
        private _coreService: CoreService,
        private _route: ActivatedRoute,
    ) {
        this.loadData();
        _coreService.setDisplayWarning(true);
    }

    ngAfterViewInit(): void {
        document
            .querySelector('body')!
            .style.setProperty(
                '--forecast-card-container-height',
                `${(document.querySelector('.cards-container') as HTMLDivElement).clientHeight}px`,
            );
    }

    sortCard(): void {
        // Sort cards info so the best alternatives is displayed on the middle
        const second = this.cards[1];
        const bestAlternativeIndex = this.cards.findIndex((el) => el.hasBestAlternative);
        if (this.cards.length > 1) {
            this.cards[1] = this.cards[bestAlternativeIndex];
            this.cards[bestAlternativeIndex] = second;
        }
    }

    loadData(): void {
        this.isLoading = true;
        this.errors = null;
        this._forecastService.getComingFlights().subscribe(
            (result) => {
                this.flights = result;
                this.flights.forEach((el) => {
                    el.initialFlight.realStartDate = new Date(el.initialFlight.startDate + 'T' + el.initialFlight.startTime + 'Z');
                    el.initialFlight.realEndDate = new Date(el.initialFlight.endDate + 'T' + el.initialFlight.endTime + 'Z');
                    el.initialFlight.flightDuration = (el.initialFlight.realEndDate.getTime() - el.initialFlight.realStartDate.getTime()) / 60000;
                    el.alternatives.forEach((alt) => (alt.cruiseFlightLevel = 'FL' + alt.cruiseFlightLevel));
                });
                this.flights.slice(0, 3).forEach((flight) => {
                    const flightAlternative: AlternativeInfo[] = [];
                    flight.alternatives.slice(0, 3).forEach((alternative) => {
                        flightAlternative.push({
                            flightLevel: alternative.cruiseFlightLevel,
                            fuelRatio: (
                                ((alternative.totalFuelBurnt - flight.initialFlight.totalFuelBurnt) / flight.initialFlight.totalFuelBurnt) *
                                100
                            ).toFixed(0),
                            ccfRatio: (((alternative.totalCcf - flight.initialFlight.totalCcf) / flight.initialFlight.totalCcf) * 100).toFixed(0),
                            totalCcf: alternative.totalCcf,
                            isBestAlternative: alternative.isBestAlternative,
                        });
                    });
                    if (flight.alternatives.length > 0) {
                        const hasAlternativesBetterThanInitialFlight =
                            flightAlternative.filter((a) => a.isBestAlternative)[0].flightLevel !== 'FL' + flight.initialFlight.cruiseFlightLevel;
                        flight.initialFlight.hasBestAlternative = flight.initialFlight.hasBestAlternative && hasAlternativesBetterThanInitialFlight;
                        const card: Card = {
                            aircraftType: flight.initialFlight.aircraftType,
                            endAirportCode: flight.initialFlight.endAirportCode,
                            endDate: flight.initialFlight.realEndDate,
                            flightLevel: flight.initialFlight.cruiseFlightLevel,
                            totalCcf: flight.initialFlight.totalCcf,
                            hasBestAlternative: flight.initialFlight.hasBestAlternative,
                            startAirportCode: flight.initialFlight.startAirportCode,
                            startDate: flight.initialFlight.realStartDate,
                            callSign: flight.initialFlight.callSign,
                            alternatives: flightAlternative,
                            flightIndex: flight.index,
                        };
                        if (hasAlternativesBetterThanInitialFlight) {
                            this.cards.push(card);
                        }
                    }
                });

                this.sortCard();

                // setTimeout(() => (this.isLoading = false), 10000);
                this.isLoading = false;
            },
            (error) => {
                this.errors = error;
                console.error(error);
            },
        );
    }

    handleComingFlightFullScreen(value: boolean): void {
        this.isComingFlightFullScreen = value;
    }
}
