import { animate, style, transition, trigger } from '@angular/animations';

export const menuAnimation = trigger('in-out-animation', [
    transition(':enter', [
        style({ marginLeft: 20, opacity: 0 }),
        animate('0.2s ease-out', style({ opacity: 0 })),
        animate('0.3s ease-out', style({ marginLeft: 36, opacity: 1 })),
    ]),
    transition(':leave', [style({ marginLeft: 36, opacity: 1 }), animate('0.13s ease-out', style({ opacity: 0, marginLeft: 20 }))]),
]);
