import { Pipe, PipeTransform } from '@angular/core';

/*
   CONVERSIONS
*/

@Pipe({ name: 'convertKgToTons' })
export class ConvertKgToTonsPipe implements PipeTransform {
    transform(value: string): string {
        return (parseFloat(value) / 1000).toFixed(2);
    }
}

@Pipe({ name: 'convertGramsToTons' })
export class ConvertGramsToTons implements PipeTransform {
    transform(value: number): string {
        return (value * 1e-6).toFixed(2);
    }
}

@Pipe({ name: 'convertMetersToNM' })
export class ConvertMetersToNM implements PipeTransform {
    transform(value: number): string {
        return (value / 1852).toFixed();
    }
}

@Pipe({ name: 'convertMsToHoursAndMinutes' })
export class ConvertMsToHoursAndMinutes implements PipeTransform {
    transform(value: number): string {
        const seconds = Math.floor(value / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        minutes = minutes % 60;
        hours = hours % 24;

        return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
    }
}

@Pipe({ name: 'convertMetersToFeet' })
export class ConvertMetersToFeet implements PipeTransform {
    transform(value: string): string {
        return (parseFloat(value) * 3.281).toFixed();
    }
}

@Pipe({ name: 'convertMetersPerMsToKts' })
export class ConvertMetersPerMsToKts implements PipeTransform {
    transform(value: string): string {
        return (parseFloat(value) * 1943.84449).toFixed();
    }
}

@Pipe({ name: 'convertToPicoKelvin' })
export class ConvertToPicoKelvin implements PipeTransform {
    transform(value: number): string {
        return value / 1e-12 > 10 ? Math.round(value / 1e-12).toString() : (value / 1e-12).toString();
    }
}

@Pipe({ name: 'convertMeterToFlightLevel' })
export class ConvertMeterToFlightLevel implements PipeTransform {
    transform(value: number): number {
        return Math.round((value * 3.28084) / 100);
    }
}

@Pipe({ name: 'convertFlightLevelToMeter' })
export class ConvertFlightLevelToMeter implements PipeTransform {
    transform(value: number): number {
        return Math.round((value / 3.28084) * 100);
    }
}

@Pipe({ name: 'convertMeterToNauticalMiles' })
export class ConvertMeterToNauticalMiles implements PipeTransform {
    transform(value: number): number {
        return value * 0.000539957;
    }
}

@Pipe({ name: 'convertMeterToFeet' })
export class ConvertMeterToFeet implements PipeTransform {
    transform(value: number): number {
        return value * 3.28084;
    }
}

// convert given value the most logical way (ex: 1000 grams => 1 kg)
// use : autoConvert:unit:returnFormat
// return format possible value : unit, value, both
// (ex: variable | autoConvert:'g':'unit')
@Pipe({ name: 'autoConvert' })
export class AutoConvert implements PipeTransform {
    transform(value: number, unit: string, returnFormat: string): string | undefined {
        if (value === 0) {
            return '0 ' + unit;
        }

        let prefix = '';
        let sizes;
        let k;
        let i;
        if (value < 0) {
            prefix = '-';
            value = -value;
        }
        if (value < 1) {
            k = 0.001;
            sizes = ['', 'm', 'µ', 'n', 'p', 'f', 'a', 'z', 'y'];
            i = Math.ceil(Math.log(value) / Math.log(k));
        } else {
            k = 1000;
            sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

            i = Math.floor(Math.log(value) / Math.log(k));
        }
        if (i < 0) {
            i = 0;
        }

        let suffix = sizes[i] + unit;
        switch (unit) {
            case 'g':
                if (sizes[i] === 'M') {
                    suffix = 't';
                }
                if (sizes[i] === 'G') {
                    suffix = 'e3 t';
                }
                break;
            default:
                break;
        }

        const results = prefix + parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + suffix;

        if (returnFormat === 'value' || returnFormat === 'unit') {
            const resultArray = [results.split(' ')[0], results.split(results.split(' ')[0])[1]];
            return returnFormat === 'value' ? resultArray[0] : resultArray[1];
        } else if (returnFormat === 'both') {
            return results;
        }
        return;
    }
}

/*
   FORMAT DATE AND TIME
*/

@Pipe({ name: 'formatDate' })
export class FormatDate implements PipeTransform {
    transform(value: string | Date | null, returnFormat: string = 'mm/dd/yyyy'): string | undefined {
        if (!value) {
            return;
        }
        const date = new Date(value);
        const day = date.getDate();
        const dd = day < 10 ? '0' + day : day;
        const month = date.getMonth() + 1;
        const mm = month < 10 ? '0' + month : month;
        const yyyy = date.getFullYear();

        if (returnFormat === 'yyyy-mm-dd') {
            return yyyy + '-' + mm + '-' + dd;
        }

        if (returnFormat === 'mm/dd/yy') {
            return dd + '/' + mm + '/' + (yyyy % 100);
        }

        return dd + '/' + mm + '/' + yyyy;
    }
}

// return format : Day of week, Month day, year (ex : Wednesday, May 10, 2023)
@Pipe({ name: 'formatFullDate' })
export class FormatFullDate implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value.toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
            });
        }
        return;
    }
}

// return format : Month day, year (ex : May 2, 2023)
@Pipe({ name: 'formatPartialDate' })
export class FormatPartialDate implements PipeTransform {
    transform(value: string | Date): string | undefined {
        const date = new Date(value);
        if (date) {
            return date.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
            });
        }
        return;
    }
}

// return format : hh:mm:ss (ex: 14:12:39)
@Pipe({ name: 'formatFullTime' })
export class FormatFullTime implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value
                .toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'UTC',
                    hourCycle: 'h23',
                })
                .toUpperCase();
        }
        return;
    }
}

// return format : hhhmm (ex: 14h12)
@Pipe({ name: 'formatHoursAndMinutes' })
export class FormatHoursAndMinutes implements PipeTransform {
    transform(value: string): string | undefined {
        if (value) {
            const date = new Date();
            const [hours, minutes, seconds] = value.split(':');
            date.setHours(parseFloat(hours));
            date.setMinutes(parseFloat(minutes));
            date.setSeconds(parseFloat(seconds));
            return date
                .toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hourCycle: 'h23',
                })
                .toUpperCase()
                .replace(':', 'h');
        }
        return;
    }
}

// return format : Short-month year (ex: Feb 2023)
@Pipe({ name: 'formatMonthAndYear' })
export class FormatMonthAndYear implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value.toLocaleString('en-US', {
                month: 'short',
                hour12: false,
                year: 'numeric',
            });
        }
        return;
    }
}

// return format : Month (ex: February)
@Pipe({ name: 'getMonth' })
export class GetMonth implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value.toLocaleString('en-US', {
                month: 'long',
                hour12: false,
            });
        }
        return;
    }
}

// return format : SEASON (ex: SUMMER)
@Pipe({ name: 'getSeason' })
export class GetSeason implements PipeTransform {
    transform(value: string): string | undefined {
        if (value) {
            const date = new Date(value);
            return ['WINTER', 'SPRING', 'SUMMER', 'AUTUMN'][Math.floor((date.getMonth() / 12) * 4) % 4];
        }
        return;
    }
}

/*
   FORMAT DATA
*/

// add space between number (ex : 1000 => 1 000)
@Pipe({ name: 'formatNumber' })
export class FormatNumber implements PipeTransform {
    transform(value: string | number): string {
        value = typeof value === 'number' ? value.toString() : value;
        if (parseFloat(value) < 10) {
            return (Math.round(100 * parseFloat(value)) / 100).toString();
        } else {
            return Math.round(parseFloat(value))
                .toString()
                .replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
        }
    }
}

// uppercase first letter of string
@Pipe({ name: 'uppercaseFirstLetter' })
export class UppercaseFirstLetter implements PipeTransform {
    transform(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}

// return format : YYYY (ex: 2024)
@Pipe({ name: 'getYear' })
export class GetYear implements PipeTransform {
    transform(value: Date): string | undefined {
        if (value) {
            return value.toLocaleString('en-US', {
                year: 'numeric',
                hour12: false,
            });
        }
        return;
    }
}
