import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceComponent, MenuComponent, NotificationComponent, NotificationPanelComponent, ServiceComponent } from './components';
import { MaintenanceService, SassHelperService } from './services';
import { MatButtonModule } from '@angular/material/button';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { MenuItemComponent } from './components/menu/components/item/menu-item.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { MatIconModule } from '@angular/material/icon';
import { ProfileComponent } from './components/menu/components/profile/profile.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        ServiceComponent,
        MaintenanceComponent,
        MenuComponent,
        MenuItemComponent,
        TopBarComponent,
        ProfileComponent,
        NotificationPanelComponent,
        NotificationComponent,
    ],
    exports: [ServiceComponent, MaintenanceComponent, MenuComponent, TopBarComponent, NotificationPanelComponent],
    imports: [CommonModule, MatButtonModule, QtmComponentLibraryModule, MatIconModule, SharedModule],
    providers: [MaintenanceService, SassHelperService],
})
export class CoreModule {}
