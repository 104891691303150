<div class="container">
    <div class="content">
        <div class="header">
            <div class="left">
                <qtm-typography fontSize="4" fontWeight="bold" class="title"> Flight comparison </qtm-typography>

                <div class="reference-flight">
                    <div class="reference-flight-header">
                        <qtm-typography fontSize="6" fontWeight="medium" class="title"> Current flight </qtm-typography>
                        @if (data.referenceFlight && isBestFlight(data.referenceFlight)) {
                            <div class="best-flight">
                                <mat-icon class="material-icons-round">star</mat-icon>
                                <qtm-typography fontSize="8" fontWeight="medium"> Best </qtm-typography>
                            </div>
                        }
                    </div>
                    <div class="reference-flight-info">
                        <div class="item">
                            <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
                                {{ data.referenceFlight.startAirportCode }}
                            </qtm-typography>
                            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                                {{ data.referenceFlight.startTime | formatHoursAndMinutes }}
                            </qtm-typography>
                        </div>
                        <div class="divider"></div>
                        <div class="item">
                            <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
                                {{ data.referenceFlight.endAirportCode }}
                            </qtm-typography>
                            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                                {{ data.referenceFlight.endTime | formatHoursAndMinutes }}
                            </qtm-typography>
                        </div>
                        <div class="divider"></div>
                        <div class="item">
                            <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
                                {{ data.referenceFlight.flightNumber }}
                            </qtm-typography>
                            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                                {{ data.referenceFlight.aircraftType }}
                            </qtm-typography>
                        </div>
                        <div class="divider"></div>
                        <div class="item">
                            <qtm-typography fontSize="7" fontWeight="bold" class="first-row">
                                {{ data.referenceFlight.startDate | formatPartialDate }}
                            </qtm-typography>
                            <qtm-typography fontSize="9" fontWeight="bold" class="second-row">
                                {{ data.referenceFlight.startDate | getSeason }}
                            </qtm-typography>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <ffp-button type="primary" size="small" neutral (ffpClick)="dialogRef.close()">
                    <mat-icon class="material-icons-round"> close </mat-icon>
                </ffp-button>
            </div>
        </div>
        <div class="selection">
            <qtm-typography fontSize="8" class="label"> Select the flights you want to compare, 2 max. </qtm-typography>
            @if (flightsLoading) {
                <div class="list-loading" [ngClass]="{ 'is-loading': flightsLoading }">
                    <div class="card loading-element"></div>
                </div>
            } @else {
                <div class="selection-content">
                    <div class="selection-card">
                        @if (!isBestFlightSelected && !isBestFlight(data.referenceFlight)) {
                            <ffp-button type="primary" size="small" gradient (ffpClick)="selectBest()">
                                <mat-icon class="material-icons-round">star</mat-icon>
                                <qtm-typography fontSize="8" class="label" fontWeight="bold"> Best </qtm-typography>
                            </ffp-button>
                        }
                        <qtm-typography fontSize="8" class="label">
                            @if (!isBestFlightSelected && !isBestFlight(data.referenceFlight)) {
                                <b>Or <br /></b>
                            }
                            Select the flight you want to compare
                        </qtm-typography>
                    </div>
                    @if (selectedCards.length === 1) {
                        <div class="selection-card" @comparisonCardAnimation>
                            @if (!isBestFlightSelected && !isBestFlight(data.referenceFlight)) {
                                <ffp-button type="primary" size="small" gradient (ffpClick)="selectBest()">
                                    <mat-icon class="material-icons-round">star</mat-icon>
                                    <qtm-typography fontSize="8" class="label" fontWeight="bold"> Best </qtm-typography>
                                </ffp-button>
                            }
                            <qtm-typography fontSize="8" class="label">
                                @if (!isBestFlightSelected && !isBestFlight(data.referenceFlight)) {
                                    <b>Or <br /></b>
                                }
                                Select the flight you want to compare
                            </qtm-typography>
                        </div>
                    }
                    @for (flight of selectedCards; track flight) {
                        <ffp-analytics-comparison-search-card
                            @comparisonCardAnimation
                            [flight]="flight"
                            [isSelected]="true"
                            (closeButtonCallback)="deselect(flight)"
                            [isBestFlight]="isBestFlight(flight)"
                            [climateImpactComparison]="computeClimateImpactComparison(flight)"
                        ></ffp-analytics-comparison-search-card>
                    }
                </div>
            }
        </div>
        <div class="modal-divider"></div>
        <div class="flight-list">
            <qtm-typography fontSize="6" fontWeight="medium" class="title"> Available flights to compare with </qtm-typography>
            <div class="filter">
                <div class="sorting comparison-select">
                    <mat-form-field>
                        <mat-label>Sort by</mat-label>
                        <mat-select
                            [(value)]="selectedSorting"
                            (selectionChange)="sortFlights()"
                            class="comparison-select"
                            disableOptionCentering
                            #selectSort
                        >
                            <mat-select-trigger>
                                {{ selectedSorting?.displayedName }}
                            </mat-select-trigger>
                            @for (option of sortOptions; track option) {
                                <mat-option [value]="option" class="comparison-select-option">
                                    <div class="select-option">
                                        @if (!option.icon.includes("assets")) {
                                            <mat-icon>{{ option.icon }}</mat-icon>
                                        } @else {
                                            <img [src]="option.icon" alt="" />
                                        }
                                        {{ option.displayedName }}
                                    </div>
                                </mat-option>
                            }
                        </mat-select>
                        <mat-icon class="material-icons-round" matSuffix>{{
                            selectSort.panelOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
                        }}</mat-icon>
                        <!-- FIXME: Remove this once Material issue resolved -->
                        <input matInput style="display: none" />
                    </mat-form-field>
                </div>
                <div class="season-filter comparison-select">
                    <mat-form-field>
                        <mat-label>Season</mat-label>
                        <mat-select
                            [(value)]="selectedSeason"
                            (selectionChange)="pendingHttpRequest.next()"
                            class="comparison-select"
                            disableOptionCentering
                            #selectSeason
                        >
                            <mat-select-trigger>
                                @if (selectedSeason && selectedSeason.name) {
                                    {{ selectedSeason.name.charAt(0).toUpperCase() + selectedSeason.name.slice(1) }}
                                }
                            </mat-select-trigger>
                            @for (option of seasons; track option) {
                                <mat-option [value]="option" class="comparison-select-option">
                                    <div class="select-option">
                                        @if (!option.icon.includes("assets")) {
                                            <mat-icon>{{ option.icon }}</mat-icon>
                                        }
                                        {{ option.name.charAt(0).toUpperCase() + option.name.slice(1) }}
                                    </div>
                                </mat-option>
                            }
                        </mat-select>
                        <mat-icon class="material-icons-round" matSuffix>{{
                            selectSeason.panelOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
                        }}</mat-icon>
                        <!-- FIXME: Remove this once Material issue resolved -->
                        <input matInput style="display: none" />
                    </mat-form-field>
                </div>
                <div class="date-filter comparison-select">
                    <mat-form-field>
                        <mat-label>Date range</mat-label>
                        <mat-select
                            [(value)]="selectedDateRange"
                            (selectionChange)="updateDateRange()"
                            class="comparison-select"
                            disableOptionCentering
                            #selectDateRange
                        >
                            <mat-select-trigger>
                                {{ selectedDateRange }}
                            </mat-select-trigger>
                            @for (option of dateRangeSelection; track option) {
                                <mat-option [value]="option" class="comparison-select-option">
                                    <div class="select-option">
                                        {{ option }}
                                    </div>
                                </mat-option>
                            }
                        </mat-select>
                        <mat-icon class="material-icons-round" matSuffix>{{
                            selectDateRange.panelOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
                        }}</mat-icon>
                        <!-- FIXME: Remove this once Material issue resolved -->
                        <input matInput style="display: none" />
                    </mat-form-field>
                </div>
                <div class="select-date" [ngClass]="{ disabled: !enableCustomDates }">
                    <mat-form-field>
                        <div class="datepicker-content start-date">
                            <div
                                class="left"
                                (click)="openDatepicker(startPicker, true)"
                                [ngClass]="{
                                    'value-is-selected': startRangeDateFilter,
                                }"
                            >
                                <mat-label>Start date</mat-label>
                                <mat-date-range-input [rangePicker]="startPicker" [max]="minDate(createNewDate(7), endRangeDateFilter!)">
                                    <input
                                        matStartDate
                                        readonly
                                        [disabled]="!enableCustomDates"
                                        [value]="startRangeDateFilter"
                                        (dateChange)="updateStartDateFilter($event.value!)"
                                    />
                                    <input
                                        matEndDate
                                        readonly
                                        [disabled]="!enableCustomDates"
                                        [style.display]="'none'"
                                        [value]="endRangeDateFilter"
                                        (dateChange)="updateEndDateFilter($event.value!)"
                                    />
                                </mat-date-range-input>
                                <!-- FIXME: Remove this once Material issue resolved -->
                                <input matInput style="display: none" />
                            </div>
                            <div class="right">
                                <button
                                    [disabled]="!enableCustomDates"
                                    (click)="updateStartDateFilter(createNewDate(-1, startRangeDateFilter!)); pendingHttpRequest.next()"
                                >
                                    <mat-icon class="material-icons-round"> chevron_left </mat-icon>
                                </button>
                                <button
                                    [disabled]="!enableCustomDates || startRangeDateFilter! > createNewDate(6, currentDate)"
                                    (click)="updateStartDateFilter(createNewDate(1, startRangeDateFilter!)); pendingHttpRequest.next()"
                                >
                                    <mat-icon class="material-icons-round"> navigate_next </mat-icon>
                                </button>
                            </div>
                        </div>
                        <mat-date-range-picker
                            #startPicker
                            panelClass="comparison-modal-datepicker"
                            (closed)="pendingHttpRequest.next()"
                        ></mat-date-range-picker>

                        <div class="datepicker-content end-date">
                            <div
                                class="left"
                                (click)="openDatepicker(picker, false)"
                                [ngClass]="{
                                    'value-is-selected': endRangeDateFilter,
                                }"
                            >
                                <mat-label>End date</mat-label>
                                <mat-date-range-input [rangePicker]="picker" [max]="createNewDate(7)" [min]="startRangeDateFilter">
                                    <input
                                        matStartDate
                                        readonly
                                        [disabled]="!enableCustomDates"
                                        [style.display]="'none'"
                                        [value]="startRangeDateFilter"
                                        (dateChange)="updateStartDateFilter($event.value!)"
                                    />
                                    <input
                                        matEndDate
                                        readonly
                                        [max]="currentDate"
                                        [disabled]="!enableCustomDates"
                                        [value]="endRangeDateFilter"
                                        (dateChange)="updateEndDateFilter($event.value!)"
                                    />
                                </mat-date-range-input>
                                <!-- FIXME: Remove this once Material issue resolved -->
                                <input matInput style="display: none" />
                            </div>
                            <div class="right">
                                <button
                                    [disabled]="!enableCustomDates"
                                    (click)="updateEndDateFilter(createNewDate(-1, endRangeDateFilter!)); pendingHttpRequest.next()"
                                >
                                    <mat-icon class="material-icons-round"> chevron_left </mat-icon>
                                </button>
                                <button
                                    [disabled]="!enableCustomDates || endRangeDateFilter! > createNewDate(6, currentDate)"
                                    (click)="updateEndDateFilter(createNewDate(1, endRangeDateFilter!)); pendingHttpRequest.next()"
                                >
                                    <mat-icon class="material-icons-round"> navigate_next </mat-icon>
                                </button>
                            </div>
                        </div>
                        <mat-date-range-picker
                            #picker
                            panelClass="comparison-modal-datepicker"
                            (closed)="pendingHttpRequest.next()"
                        ></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            @if (!flightsLoading && flights.length > 0) {
                <div class="list" (scroll)="loadMoreFlights()" #list>
                    @for (flight of flights; track flight) {
                        <ffp-analytics-comparison-search-card
                            @comparisonCardAnimation
                            (click)="selectCard(flight)"
                            [flight]="flight"
                            [isSelected]="false"
                            [isBestFlight]="isBestFlight(flight)"
                            [climateImpactComparison]="computeClimateImpactComparison(flight)"
                        ></ffp-analytics-comparison-search-card>
                    }
                </div>
            }
            @if (!flightsLoading && flights.length === 0) {
                <ffp-no-data
                    title="No flights were found"
                    description="No flights were found with the given filters. <br> Please change your filters to see more flights."
                    service="climate-analytics"
                    [button]="false"
                >
                </ffp-no-data>
            }
            @if (flightsLoading) {
                <div class="list-loading" [ngClass]="{ 'is-loading': flightsLoading }">
                    @for (card of loadingFlightCards; track card) {
                        <div class="card loading-element"></div>
                    }
                </div>
            }
        </div>
        <div class="buttons">
            <ffp-button type="primary" size="medium" neutral (ffpClick)="dialogRef.close()">
                <qtm-typography fontSize="7" fontWeight="medium"> Cancel </qtm-typography>
            </ffp-button>
            <ffp-button type="primary" size="medium" gradient (ffpClick)="launchComparison()" [disabled]="selectedCards.length === 0">
                <qtm-typography fontSize="7" fontWeight="medium"> Compare </qtm-typography>
            </ffp-button>
        </div>
    </div>
</div>
