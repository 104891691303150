import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth/auth.service';
import { CoreService } from '../../../../services/core/core.service';
import { Notification } from '../../../../models/notification.interface';

@Component({
    selector: 'ffp-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
    hideWarning = true;

    @Input()
    public notification: Notification | null = null;

    constructor(
        public authService: AuthService,
        public coreService: CoreService,
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.hideWarning = false;
        }, 500);
        if (this.notification?.lifeDuration !== 'infinite') {
            setTimeout(() => {
                this.closePanel();
            }, +this.notification!.lifeDuration);
        }
    }

    closePanel(): void {
        this.hideWarning = true;
        if (this.notification!.info === 'public-flight-warning') {
            this.coreService.setDisplayWarning(false);
        }
        setTimeout(() => this.coreService.removeNotification(this.notification!), 200);
    }
}
