<div class="container">
    @if (displayLabel) {
        <div class="top">
            @if (coreService.emissionMeasure === "Ccf") {
                <qtm-typography fontSize="9"> ATR<sub>20</sub> distributions </qtm-typography>
            }
            @if (coreService.emissionMeasure === "Gwp100") {
                <qtm-typography fontSize="9"> GWP<sub>100</sub> distributions </qtm-typography>
            }
            @if (coreService.emissionMeasure === "Ccf") {
                <qtm-typography fontWeight="light" fontSize="9"> in picoKelvin </qtm-typography>
            }
            @if (coreService.emissionMeasure === "Gwp100") {
                <qtm-typography fontWeight="light" fontSize="9"> in t CO<sub>2</sub>-eq </qtm-typography>
            }
        </div>
    }
    <div class="bottom">
        <div class="co2 item">
            <qtm-typography fontWeight="medium" fontSize="8"> CO<sub>2</sub> </qtm-typography>
            <div class="value">
                @if (displayValues) {
                    <qtm-typography fontWeight="bold" fontSize="8">
                        {{ data.co2! | formatNumber }}
                    </qtm-typography>
                }
                @if (!displayValues) {
                    <div class="no-data"></div>
                }
            </div>
        </div>
        <div class="nox item">
            <qtm-typography fontWeight="medium" fontSize="8"> NO<sub>X</sub> </qtm-typography>
            <div class="value">
                @if (displayValues) {
                    <qtm-typography fontWeight="bold" fontSize="8">
                        {{ data.nox! | formatNumber }}
                    </qtm-typography>
                }
                @if (!displayValues) {
                    <div class="no-data"></div>
                }
            </div>
        </div>
        <div class="contrails item">
            <qtm-typography fontWeight="medium" fontSize="8"> CONTRAILS </qtm-typography>
            <div class="value">
                @if (displayValues) {
                    <qtm-typography fontWeight="bold" fontSize="8">
                        {{ data.contrails! | formatNumber }}
                    </qtm-typography>
                }
                @if (!displayValues) {
                    <div class="no-data"></div>
                }
            </div>
        </div>
        <div class="h2o item">
            <qtm-typography fontWeight="medium" fontSize="8"> H<sub>2</sub>O </qtm-typography>
            <div class="value">
                @if (displayValues) {
                    <qtm-typography fontWeight="bold" fontSize="8">
                        {{ data.h2o! | formatNumber }}
                    </qtm-typography>
                }
                @if (!displayValues) {
                    <div class="no-data"></div>
                }
            </div>
        </div>
    </div>
</div>
