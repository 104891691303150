import { EventEmitter, Injectable } from '@angular/core';
import { AnyLayer, GeoJSONSource, LngLatBoundsLike, MapMouseEvent, Marker, Popup, ScaleControl, Sources, Style, Map as mapboxMap } from 'mapbox-gl';
import { AllGeoJSON, Properties, bbox, coordAll, featureCollection, lineString, nearest, propEach } from '@turf/turf';
import { environment } from '../../../../environments/environment';
import { MapboxStyleConfig } from '../../../../assets/map/mapboxStyleConfig';
import { SassHelperService } from '../../../core/services';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPoint, Point, Position } from 'geojson';
import { HttpClient } from '@angular/common/http';
import { FeatureCollectionWithProp, Trajectory } from '../../models/trajectory.interface';
import { Utils } from '../../utils/utils';

const MAPBOX_DEFAULT_TOLERANCE = 0.375;

const FLIGHT_WIDTH = 3;
const HIGHLIGHTED_FLIGHT_WIDTH = 6;
const NON_HIGHLIGHTED_FLIGHT_OPACITY = 0.5;
const FLIGHT_OPACITY = 1;

@Injectable({
    providedIn: 'root',
})
export class MapService {
    public selectedSegment: EventEmitter<{
        feature: Feature;
        firstIndex: number;
        lastIndex: number;
    } | null> = new EventEmitter<{
        feature: Feature;
        firstIndex: number;
        lastIndex: number;
    } | null>();

    public highlightedFlight: EventEmitter<string> = new EventEmitter<string>();

    public weatherLayerActivated!: string;

    public verticalViewEnabled = false;

    public highlightable = false;

    sources: (Feature | FeatureCollectionWithProp)[] = [];

    private _mapStyle: Style = {
        glyphs: MapboxStyleConfig.glyphs,
        layers: MapboxStyleConfig.layers as AnyLayer[],
        sources: MapboxStyleConfig.sources as Sources,
        sprite: environment.appUrl + '/assets/map/sprite',
        version: 8,
    };

    private _map!: mapboxMap;
    // private _popup;
    private _weatherPopup: Popup | null = null;
    private _firstSelectedPoint: Feature<Point> | null = null;
    private _selectedFeature: Feature | null = null;
    private _selectedFeatureFirstPointIndex: number | null = null;
    private _selectedFeatureLastPointIndex: number | null = null;
    private _legend: Map<string, [number, string][]> = new Map();
    private _flightPointsNumber: number = 0;
    private selectionEnabled = false;

    constructor(
        private _sassHelperService: SassHelperService,
        private _httpClient: HttpClient,
    ) {}

    get bearing(): number {
        return this._map.getBearing();
    }

    public getSource(sourceName: string): Feature | FeatureCollection {
        // @ts-expect-error: Only way to access Mapbox source data
        return this._map.getSource(sourceName)?._data;
    }

    public buildMap(): void {
        if (this._map) {
            this.weatherLayerActivated = '';
            this._map.remove();
        }
        this._map = new mapboxMap({
            accessToken: environment.mapboxAccessToken,
            container: 'map',
            style: this._mapStyle,
            preserveDrawingBuffer: true,
            attributionControl: false,
            logoPosition: 'bottom-right',
        });

        this._map.addControl(new ScaleControl(), 'bottom-left');

        this._map.on('load', () => {
            this._map.resize();
        });

        this._map.on('mousemove', this._mousemoveListener);
    }

    public getLegend(layerName: string): [number, string][] {
        return this._legend.get(layerName)!;
    }

    public resetBearing(): void {
        this._map.setPitch(0);
        this._map.rotateTo(0);
    }

    public zoomIn(): void {
        this._map.zoomIn({ duration: 500 });
    }

    public zoomOut(): void {
        this._map.zoomOut({ duration: 500 });
    }

    public resize(): void {
        this._map.resize();
    }

    public zoomOn(): void {
        let boundingBox;
        if (this._selectedFeature) {
            boundingBox = bbox(this._selectedFeature);
        } else {
            boundingBox = bbox(this.sources[0]);
        }
        if (this.verticalViewEnabled) {
            this._map.fitBounds(boundingBox.slice(0, 4) as LngLatBoundsLike, {
                padding: {
                    bottom: 450,
                    top: 150,
                    left: 100,
                    right: 450,
                },
            });
        } else {
            this._map.fitBounds(boundingBox.slice(0, 4) as LngLatBoundsLike, {
                padding: {
                    bottom: 100,
                    top: 150,
                    left: 100,
                    right: 450,
                },
            });
        }
    }

    public toggleLayerVisibility(layerName: string, show: boolean): void {
        this._map.setLayoutProperty(layerName, 'visibility', show ? 'visible' : 'none');
    }

    public toggleSelectionMode(enabled: boolean): void {
        this.selectionEnabled = enabled;
        if (enabled) {
            this._map.on('mousemove', this._mousemoveListener);
            this._map.on('click', this._mouseclickListener);
        } else {
            this._map.off('mousemove', this._mousemoveListener);
            this._map.off('click', this._mouseclickListener);
            this._mouseclickListener(null);
        }
    }

    public addDataAtPoints(layerName: string, coordinates: number[][], properties: Properties): void {
        if (this._map) {
            const geojson = this._getFeatureCollectionFromCoordinates(coordinates, 'Point', `${layerName}--data-at-points`, properties);

            if (!this._map.getSource(`${layerName}--data-at-points`)) {
                this._map.addSource(`${layerName}--data-at-points`, {
                    type: 'geojson',
                    lineMetrics: true,
                    data: geojson,
                });
            } else {
                (this._map.getSource(`${layerName}--data-at-points`) as GeoJSONSource).setData(geojson);
            }
            this._addTrajectoryPointsLayer(layerName);

            if (Object.keys(!properties).indexOf('contrails') > -1) {
                // BE CAREFUL, COLORS MUST BE DEFINED AS RGBA FOR VERTICAL VIEW
                this._legend.set('contrails', [
                    [-5e-11, 'rgba(0, 0, 255, 1)'],
                    [0, 'rgba(0, 0, 0, 0)'],
                    [5e-11, 'rgba(255, 0, 0, 1)'],
                ]);
                this._addWeatherLayer(layerName + '--contrails', 'contrails', this.getLegend('contrails'));
            }
            if (Object.keys(!properties).indexOf('wind') > -1) {
                // BE CAREFUL, COLORS MUST BE DEFINED AS RGBA FOR VERTICAL VIEW
                this._legend.set('wind', [
                    [0.9, 'rgba(0, 0, 255, 1)'],
                    [1, 'rgba(0,255,255, 1)'],
                    [1.1, 'rgba(255, 255, 0, 1)'],
                    [1.2, 'rgba(255, 0, 0, 1)'],
                ]);
                this._addWeatherLayer(layerName + '--wind', 'wind', this.getLegend('wind'));
            }

            this.zoomOn();
        }
    }

    public addTrajectoryFeature(
        layerName: string,
        coordinates: number[][],
        featureType: string,
        properties: Properties,
        flightInfo: { start: string; end: string },
    ): void {
        if (this._map) {
            const geojson: Feature = this._getFeatureFromCoordinates(coordinates, featureType, layerName, properties);
            if (!this._map.getSource(layerName)) {
                this._map.addSource(layerName, {
                    type: 'geojson',
                    lineMetrics: true,
                    data: geojson,
                });
            } else {
                (this._map.getSource(layerName) as GeoJSONSource).setData(geojson);
            }

            if (layerName === 'flight' || layerName === 'route-explorer-flight') {
                this._flightPointsNumber = coordinates.length;
                const geojsonExtremities = this._getFeatureFromCoordinates(
                    [coordinates[0], coordinates[coordinates.length - 1]],
                    featureType,
                    layerName,
                );
                this._addTrajectoryExtremitiesLayer(geojsonExtremities, flightInfo, layerName);
            }

            this._addTrajectoryLayer(layerName);

            this.zoomOn();
        }
    }

    public resetSelectedFeature(): void {
        this._selectedFeature = null;
    }

    // eslint-disable-next-line unused-imports/no-unused-vars
    public addOnMap(trajectory: Trajectory, layerName: string, flightInfo: { start: string; end: string }, mapIsLoading: boolean): void {
        setTimeout(() => {
            this.resize();
            this.addTrajectoryFeature(
                layerName,
                trajectory.points.map((p) => p.coordinates),
                'LineString',
                {
                    flightNumber: trajectory.flightNumber,
                    startDate: trajectory.startDate,
                },
                flightInfo,
            );
            this.addDataAtPoints(
                layerName,
                trajectory.points.map((p) => p.coordinates),
                {
                    contrails: trajectory.points.map((p) => p.ccfContrails),
                    distanceStart: trajectory.points.map((p) => p.groundDistanceToStart),
                    wind: trajectory.points.map((p) => p.windEffect),
                    flightNumber: trajectory.flightNumber,
                    startDate: trajectory.startDate,
                    ccfCo2: trajectory.points.map((p) => p.ccfCo2Abs),
                    ccfNox: trajectory.points.map((p) => p.ccfNoxAbs),
                    ccfContrails: trajectory.points.map((p) => p.ccfContrailsAbs),
                    ccfH2o: trajectory.points.map((p) => p.ccfH2oAbs),
                    totalCcfAbs: trajectory.points.map((p) => p.ccfAbs),
                    gwp100Co2: trajectory.points.map((p) => p.gwp100Co2),
                    gwp100Nox: trajectory.points.map((p) => p.gwp100Nox),
                    gwp100H2o: trajectory.points.map((p) => p.gwp100H2o),
                    gwp100Contrails: trajectory.points.map((p) => p.gwp100Contrails),
                    totalGwp100: trajectory.points.map((p) => p.gwp100),
                    phase: trajectory.points.map((p) => p.phase),
                },
            );
            mapIsLoading = false;
        }, 10);
    }

    public removeFromMap(layerName: string) {
        if (this._map) {
            this._map.removeLayer(layerName);
            this._map.removeSource(layerName);

            this._map.removeLayer(layerName + '--all-points');
            this._map.removeSource(layerName + '--all-points');

            if (this._map.getLayer(layerName + '--contrails')) {
                this._map.removeLayer(layerName + '--contrails');
                this._map.removeSource(layerName + '--contrails');
            }

            if (this._map.getLayer(layerName + '--wind')) {
                this._map.removeLayer(layerName + '--wind');
                this._map.removeSource(layerName + '--wind');
            }
        }
    }

    public formatTrajectory(trajectory: Trajectory): void {
        trajectory.points.forEach((p) => {
            p.coordinates = [p.coordinates[1], p.coordinates[0], p.coordinates[2]];
        });

        // Next bloc is to handle flights which cross antimeridian and jumps out of ]-180, 180]
        let crossesAntimeridian = false;
        for (let i = 1; i < trajectory.points.length; i++) {
            const lng = trajectory.points[i].coordinates[0];
            const prevLng = trajectory.points[i - 1].coordinates[0];
            if (Math.abs(lng - prevLng) > 180) {
                crossesAntimeridian = true;
                break;
            }
        }
        if (crossesAntimeridian) {
            trajectory.points.forEach((p) => {
                p.coordinates[0] = Utils.mod(p.coordinates[0], 360);
            });
        }

        trajectory.aircraftType = trajectory.aircraftType.toUpperCase();
    }

    public highlightFlight(highlightedFlightLayerId: string | null, currentlyHighlightedLayer: AnyLayer | null = null) {
        if (this._map) {
            const allLayersId = this._map
                .getStyle()!
                .layers.filter((l) => l.id.includes('--all-points'))
                .map((l) => l.id.split('--all-points')[0]);
            const allLayers = this._map.getStyle()!.layers.filter((l) => allLayersId.includes(l.id));
            if (!currentlyHighlightedLayer) {
                currentlyHighlightedLayer =
                    allLayers.filter((l) => this._map.getPaintProperty(l.id, 'line-width') === HIGHLIGHTED_FLIGHT_WIDTH)[0] || null;
            }
            if (currentlyHighlightedLayer && currentlyHighlightedLayer.id !== highlightedFlightLayerId) {
                this._map.setPaintProperty(currentlyHighlightedLayer.id, 'line-width', FLIGHT_WIDTH);
                this._map.setPaintProperty(currentlyHighlightedLayer.id, 'line-opacity', NON_HIGHLIGHTED_FLIGHT_OPACITY);
            }

            if (highlightedFlightLayerId && allLayersId.includes(highlightedFlightLayerId)) {
                this._map.setPaintProperty(highlightedFlightLayerId, 'line-width', HIGHLIGHTED_FLIGHT_WIDTH);
                this._map.setPaintProperty(highlightedFlightLayerId, 'line-opacity', FLIGHT_OPACITY);
            }

            if (highlightedFlightLayerId && allLayersId.includes(highlightedFlightLayerId)) {
                this._map.moveLayer(highlightedFlightLayerId);
                const nonHighlightedLayers = allLayersId.filter((el) => el !== highlightedFlightLayerId);
                nonHighlightedLayers.forEach((el) => this._map.setPaintProperty(el, 'line-opacity', NON_HIGHLIGHTED_FLIGHT_OPACITY));
            } else {
                if (allLayersId.includes('flight')) {
                    this._map.moveLayer('flight');
                }
                allLayersId.forEach((el) => this._map.setPaintProperty(el, 'line-opacity', FLIGHT_OPACITY));
            }
        }
    }

    private _resetSelectedFeature(): void {
        this.selectedSegment.emit(null);
        this._firstSelectedPoint = null;
        this._selectedFeature = null;
        this._selectedFeatureFirstPointIndex = null;
        this._selectedFeatureLastPointIndex = null;
        if (this._map.getLayer('flight')) {
            this._map.setPaintProperty('flight', 'line-opacity', FLIGHT_OPACITY);
            this._map.setLayoutProperty('flight', 'line-cap', 'round');
        }
        if (typeof this._map.getLayer('selectedPoint') !== 'undefined') {
            this._map.removeLayer('selectedPoint');
            this._map.removeSource('selectedPoint');
        }
        return;
    }

    private _addTrajectoryLayer(layerName: string): void {
        const linePaint = {
            'line-color': this._sassHelperService.getProperty('ffp-trajectory-' + layerName),
            'line-width': FLIGHT_WIDTH,
            'line-opacity': FLIGHT_OPACITY,
        };

        this._map.addLayer({
            id: layerName,
            type: 'line',
            source: layerName,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: linePaint,
        });

        if (this._map.getLayer('flight')) {
            this._map.moveLayer('flight');
        }

        // this._map.on('mouseenter', layerName, (e) => {
        //     const featuresUnderMouse = this._map.queryRenderedFeatures([[e.point.x - 1, e.point.y - 1], [e.point.x + 1, e.point.y + 1]]);
        //     const lineLayers = featuresUnderMouse.filter(f => f.layer.id.includes(layerName) && f.layer.type === 'line');
        //
        //     if (this._popup) {
        //         this._popup.remove();
        //     }
        //
        //     // const html = '<span>' +
        //     //     e.features[0].properties.flightNumber + ' - ' +
        //     //     e.features[0].properties.startDate + '</span>';
        //
        //     // if (lineLayers.length === 1) {
        //     //     this._map.getCanvas().style.cursor = 'crosshair';
        //     //     this._popup = new Popup({closeButton: false, anchor: 'right', className: 'mapTooltip ' + layerName, offset: 15})
        //     //     .setLngLat(e.lngLat)
        //     //     .setHTML(html)
        //     //     .addTo(this._map);
        //     // }
        // });

        // this._map.on('mouseleave', layerName, () => {
        //     this._map.getCanvas().style.cursor = 'inherit';
        //     if (this._popup) {
        //         this._popup.remove();
        //     }
        // });
    }

    private _addWeatherLayer(layerName: string, propertyName: string, colorsArray: [number, string][]): void {
        const allPointsFeatureCollection: FeatureCollection = this.getSource(layerName.split('--')[0] + '--data-at-points') as FeatureCollection;
        const features: Feature[] = [];
        for (let i = 1; i < allPointsFeatureCollection.features.length - 1; i++) {
            const prevPoint: Point = allPointsFeatureCollection.features[i - 1].geometry as Point;
            const currentPoint: Point = allPointsFeatureCollection.features[i].geometry as Point;
            const geojsonFeature: Feature = {
                type: 'Feature',
                properties: {
                    [propertyName]: allPointsFeatureCollection.features[i].properties![propertyName],
                    flightNumber: allPointsFeatureCollection.features[i].properties!['flightNumber'],
                    startDate: allPointsFeatureCollection.features[i].properties!['startDate'],
                },
                geometry: {
                    type: 'LineString',
                    coordinates: [prevPoint.coordinates, currentPoint.coordinates],
                } as Geometry,
            };
            features.push(geojsonFeature);
        }

        this._map.addLayer({
            id: layerName,
            type: 'line',
            source: {
                type: 'geojson',
                lineMetrics: true,
                data: featureCollection(features),
                tolerance: MAPBOX_DEFAULT_TOLERANCE / (allPointsFeatureCollection.features.length / this._flightPointsNumber),
            },
            paint: {
                'line-color': {
                    property: propertyName,
                    stops: colorsArray,
                },
                'line-width': +this._map.getPaintProperty(layerName.split('--')[0], 'line-width')! * 1.5,
            },
            layout: {
                'line-cap': 'round',
            },
        });

        this._map.on('mouseenter', layerName, (e) => {
            const featuresUnderMouse = this._map.queryRenderedFeatures([
                [e.point.x - 1, e.point.y - 1],
                [e.point.x + 1, e.point.y + 1],
            ]);
            const selectedSegmentFeatureIndex = featuresUnderMouse.map((f) => f.layer!.id).indexOf('selectedSegment');

            if (this._weatherPopup) {
                this._weatherPopup.remove();
            }

            if (selectedSegmentFeatureIndex === -1 && e.features) {
                this._map.getCanvas().style.cursor = 'crosshair';
                if (e.features[0].properties) {
                    const value = e.features[0].properties[propertyName];
                    let prettifyValue = parseFloat(('' + value).split('e')[0]).toFixed(2);
                    prettifyValue += ('' + value).split('e')[1] ? 'e' + ('' + value).split('e')[1] : '';

                    const html = '<span>' + e.features[0].properties['flightNumber'] + ' - ' + e.features[0].properties['startDate'] + '</span>';

                    this._weatherPopup = new Popup({
                        closeButton: false,
                        anchor: 'right',
                        className: 'mapTooltip ' + layerName.split('--')[0],
                        offset: 15,
                    })
                        .setLngLat(e.lngLat)
                        .setHTML(html + '<span>' + propertyName + ': ' + prettifyValue + '</span>')
                        .addTo(this._map);
                }
            }
        });

        this._map.on('mouseleave', layerName, () => {
            this._map.getCanvas().style.cursor = 'inherit';
            if (this._weatherPopup) {
                this._weatherPopup.remove();
            }
        });
        this.toggleLayerVisibility(layerName, false);
    }

    private _addTrajectoryExtremitiesLayer(geojsonExtremities: Feature, flightInfo: { start: string; end: string }, layerName: string): void {
        const coordinates = (geojsonExtremities.geometry as MultiPoint).coordinates as [number, number][];

        // add a marker at start point
        const startInfo = document.createElement('div');
        startInfo.className = 'start-info map-marker-info ' + layerName;
        const startMarker = document.createElement('div');
        startMarker.className = 'marker';
        const startText = document.createElement('div');
        startText.className = 'start-text text';
        const startTextLabel = document.createElement('span');
        startTextLabel.innerText = 'FROM';
        startTextLabel.className = 'label';
        startText.append(startTextLabel);
        const startAirportCode = document.createElement('span');
        startAirportCode.innerText = flightInfo.start;
        startAirportCode.className = 'airport-code';
        startInfo.append(startMarker);
        startText.append(startAirportCode);
        startInfo.append(startText);
        new Marker(startInfo).setLngLat(coordinates[0]).addTo(this._map);

        // add a marker at end point
        const endInfo = document.createElement('div');
        endInfo.className = 'end-info map-marker-info ' + layerName;
        const endMarker = document.createElement('div');
        endMarker.className = 'marker';
        const endContent = document.createElement('div');
        endContent.className = 'end-text text';
        const endText = document.createElement('div');
        endText.className = 'vertical-align';
        const endTextLabel = document.createElement('span');
        endTextLabel.innerText = 'TO';
        endTextLabel.className = 'label';
        endText.append(endTextLabel);

        const endAirportCode = document.createElement('span');
        endAirportCode.innerText = flightInfo.end;
        endAirportCode.className = 'airport-code';
        endText.append(endAirportCode);
        endContent.append(endText);
        endInfo.append(endContent);
        endInfo.append(endMarker);
        new Marker(endInfo).setLngLat(coordinates[1]).addTo(this._map);
    }

    private _addTrajectoryPointsLayer(layerName: string): void {
        const allPointsFeatureCollection = this.getSource(`${layerName}--data-at-points`) as FeatureCollection;
        this._map.addLayer({
            id: layerName + '--all-points',
            type: 'circle',
            source: {
                type: 'geojson',
                lineMetrics: true,
                data: allPointsFeatureCollection,
            },
            paint: {
                'circle-opacity': 0,
            },
        });
    }

    private _addPointLayer(type: string, feature: Feature): void {
        if (this._map.getLayer('flight')) {
            this._map.setPaintProperty('flight', 'line-opacity', 0.2);
            this._map.setLayoutProperty('flight', 'line-cap', 'butt');
        }
        this._map.addLayer({
            id: type + 'Point',
            type: 'circle',
            source: {
                type: 'geojson',
                data: feature,
            },
            paint: {
                'circle-opacity': 1,
                'circle-color': this._sassHelperService.getProperty('ffp-trajectory-flight'),
                'circle-radius': 6,
            },
        });
    }

    private _addSelectedSegmentLayer(feature: Feature): void {
        if (this._map.getLayer('flight')) {
            this._map.setPaintProperty('flight', 'line-opacity', 0.2);
            this._map.setLayoutProperty('flight', 'line-cap', 'butt');
        }

        this._map.addLayer({
            id: 'selectedSegment',
            type: 'line',
            source: {
                type: 'geojson',
                data: feature,
            },
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': this._sassHelperService.getProperty('ffp-trajectory-flight'),
                'line-width': HIGHLIGHTED_FLIGHT_WIDTH,
                'line-opacity': FLIGHT_OPACITY,
            },
        });
    }

    private _getFeatureFromCoordinates(coordinates: Position[], type: string, layerName: string, properties?: GeoJsonProperties): Feature {
        if (properties) {
            properties['type'] = layerName;
        } else {
            properties = { type: layerName };
        }
        const geojsonData: Feature = {
            type: 'Feature',
            properties,
            geometry: {
                type,
                coordinates,
            } as Geometry,
        };

        this.sources.push(geojsonData);

        return geojsonData;
    }

    private _getFeatureCollectionFromCoordinates(
        coordinates: Position[],
        type: string,
        layerName: string,
        properties: GeoJsonProperties,
    ): FeatureCollectionWithProp {
        const geojsonData: FeatureCollectionWithProp = {
            type: 'FeatureCollection',
            properties: {
                type: layerName,
            },
            features: [] as Feature[],
        };

        coordinates.forEach((coord, index) => {
            const props = properties ? Object.keys(properties) : [];
            const geojsonFeature: Feature = {
                type: 'Feature',
                properties: {
                    type: layerName + '-feature',
                },
                geometry: {
                    type,
                    coordinates: coord,
                } as Geometry,
            };
            props.forEach((prop) => {
                if (geojsonFeature.properties && properties) {
                    geojsonFeature.properties[prop] = typeof properties[prop] === 'object' ? properties[prop][index] : properties[prop];
                }
            });

            geojsonData.features?.push(geojsonFeature);
        });

        this.sources.push(geojsonData);

        return geojsonData;
    }

    private _mousemoveListener = (e: MapMouseEvent) => {
        const features = this._map.queryRenderedFeatures([
            [e.point.x - 1, e.point.y - 1],
            [e.point.x + 1, e.point.y + 1],
        ]);
        if (this.selectionEnabled) {
            const flightFeatureIndex = features.map((f) => f.layer!.id).indexOf('flight--all-points');
            const selectedSegmentFeatureIndex = features.map((f) => f.layer!.id).indexOf('selectedSegment');

            if (selectedSegmentFeatureIndex !== -1) {
                if (typeof this._map.getLayer('hoveredPoint') !== 'undefined') {
                    this._map.removeLayer('hoveredPoint');
                    this._map.removeSource('hoveredPoint');
                }
                this._map.getCanvas().style.cursor = 'pointer';
                return;
            }

            if (!features.length || flightFeatureIndex === -1) {
                if (typeof this._map.getLayer('hoveredPoint') !== 'undefined') {
                    this._map.removeLayer('hoveredPoint');
                    this._map.removeSource('hoveredPoint');
                }
                if (typeof this._map.getLayer('selectedSegment') === 'undefined' && !this._firstSelectedPoint && this._map.getLayer('flight')) {
                    this._map.setPaintProperty('flight', 'line-opacity', FLIGHT_OPACITY);
                    this._map.setLayoutProperty('flight', 'line-cap', 'round');
                }
                this._map.getCanvas().style.cursor = '';
                return;
            }

            if (typeof this._map.getLayer('hoveredPoint') !== 'undefined') {
                if (JSON.stringify(this._map.querySourceFeatures('hoveredPoint')) === JSON.stringify(features[flightFeatureIndex])) {
                    return;
                } else {
                    this._map.removeLayer('hoveredPoint');
                    this._map.removeSource('hoveredPoint');
                }
            }
            this._map.getCanvas().style.cursor = 'crosshair';
            this._addPointLayer('hovered', features[flightFeatureIndex]);
        } else if (this.highlightable) {
            const flightFeatures = features.filter((f) => f.layer!.id.includes('--all-points'));
            const allLayersId = this._map
                .getStyle()!
                .layers.filter((l) => l.id.includes('--all-points'))
                .map((l) => l.id.split('--all-points')[0]);
            const allLayers = this._map.getStyle()!.layers.filter((l) => allLayersId.includes(l.id));
            const currentlyHighlightedLayer =
                allLayers.filter((l) => this._map.getPaintProperty(l.id, 'line-width') === HIGHLIGHTED_FLIGHT_WIDTH)[0] || null;
            if (flightFeatures.length) {
                this._map.getCanvas().style.cursor = 'pointer';
                const highlightedFlightLayerId = flightFeatures[0].layer!.id.split('--all-points')[0];
                this.highlightFlight(highlightedFlightLayerId, currentlyHighlightedLayer);
                this.highlightedFlight.emit(highlightedFlightLayerId);
            } else {
                this._map.getCanvas().style.cursor = 'inherit';
                if (currentlyHighlightedLayer) {
                    this._map.setPaintProperty(currentlyHighlightedLayer.id, 'line-width', FLIGHT_WIDTH);
                }
                allLayersId.forEach((el) => this._map.setPaintProperty(el, 'line-opacity', FLIGHT_OPACITY));
                this.highlightedFlight.emit();
            }
        }
    };

    private _mouseclickListener = (e: MapMouseEvent | null) => {
        if (typeof this._map.getLayer('selectedSegment') !== 'undefined') {
            this._map.removeLayer('selectedSegment');
            this._map.removeSource('selectedSegment');
        }
        if (typeof this._map.getLayer('hoveredPoint') !== 'undefined') {
            this._map.removeLayer('hoveredPoint');
            this._map.removeSource('hoveredPoint');
        }
        let features;
        let flightFeatureIndex;
        if (e) {
            features = this._map.queryRenderedFeatures([
                [e.point.x - 1, e.point.y - 1],
                [e.point.x + 1, e.point.y + 1],
            ]);
            flightFeatureIndex = features.map((f) => f.layer!.id).indexOf('flight--all-points');
        }

        if (features === undefined || flightFeatureIndex === undefined || !features.length || flightFeatureIndex === -1) {
            this._resetSelectedFeature();
            return;
        }

        if (this._firstSelectedPoint) {
            if (typeof this._map.getLayer('selectedPoint') !== 'undefined') {
                this._map.removeLayer('selectedPoint');
                this._map.removeSource('selectedPoint');
            }

            const allPointsFeatureCollection: FeatureCollection<Point> = this.getSource(
                'flight--data-at-points',
            ) as unknown as FeatureCollection<Point>;
            const allPointsCoords = coordAll(allPointsFeatureCollection as AllGeoJSON).map((c) => [c[0], c[1]]);
            const firstPointCoord = nearest(this._firstSelectedPoint, allPointsFeatureCollection).geometry.coordinates.slice(0, 2);
            const lastPointCoord = nearest(features[flightFeatureIndex] as Feature<Point>, allPointsFeatureCollection).geometry.coordinates.slice(
                0,
                2,
            );

            const firstIndex = allPointsCoords.findIndex((f) => f[0] === firstPointCoord[0] && f[1] === firstPointCoord[1]);
            const lastIndex = allPointsCoords.findIndex((f) => f[0] === lastPointCoord[0] && f[1] === lastPointCoord[1]);
            const firstPointIndex = Math.min(firstIndex, lastIndex);
            const lastPointIndex = Math.max(firstIndex, lastIndex);
            if (firstPointIndex === lastPointIndex) {
                this._resetSelectedFeature();
                return;
            }
            this._selectedFeatureFirstPointIndex = firstPointIndex;
            this._selectedFeatureLastPointIndex = lastPointIndex;
            const featureCollectionElements = allPointsFeatureCollection.features.slice(firstPointIndex, lastPointIndex + 1);

            const collection = featureCollection(featureCollectionElements);
            const featureCollectionPropertiesMap = new Map();
            propEach(collection, (prop) => {
                if (prop) {
                    for (const propName of Object.keys(prop)) {
                        if (propName !== 'type') {
                            if (!featureCollectionPropertiesMap.get(propName)) {
                                featureCollectionPropertiesMap.set(propName, []);
                            }
                            featureCollectionPropertiesMap.get(propName).push(prop[propName]);
                        }
                    }
                }
            });
            const featureCollectionProperties: GeoJsonProperties = {};
            featureCollectionPropertiesMap.forEach((value, key) => {
                featureCollectionProperties[key] = value;
            });
            const selectedSegmentFeature = lineString(coordAll(collection as AllGeoJSON), featureCollectionProperties);
            this._selectedFeature = selectedSegmentFeature as Feature;
            this.selectedSegment.emit({
                feature: selectedSegmentFeature,
                firstIndex: this._selectedFeatureFirstPointIndex,
                lastIndex: this._selectedFeatureLastPointIndex,
            });
            this._addSelectedSegmentLayer(selectedSegmentFeature);
            this._firstSelectedPoint = null;
        } else {
            this._firstSelectedPoint = features[flightFeatureIndex] as Feature<Point>;
            this._addPointLayer('selected', features[flightFeatureIndex]);
        }
    };
}
