import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Trajectory } from '../../../../../../../shared/models/trajectory.interface';
import { ClimateAnalyticsService } from '../../../../../services/climate-analytics/climate-analytics.service';
import { CoreService } from '../../../../../../../core/services/core/core.service';
import { ComparisonSearchCard } from '../../../../../models/card.interface';
import { Subject, TimeoutError, switchMap } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormatDate } from '../../../../../../../shared/pipes/format-data.pipe';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
    selector: 'ffp-comparison-modal',
    templateUrl: './comparison-modal.component.html',
    styleUrls: ['./comparison-modal.component.scss'],
    providers: [FormatDate],
    animations: [
        trigger('comparisonCardAnimation', [
            transition(':enter', [style({ opacity: 0, scale: 0.9 }), animate(200)]),
            transition(':leave', animate(400, style({ opacity: 0, scale: 0.9 }))),
        ]),
    ],
})
export class ComparisonModalComponent {
    public initialFlightsLoaded: ComparisonSearchCard[] = [];
    public flights: ComparisonSearchCard[] = [];

    public bestFlight: ComparisonSearchCard | undefined;

    public flightsPage = 1;
    public flightsPageSize = 30;
    public moreFlightsLoading = false;

    public flightsLoading = true;
    public loadingFlightCards: number[];

    public selectedSorting:
        | {
              name: string;
              displayedName: string;
              icon: string;
          }
        | undefined;
    public selectedDateRange: string | undefined;
    public sortedBy: string | undefined;
    public sortOptions: {
        name: string;
        displayedName: string;
        icon: string;
    }[] = [
        {
            name: 'climateImpact',
            displayedName: 'Climate impact',
            icon: '/assets/icons/climate-impact.svg',
        },
        {
            name: 'startDate',
            displayedName: 'Date',
            icon: 'date_range',
        },
        {
            name: 'flightNumber',
            displayedName: 'Flight number',
            icon: 'airplanemode_active',
        },
        {
            name: 'contrails',
            displayedName: 'Contrails',
            icon: 'air',
        },
    ];

    public selectedSeason:
        | {
              name: string;
              icon: string;
              startMonth: string;
              endMonth: string;
          }
        | undefined;
    public dateRangeSelection: string[] = ['Current month', 'Last month', '3 last months', 'Custom dates'];

    public seasons: {
        name: string;
        icon: string;
        startMonth: string;
        endMonth: string;
    }[] = [
        {
            name: 'spring',
            icon: 'local_florist',
            startMonth: '3',
            endMonth: '6',
        },
        {
            name: 'summer',
            icon: 'beach_access',
            startMonth: '6',
            endMonth: '9',
        },
        {
            name: 'autumn',
            icon: 'energy_savings_leaf',
            startMonth: '9',
            endMonth: '12',
        },
        {
            name: 'winter',
            icon: 'ac_unit',
            startMonth: '12',
            endMonth: '3',
        },
    ];

    public selectedCards: ComparisonSearchCard[] = [];

    public startRangeDateFilter: Date | null = null;
    public endRangeDateFilter: Date | null = null;
    public currentDate: Date = new Date();
    public enableCustomDates = false;

    @ViewChild('list') list!: ElementRef;
    public pendingHttpRequest: Subject<void> = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<ComparisonModalComponent>,
        private _climateAnalyticsService: ClimateAnalyticsService,
        public coreService: CoreService,
        private _formatDate: FormatDate,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            bestFlight: ComparisonSearchCard;
            referenceFlight: Trajectory;
            flightsToCompare: ComparisonSearchCard[];
        },
    ) {
        const numberOfCardPerLign = 6;

        this.flightsPageSize = numberOfCardPerLign * 5;
        this.flightsLoading = true;
        this.loadingFlightCards = new Array(numberOfCardPerLign * 2);

        if (data.flightsToCompare) {
            this.selectedCards = data.flightsToCompare;
        }

        if (data.bestFlight) {
            this.bestFlight = data.bestFlight;
        }
        this._handleRequest();
        this.pendingHttpRequest.next();
    }

    public loadMoreFlights() {
        if (this.moreFlightsLoading || this.flightsPage === 1) {
            return;
        } else {
            this.moreFlightsLoading = true;

            this._climateAnalyticsService
                .getFlightsToCompare(
                    this.data.referenceFlight.startAirportCode,
                    this.data.referenceFlight.endAirportCode,
                    this.flightsPageSize,
                    this.flightsPage,
                    this.sortedBy!,
                    this.selectedSeason?.startMonth,
                    this.selectedSeason?.endMonth,
                )
                .subscribe(
                    (result) => {
                        if (result.flights.length === this.flightsPageSize) {
                            this.flightsPage += 1;
                        } else {
                            this.flightsPage = 1;
                        }
                        // remove reference flight
                        result.flights = result.flights?.filter(
                            (el) => el.startDate !== this.data.referenceFlight.startDate || el.startTime !== this.data.referenceFlight.startTime,
                        );

                        // remove best flight
                        result.flights = result.flights?.filter(
                            (el) => el.startDate !== this.bestFlight?.startDate || el.startTime !== this.bestFlight.startTime,
                        );

                        this.initialFlightsLoaded.push(...result.flights);
                        this.flights.push(...result.flights);

                        // filter selected flights
                        this.selectedCards.forEach((selectedCard) => {
                            this.flights = this.flights.filter(
                                (el) => el.startDate !== selectedCard.startDate || el.startTime !== selectedCard.startTime,
                            );
                        });

                        this.moreFlightsLoading = false;
                    },
                    (error) => {
                        console.error(error);
                        let errorMsg = 'An error occurred on server side.';
                        if (error instanceof TimeoutError) {
                            errorMsg = 'Server did not respond within a reasonable delay.';
                        } else if (error.statusText === 'BAD REQUEST') {
                            errorMsg = 'Bad request : no flights were found with the given parameters.';
                        }
                        this.coreService.pushNotification({
                            title: 'Past flight analysis',
                            description: errorMsg,
                            icon: 'error',
                            lifeDuration: 'infinite',
                            info: '',
                            index: 0,
                            type: 'ERROR',
                        });
                    },
                );
        }
    }

    sortFlights(): void {
        if (this.selectedSorting?.name === 'climateImpact') {
            this.sortedBy = 'total' + this.coreService.emissionMeasure!;
        } else if (this.selectedSorting?.name === 'contrails') {
            this.sortedBy = 'total' + this.coreService.emissionMeasure! + 'Contrails';
        } else {
            this.sortedBy = this.selectedSorting?.name;
        }

        this.pendingHttpRequest.next();
    }

    computeClimateImpactComparison(flight: ComparisonSearchCard) {
        const emissionMeasure = this.coreService.emissionMeasure === 'Ccf' ? 'CcfAbs' : 'Gwp100';
        return +(
            ((flight[this.getKeyForTotalEmissionMeasure(this.coreService.emissionMeasure) as 'totalGwp100' | 'totalCcf'] -
                this.data.referenceFlight[this.getKeyForTotalEmissionMeasure(emissionMeasure)]) /
                this.data.referenceFlight[this.getKeyForTotalEmissionMeasure(emissionMeasure)]) *
            100
        ).toFixed();
    }

    getKeyForTotalEmissionMeasure(emissionMeasure: 'Gwp100' | 'Ccf' | 'CcfAbs'): 'totalGwp100' | 'totalCcf' | 'totalCcfAbs' {
        return ('total' + emissionMeasure) as 'totalGwp100' | 'totalCcf';
    }

    selectCard(flight: ComparisonSearchCard) {
        if (this.selectedCards.length < 2) {
            this.flights = this.flights.filter((el) => el.startDate !== flight.startDate || el.startTime !== flight.startTime);
            this.selectedCards.push(flight);
        }
    }

    selectBest() {
        this.flights = this.flights.filter((el) => el.startDate !== this.bestFlight!.startDate || el.startTime !== this.bestFlight!.startTime);
        this.selectedCards.push(this.bestFlight!);
    }

    deselect(card: ComparisonSearchCard): void {
        this.selectedCards = this.selectedCards.filter((el) => el.startDate !== card.startDate || el.startTime !== card.startTime);

        if (this.isPresentInInitialFlightLoaded(card)) {
            if (this.isBestFlight(card)) {
                this.flights.unshift(card);
            } else {
                const temp = this.flights;
                if (!this.isBestFlightSelected) {
                    // remove first element because we don't consider best flight while sorting
                    temp.shift();
                }
                temp.push(card);
                let sortBy = '';
                if (this.selectedSorting) {
                    sortBy = this.selectedSorting.name;
                    if (this.selectedSorting.name === 'climateImpact') {
                        sortBy = 'total' + this.coreService.emissionMeasure;
                    } else if (this.selectedSorting.name === 'contrails') {
                        sortBy = 'total' + this.coreService.emissionMeasure + 'Contrails';
                    }
                } else {
                    sortBy = 'startDate';
                }

                this.flights = temp.sort((a, b) => {
                    return typeof this.flights[0][sortBy as keyof ComparisonSearchCard] === 'string'
                        ? (a[sortBy as keyof ComparisonSearchCard] as string).localeCompare(b[sortBy as keyof ComparisonSearchCard] as string)
                        : (a[sortBy as keyof ComparisonSearchCard] as number) - (b[sortBy as keyof ComparisonSearchCard] as number);
                });
                if (!this.isBestFlightSelected) {
                    // add best flight that we removed while we were sorting
                    this.flights.unshift(this.bestFlight!);
                }
            }
        }
    }

    isBestFlight(flight: ComparisonSearchCard | Trajectory): boolean {
        return flight.startDate === this.bestFlight?.startDate && flight.startTime === this.bestFlight?.startTime;
    }

    isPresentInInitialFlightLoaded(flight: ComparisonSearchCard): boolean {
        const findElement = this.initialFlightsLoaded.find((el) => flight.startDate === el.startDate && flight.startTime === el.startTime);
        return findElement !== undefined;
    }

    get isBestFlightSelected(): boolean {
        return this.selectedCards.filter((el) => this.isBestFlight(el)).length > 0;
    }

    launchComparison() {
        this.dialogRef.close({
            flights: this.selectedCards,
        });
    }

    updateDateRange() {
        const currentDate = new Date();
        switch (this.selectedDateRange) {
            case 'Current month':
                this.startRangeDateFilter = new Date(currentDate.setDate(1));
                this.endRangeDateFilter = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                break;
            case 'Last month':
                this.startRangeDateFilter = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                this.endRangeDateFilter = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case '3 last months':
                this.startRangeDateFilter = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
                this.endRangeDateFilter = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                break;
            case 'Custom dates':
                this.enableCustomDates = true;
                this.startRangeDateFilter = null;
                this.endRangeDateFilter = null;
                break;
        }
        if (this.selectedDateRange !== 'Custom dates') {
            this.enableCustomDates = false;
        }
        this.pendingHttpRequest.next();
    }

    updateStartDateFilter(newDate: Date) {
        this.startRangeDateFilter = newDate;
    }

    updateEndDateFilter(newDate: Date) {
        this.endRangeDateFilter = newDate;
    }

    createNewDate(daysToAdd: number, date?: Date) {
        if (!date) {
            date = new Date();
        }
        const newDate = new Date(date);
        newDate.setDate(date.getDate() + daysToAdd);
        return newDate;
    }

    minDate(time: Date, time2: Date) {
        return time2 && time?.getTime() > time2?.getTime() ? time2 : time;
    }

    openDatepicker(picker: MatDateRangePicker<Date>, start: boolean) {
        if (this.enableCustomDates) {
            if (start) {
                this.startRangeDateFilter = null;
            } else {
                this.endRangeDateFilter = null;
            }
            picker.open();
        }
    }

    private _handleRequest(): void {
        this.pendingHttpRequest = new Subject<void>();
        this.pendingHttpRequest
            .pipe(
                switchMap(() => {
                    this.flightsPage = 1;
                    this.flights = [];
                    this.initialFlightsLoaded = [];
                    this.flightsLoading = true;

                    return this._climateAnalyticsService.getFlightsToCompare(
                        this.data.referenceFlight.startAirportCode,
                        this.data.referenceFlight.endAirportCode,
                        this.flightsPageSize,
                        this.flightsPage,
                        this.sortedBy!,
                        this.selectedSeason?.startMonth,
                        this.selectedSeason?.endMonth,
                        this._formatDate.transform(this.startRangeDateFilter, 'yyyy-mm-dd'),
                        this._formatDate.transform(this.endRangeDateFilter, 'yyyy-mm-dd'),
                    );
                }),
            )
            .subscribe({
                next: (results) => {
                    this.flights.push(...results.flights);
                    this.initialFlightsLoaded.push(...results.flights);

                    // remove reference flight
                    this.flights = this.flights.filter(
                        (el) => el.startDate !== this.data.referenceFlight.startDate || el.startTime !== this.data.referenceFlight.startTime,
                    );

                    // remove best flight
                    this.flights = this.flights?.filter(
                        (el) => el.startDate !== this.bestFlight!.startDate || el.startTime !== this.bestFlight!.startTime,
                    );

                    if (
                        this.data.referenceFlight.startDate !== this.bestFlight!.startDate ||
                        this.data.referenceFlight.startTime !== this.bestFlight!.startTime
                    ) {
                        this.flights.unshift(this.bestFlight!);
                    }

                    // filter selected flights
                    this.selectedCards.forEach((selectedCard) => {
                        this.flights = this.flights.filter(
                            (el) => el.startDate !== selectedCard.startDate || el.startTime !== selectedCard.startTime,
                        );
                    });

                    if (results.flights.length === this.flightsPageSize) {
                        this.flightsPage += 1;
                    } else {
                        this.flightsPage = 1;
                    }
                    this.flightsLoading = false;
                },
                error: (error) => {
                    console.error(error);
                    let errorMsg = 'An error occurred on server side.';
                    if (error instanceof TimeoutError) {
                        errorMsg = 'Server did not respond within a reasonable delay.';
                    } else if (error.statusText === 'BAD REQUEST') {
                        errorMsg = 'Bad request : no flights were found with the given parameters.';
                    }
                    this.coreService.pushNotification({
                        title: 'Past flight analysis',
                        description: errorMsg,
                        icon: 'error',
                        lifeDuration: 'infinite',
                        info: '',
                        index: 0,
                        type: 'ERROR',
                    });
                },
            });
    }
}
