<h1 mat-dialog-title class="title">
    {{ title }}
    <mat-icon mat-dialog-close>close</mat-icon>
</h1>
<mat-dialog-content class="mat-typography">
    <mat-tab-group
        mat-align-tabs="start"
        disableRipple
        [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)"
        [ngClass]="{ 'hide-tabs': tabs.length === 1 }"
    >
        @for (tab of tabs; track tab) {
            <mat-tab label="{{ tab.title }}">
                @if (tab.illustrations.length > 0) {
                    <div class="illustrations-container">
                        @for (img of tab.illustrations; track img) {
                            <div class="illustration">
                                <img src="/assets/methodDescriptions/illustrations/{{ img }}" [alt]="img" />
                                <span></span>
                            </div>
                        }
                    </div>
                }
                <div class="tab-container">
                    <div class="abstract">
                        {{ tab.abstract }}
                    </div>
                    @if (tab.references.length > 0) {
                        <div class="references">
                            <span class="section-title"> References </span>
                            @for (reference of tab.references; track reference) {
                                <div>
                                    @if (reference.label && !reference.link) {
                                        <span>{{ reference.label }}</span>
                                    }
                                    @if (reference.label && reference.link) {
                                        <span>
                                            <a [href]="reference.link">{{ reference.label }}</a>
                                        </span>
                                    }
                                    @if (!reference.label && reference.link) {
                                        <span>
                                            <a [href]="reference.link">{{ reference.link }}</a>
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    }
                    @if (tab.resources.length > 0) {
                        <div class="resources">
                            <span class="section-title"> Resources </span>
                            @for (resource of tab.resources; track resource) {
                                <div>
                                    @if (resource.label && !resource.link) {
                                        <span>{{ resource.label }}</span>
                                    }
                                    @if (resource.label && resource.link) {
                                        <span>
                                            <a [href]="resource.link">{{ resource.label }}</a>
                                        </span>
                                    }
                                    @if (!resource.label && resource.link) {
                                        <span>
                                            <a [href]="resource.link">{{ resource.link }}</a>
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </mat-tab>
        }
    </mat-tab-group>
</mat-dialog-content>
