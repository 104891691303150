<div [class]="'no-data ' + service">
    <div class="text-container">
        <div class="title">
            <qtm-typography fontSize="4" fontWeight="bold">
                {{ this.title }}
            </qtm-typography>
        </div>
        <div class="description">
            <qtm-typography fontSize="6" fontWeight="light">
                <div [innerHTML]="this.description"></div>
            </qtm-typography>
        </div>

        @if (button && errorCode !== 400) {
            <ffp-button type="primary" size="medium" gradient (ffpClick)="triggerCallback()">
                <mat-icon [inline]="true">{{ buttonIcon }}</mat-icon>
                <qtm-typography fontSize="7" fontWeight="bold">
                    {{ buttonText }}
                </qtm-typography>
            </ffp-button>
        }
    </div>
    <div class="img"></div>
</div>
