import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { axisBottom, axisLeft, axisRight, drag, line, scaleLinear, select, sum, zoom, zoomIdentity } from 'd3';
import maxBy from 'lodash.maxby';
import minBy from 'lodash.minby';
import { SassHelperService } from '../../../core/services';
import { extent, height, margin, svgHeight, svgWidth, width } from './config/vertical-display-config';
import { VerticalDisplayService } from '../../services/verticalDisplay/vertical-display.service';
import { FlightPhase, FlightPhaseSelect } from '../../models/flight-phase.interface';
import { CoreService } from '../../../core/services/core/core.service';
import { ConvertMeterToFlightLevel, ConvertMeterToNauticalMiles } from '../../pipes/format-data.pipe';
import { FilteredData, PointEmissionData } from './models/vertical-view.interface';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import { ClimateAnalyticsService } from '../../../features/climate-analytics/services/climate-analytics/climate-analytics.service';

@Component({
    selector: 'ffp-vertical-view',
    templateUrl: './vertical-view.component.html',
    styleUrls: ['./vertical-view.component.scss'],
    providers: [ConvertMeterToFlightLevel, ConvertMeterToNauticalMiles],
})
export class VerticalViewComponent implements AfterViewInit {
    _flightFeatureCollection!: {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    };

    get flightFeatureCollection(): {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    } {
        return this._flightFeatureCollection;
    }

    @Input() set flightFeatureCollection(value: {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    }) {
        if (JSON.stringify(this._flightFeatureCollection) !== JSON.stringify(value)) {
            if (!value.visible && this.trajectoryCurrentlyDisplayed?.id === value.id && this.similarFlightFeatureCollection.length > 0) {
                this.trajectoryCurrentlyDisplayed = this.similarFlightFeatureCollection.find(
                    (el) => this._climateAnalyticsService.getVisibleTrajectories()[0] === el.id,
                );
                this.previousTrajectoryDisplayed = this.trajectoryCurrentlyDisplayed;
            }

            if (
                value.id === this.trajectoryCurrentlyDisplayed?.id &&
                value.source &&
                JSON.stringify(this.trajectoryCurrentlyDisplayed?.source) !== JSON.stringify(value.source)
            ) {
                this.trajectoryCurrentlyDisplayed = value;
            }
            this._flightFeatureCollection = value;

            if (this.trajectoryCurrentlyDisplayed?.source) {
                this.buildVerticalView();
            }
        }
    }

    _similarFlightFeatureCollection: {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    }[] = [];
    get similarFlightFeatureCollection(): {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    }[] {
        return this._similarFlightFeatureCollection;
    }

    @Input() set similarFlightFeatureCollection(
        value: {
            flightNumber: string;
            startDate: string;
            startTime: string;
            source: Feature | FeatureCollection;
            visible: boolean;
            id: string;
        }[],
    ) {
        if (JSON.stringify(this._similarFlightFeatureCollection) !== JSON.stringify(value)) {
            this._similarFlightFeatureCollection = value;
            value.forEach((similarFlight) => {
                if (
                    !similarFlight.visible &&
                    this.trajectoryCurrentlyDisplayed?.id === similarFlight.id &&
                    this.similarFlightFeatureCollection.length > 0
                ) {
                    const trajToDisplay = this._climateAnalyticsService.getVisibleTrajectories()[0];
                    if (trajToDisplay === 'flight') {
                        this.trajectoryCurrentlyDisplayed = this.flightFeatureCollection;
                    } else {
                        this.trajectoryCurrentlyDisplayed = this.similarFlightFeatureCollection.find((el) => trajToDisplay === el.id);
                    }
                    this.previousTrajectoryDisplayed = this.trajectoryCurrentlyDisplayed;
                }
            });
            if (this._similarFlightFeatureCollection.length > 0 && this._similarFlightFeatureCollection[0].source) {
                this.buildVerticalView();
            }
            if (
                this.trajectoryCurrentlyDisplayed?.id &&
                this.trajectoryCurrentlyDisplayed?.id !== 'flight' &&
                !this._similarFlightFeatureCollection.map((el) => el.id).includes(this.trajectoryCurrentlyDisplayed?.id)
            ) {
                if (
                    this.previousTrajectoryDisplayed &&
                    (this._similarFlightFeatureCollection.map((el) => el.id).includes(this.previousTrajectoryDisplayed.id) ||
                        this.previousTrajectoryDisplayed.id === 'flight')
                ) {
                    this.trajectoryCurrentlyDisplayed = this.previousTrajectoryDisplayed;
                    this.buildVerticalView();
                } else {
                    this.trajectoryCurrentlyDisplayed = this.flightFeatureCollection;
                    if (
                        this.previousTrajectoryDisplayed &&
                        !this._similarFlightFeatureCollection.map((el) => el.id).includes(this.previousTrajectoryDisplayed.id) &&
                        this.previousTrajectoryDisplayed.id !== 'flight'
                    ) {
                        this.previousTrajectoryDisplayed = undefined;
                    }
                    this.buildVerticalView();
                }
            }
        }
    }

    @Input() set highlightedFlight(value: string | null) {
        if (value !== null && this.previousTrajectoryDisplayed === undefined && this.verticalViewEnabled) {
            this.previousTrajectoryDisplayed = this.trajectoryCurrentlyDisplayed;
        }
        if (this.verticalViewEnabled && this.trajectoryCurrentlyDisplayed?.id !== value) {
            if (value === 'flight') {
                this.trajectoryCurrentlyDisplayed = this.flightFeatureCollection;
                this.buildVerticalView();
            } else if (value !== null && value !== undefined) {
                this.trajectoryCurrentlyDisplayed = this.similarFlightFeatureCollection.find((el) => el.id === value);
                this.buildVerticalView();
            } else if (this.previousTrajectoryDisplayed && this.trajectoryCurrentlyDisplayed?.id !== this.previousTrajectoryDisplayed.id) {
                this.trajectoryCurrentlyDisplayed = this.previousTrajectoryDisplayed;
                this.previousTrajectoryDisplayed = undefined;
                this.buildVerticalView();
            }
        }
    }

    @Input()
    legend!: [number, string][];

    @Input()
    legendName!: string; // Used for weather layers

    @Output()
    emissionMeasureChange = new EventEmitter<string>();

    @Output()
    expanded: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('mainContainer') mainContainer!: ElementRef;

    verticalViewEnabled = false;

    flightPhases: FlightPhaseSelect[] = [
        {
            label: 'Full Flight',
            value: FlightPhase.FULL,
        },
        {
            label: ' Climb',
            value: FlightPhase.CL,
        },
        {
            label: 'Cruise',
            value: FlightPhase.CR,
        },
        {
            label: 'Descent',
            value: FlightPhase.DE,
        },
    ];
    selectedFlightPhase: FlightPhaseSelect = this.flightPhases[0];
    emissionDisplayed = {
        h2o: true,
        contrails: true,
        nox: true,
        co2: true,
    };
    emissionsDetails: {
        co2: number;
        nox: number;
        contrails: number;
        h2o: number;
    } = {
        co2: 0,
        nox: 0,
        contrails: 0,
        h2o: 0,
    };

    trajectoryCurrentlyDisplayed:
        | {
              flightNumber: string;
              startDate: string;
              startTime: string;
              source: Feature | FeatureCollection;
              visible: boolean;
              id: string;
          }
        | undefined;
    previousTrajectoryDisplayed:
        | {
              flightNumber: string;
              startDate: string;
              startTime: string;
              source: Feature | FeatureCollection;
              visible: boolean;
              id: string;
          }
        | undefined;

    @ViewChild('ref')
    private ref!: ElementRef;

    /***************
     D3 variables
     **************/
    // @ts-expect-error: D3.js stuff
    private zoomCall;
    // @ts-expect-error: D3.js stuff
    private x;
    // @ts-expect-error: D3.js stuff
    private y1;
    // @ts-expect-error: D3.js stuff
    private y2;
    // @ts-expect-error: D3.js stuff
    private rescaledAxis;
    // @ts-expect-error: D3.js stuff
    private line;
    // @ts-expect-error: D3.js stuff
    private flatLine;
    // @ts-expect-error: D3.js stuff
    private xAxis;
    // @ts-expect-error: D3.js stuff
    private y1Axis;
    // @ts-expect-error: D3.js stuff
    private y1Min;
    // @ts-expect-error: D3.js stuff
    private y1Max;
    // @ts-expect-error: D3.js stuff
    private y2Axis;
    // @ts-expect-error: D3.js stuff
    private y2Min;
    // @ts-expect-error: D3.js stuff
    private y2Max;
    // @ts-expect-error: D3.js stuff
    private svgChart;
    // @ts-expect-error: D3.js stuff
    private tooltip;
    private zoomLevel = 1;
    // @ts-expect-error: D3.js stuff
    private clickedPoint;

    // Feature management and selection
    private flightDataMap: Map<string, [number, number, GeoJsonProperties, string | null][]> = new Map<
        string,
        [number, number, GeoJsonProperties, string | null][]
    >();

    constructor(
        private _sassHelperService: SassHelperService,
        private _verticalDisplayService: VerticalDisplayService,
        private cdr: ChangeDetectorRef,
        public coreService: CoreService,
        private _climateAnalyticsService: ClimateAnalyticsService,
        private _convertMeterToFlightLevel: ConvertMeterToFlightLevel,
        private _convertMeterToNauticalMiles: ConvertMeterToNauticalMiles,
    ) {}

    ngAfterViewInit(): void {
        this.trajectoryCurrentlyDisplayed = this.flightFeatureCollection;
        this.buildVerticalView();
    }

    toggleVerticalView(): void {
        this._verticalDisplayService.lastZoomEvent = null;
        this.verticalViewEnabled = !this.verticalViewEnabled;
        if (this.verticalViewEnabled) {
            if (this.trajectoryCurrentlyDisplayed?.source === undefined) {
                this.trajectoryCurrentlyDisplayed = this.flightFeatureCollection;
            }
            this.buildVerticalView();
        }
        this.expanded.emit(this.verticalViewEnabled);
    }

    buildVerticalView(onlyUpdate?: boolean): void {
        this.flightDataMap = new Map<string, [number, number, GeoJsonProperties, string | null][]>();

        if (this.trajectoryCurrentlyDisplayed) {
            this._addDataToDataset(this.trajectoryCurrentlyDisplayed.source as unknown as FeatureCollection, this.trajectoryCurrentlyDisplayed?.id);
        }

        // Compute emissions detailed values
        if (this.trajectoryCurrentlyDisplayed) {
            this.computeEmissionsDetails(this.trajectoryCurrentlyDisplayed.id);
        }
        this.cdr.detectChanges();

        select('#vertical-view').remove();
        select('#vertical-view-tooltip').remove();

        if (this.trajectoryCurrentlyDisplayed && this.flightDataMap.get(this.trajectoryCurrentlyDisplayed?.id)?.length !== 0) {
            this.initChartConfiguration();
            this.drawChart(onlyUpdate);
        } else {
            this.svgChart = select(this.ref.nativeElement).append('svg').attr('class', 'legend').attr('id', 'vertical-view');

            this.svgChart.attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`).attr('preserveAspectRatio', `xMinYMin meet`).attr('width', `${svgWidth}`);
        }

        // Reset last zoom if needed
        if (this._verticalDisplayService.lastZoomEvent) {
            this.zoomed(this._verticalDisplayService.lastZoomEvent);
        }
    }

    initChartConfiguration = (): void => {
        let minY1Value = 0;
        let maxY1Value = 0;
        let minY2Value = 0;
        let maxY2Value = 0;
        let minXValue = 0;
        let maxXValue = 0;
        for (const trajectoryName of this.flightDataMap.keys()) {
            if (this.flightDataMap.get(trajectoryName)!.length > 0) {
                // @ts-expect-error: Due to lodash.minBy signature
                minY1Value = Math.min(minY1Value, minBy(this.flightDataMap.get(trajectoryName), (value) => value[1])[1]);
                // @ts-expect-error: Due to lodash.maxBy signature
                maxY1Value = Math.max(maxY1Value, maxBy(this.flightDataMap.get(trajectoryName), (value) => value[1])[1] * 1.1);
                minY2Value = 0;
                let totalEmissionForTraj = 0;
                (Object.keys(this.emissionDisplayed) as ('h2o' | 'contrails' | 'nox' | 'co2')[]).forEach((p) => {
                    if (this.emissionDisplayed[p]) {
                        totalEmissionForTraj +=
                            // @ts-expect-error: Due to lodash.maxBy signature
                            maxBy(
                                this.flightDataMap.get(trajectoryName),
                                // @ts-expect-error: Due to lodash.maxBy signature
                                (value) => value[2][this.coreService.emissionMeasure.toLowerCase() + p[0].toUpperCase() + p.slice(1)],
                            )[2][this.coreService.emissionMeasure.toLowerCase() + p[0].toUpperCase() + p.slice(1)] * 1.1;
                    }
                });
                maxY2Value = Math.max(maxY2Value, totalEmissionForTraj);
                if (maxY2Value === minY2Value) {
                    maxY2Value = 1e-12;
                }
                minXValue = minXValue
                    ? // @ts-expect-error: Due to lodash.minBy signature
                      Math.min(minXValue, minBy(this.flightDataMap.get(trajectoryName), (value) => value[0])[0])
                    : // @ts-expect-error: Due to lodash.minBy signature
                      minBy(this.flightDataMap.get(trajectoryName), (value) => value[0])[0];
                // @ts-expect-error: Due to lodash.maxBy signature
                maxXValue = Math.max(maxXValue, maxBy(this.flightDataMap.get(trajectoryName), (value) => value[0])[0]);
            }
        }

        // Manage x and y
        this.x = scaleLinear().range([minXValue, maxXValue]).rangeRound([0, width]);
        this.y1 = scaleLinear().range([minY1Value, maxY1Value]).rangeRound([height, 0]);
        this.y2 = scaleLinear().range([minY2Value, maxY2Value]).rangeRound([height, 0]);
        this.x.domain([minXValue, maxXValue]);
        this.y1.domain([minY1Value, maxY1Value]);
        this.y2.domain([minY2Value, maxY2Value]);

        this.rescaledAxis = this.x.copy();

        this.xAxis = axisBottom(this.x)
            .ticks(8)
            .tickFormat((x) => x as string);
        this.y1Axis = axisLeft(this.y1).tickFormat((x) => x as string);
        if (this.coreService.emissionMeasure.toLowerCase() === 'ccf') {
            this.y2Axis = axisRight(this.y2).tickFormat((x) => (Math.round((100 * (x as number)) / 1e-12) / 100).toString());
        } else {
            this.y2Axis = axisRight(this.y2).tickFormat((x) => (Math.round((100 * (x as number)) / 1000000) / 100).toString());
        }

        // eslint-disable-next-line
        this.line = (data: any, xScale: any) =>
            line()
                .x((d) => {
                    return xScale(d[0]);
                })
                .y((d) => {
                    return this.y1(d[1]);
                })(data);

        // eslint-disable-next-line
        this.flatLine = (data: any, xScale: any) =>
            line()
                .x((d) => {
                    return xScale(d[0]);
                })
                .y(() => {
                    return this.y1(0);
                })(data);

        this.y1Min = this.y1Axis.scale().domain()[0];
        this.y1Max = this.y1Axis.scale().domain()[1];
        this.y2Min = this.y2Axis.scale().domain()[0];
        this.y2Max = this.y2Axis.scale().domain()[1];
    };

    drawChart = (onlyUpdate: boolean | undefined): void => {
        this.svgChart = select(this.ref.nativeElement).insert('svg', '.legend').attr('id', 'vertical-view');

        this.svgChart
            .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
            .attr('preserveAspectRatio', `xMinYMin meet`)
            .attr('width', `${svgWidth}`)
            .attr('pointer-events', 'all');

        this.svgChart.on('click', () => {
            if (this.clickedPoint !== null) {
                this.clickedPoint = null;
                this.tooltip.style('display', 'none').style('opacity', 0);
                document.querySelectorAll('.point').forEach((el) => ((el as SVGElement).style.opacity = '1'));
            }
        });

        // Handle drag
        drag().on('drag', (e) => this.handleDragEvent(e))(this.svgChart);

        /* X axis */
        this.svgChart
            .append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(${margin.left}, ${height + margin.top + 8})`)
            .call(this.xAxis.tickSize(0))
            .style('color', this._sassHelperService.getProperty('ffp-grey-170'))
            .style('font-size', '12px')
            .style('font-weight', '400')
            .select('path')
            .style('stroke', 'transparent');

        // title
        const xAxisTitle = this.svgChart.append('g');
        xAxisTitle
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', width + margin.left + 8)
            .attr('y', height + margin.top + 20)
            .attr('font-size', '12')
            .text('Nautical')
            .style('fill', this._sassHelperService.getProperty('ffp-grey-150'));

        xAxisTitle
            .append('text')
            .attr('text-anchor', 'start')
            .attr('x', width + margin.left + 8)
            .attr('y', height + margin.top + 36)
            .attr('font-size', '12')
            .text('miles')
            .style('fill', this._sassHelperService.getProperty('ffp-grey-150'));

        /* Y axis right */
        this.svgChart
            .append('g')
            .attr('class', 'axis axis--y1')
            .attr('transform', `translate(0, ${margin.top})`)
            .call(this.y2Axis.ticks(4).tickSize(0))
            .style('color', this._sassHelperService.getProperty('ffp-grey-170'))
            .style('font-size', '12px')
            .style('font-weight', '400')
            .attr('x', '0')
            .attr('text-anchor', 'start')
            .select('path')
            .style('stroke', 'transparent');
        // Delete the "0" tick
        this.svgChart.select('.axis--y1').select('.tick').remove();

        // title
        if (this.coreService.emissionMeasure.toLowerCase() === 'ccf') {
            this.svgChart
                .append('g')
                .append('text')
                .attr('text-anchor', 'start')
                .attr('x', 0)
                .attr('y', 35)
                .attr('font-size', '12')
                .text('Picokelvin')
                .style('fill', this._sassHelperService.getProperty('ffp-grey-150'));
        } else {
            this.svgChart
                .append('g')
                .append('text')
                .attr('text-anchor', 'start')
                .attr('x', 0)
                .attr('y', 35)
                .attr('font-size', '12')
                .text('t C02-eq')
                .style('fill', this._sassHelperService.getProperty('ffp-grey-150'));
        }

        // Display horizontal lines
        this.svgChart
            .append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', '2,3')
            .call(this.y2Axis.ticks(5).tickSize(width).tickFormat(''))
            .select('path')
            .attr('stroke-width', 0);
        this.svgChart.select('.grid').select('.tick').remove();

        this.zoomCall = zoom()
            .scaleExtent([1, 40])
            .translateExtent(extent)
            .extent(extent)
            .on('zoom', (e) => this.zoomed(e));

        if (this._verticalDisplayService.lastZoomEvent) {
            const event = this._verticalDisplayService.lastZoomEvent;
            this.svgChart
                .call(this.zoomCall)
                .call(this.zoomCall.transform, zoomIdentity.translate(event.transform.x, event.transform.y1).scale(event.transform.k));
        } else {
            this.svgChart.call(this.zoomCall);
        }

        //  Manage chart plotting
        this.svgChart
            .append('defs')
            .append('svg:clipPath')
            .attr('id', 'clip')
            .append('svg:rect')
            .attr('width', width)
            .attr('height', height + 20)
            .attr('transform', `translate(${margin.left}, ${margin.top - 20})`);

        const zoomable = this.svgChart.append('g').attr('width', width).attr('clip-path', 'url(#clip)').attr('id', 'clip-path');

        // Add trajectories representation
        for (const trajectoryName of this.flightDataMap.keys()) {
            this._addDataToGraph(zoomable, trajectoryName, onlyUpdate);
        }

        this.tooltip = select(this.ref.nativeElement)
            .append('div')
            .attr('id', 'vertical-view-tooltip')
            .attr('class', 'tooltip')
            .style('display', 'none')
            .style('opacity', 0);
    };

    // eslint-disable-next-line
    handleDragEvent = (e: any): void => {
        if (e.sourceEvent === null || e.sourceEvent === undefined || this.zoomLevel === 1) {
            return;
        }

        const x0 = this.x(this.xAxis.scale().domain()[0]) + e.dx;
        if (x0 < 0 || x0 + width / this.zoomLevel > width) {
            return;
        }

        // Update chart range
        this.svgChart.call(this.zoomCall.transform, zoomIdentity.scale(this.zoomLevel).translate(-x0, 0));
    };

    // eslint-disable-next-line
    zoomed = (event: any) => {
        if (event.sourceEvent !== null && !(event.sourceEvent && event.sourceEvent.type === 'wheel')) {
            return;
        }
        // Update cursor to drag when zoomed
        this.svgChart.attr('cursor', () => (this.zoomLevel === 1 ? 'inherit' : 'ew-resize'));

        // Apply rescale
        this.rescaledAxis = event.transform.rescaleX(this.x);
        this.zoomLevel = event.transform.k;

        // Rescale x axis
        this.xAxis.scale(this.rescaledAxis);
        select('.axis--x').call(this.xAxis);

        // Rescale graph elements
        for (const trajectoryName of this.flightDataMap.keys()) {
            // Rescale points
            this.svgChart
                .selectAll('circle.data-point-' + trajectoryName)
                .data(this.flightDataMap.get(trajectoryName))
                .attr('cx', (d: [number, number, GeoJsonProperties, string | null]) => {
                    return this.rescaledAxis(d[0]);
                });

            // Rescale line
            select('#graph-line-' + trajectoryName).attr('d', this.line(this.flightDataMap.get(trajectoryName), this.rescaledAxis));

            // Rescale rect
            this.svgChart
                .select('.conso-details')
                .attr('width', width)
                .selectAll('rect')
                .attr('x', (d: FilteredData) => {
                    return this.rescaledAxis(d.distance);
                });
        }

        this._verticalDisplayService.lastZoomEvent = event;
    };

    filterData(data: PointEmissionData[], total: number): FilteredData[] {
        const percent = scaleLinear().domain([0, total]).range([0, 100]);
        // filter out data that has zero values
        // also get mapping for next placement
        // (save having to format data for d3 stack)
        let cumulative = 0;
        return data.map((d, i) => {
            cumulative += d.value;
            return {
                value: d.value,
                // want the cumulative to prior value (start of rect)
                cumulative: cumulative - d.value,
                label: d.label,
                percent: percent(d.value),
                distance: d.distance,
                initialIndex: i,
            };
        });
    }

    computeEmissionsDetails(id: string): void {
        const co2 = this.flightDataMap
            .get(id)
            ?.map((f) => f[2]![this.coreService.emissionMeasure.toLowerCase() + 'Co2'])
            .reduce((all, val) => all + val, 0);
        const nox = this.flightDataMap
            .get(id)
            ?.map((f) => f[2]![this.coreService.emissionMeasure.toLowerCase() + 'Nox'])
            .reduce((all, val) => all + val, 0);
        const contrails = this.flightDataMap
            .get(id)
            ?.map((f) => f[2]![this.coreService.emissionMeasure.toLowerCase() + 'Contrails'])
            .reduce((all, val) => all + val, 0);
        const h2o = this.flightDataMap
            .get(id)
            ?.map((f) => f[2]![this.coreService.emissionMeasure.toLowerCase() + 'H2o'])
            .reduce((all, val) => all + val, 0);
        this.emissionsDetails = { co2, nox, contrails, h2o };
    }

    getPhaseName(phaseIndex: number): string {
        return FlightPhase[phaseIndex];
    }

    changeEmissionDisplayed(property: 'h2o' | 'contrails' | 'nox' | 'co2'): void {
        if (Object.keys(this.emissionDisplayed).includes(property)) {
            // If all enabled, then keep only current property
            if (Object.keys(this.emissionDisplayed).every((p) => this.emissionDisplayed[p as keyof typeof this.emissionDisplayed])) {
                Object.keys(this.emissionDisplayed).forEach((p) => (this.emissionDisplayed[p as keyof typeof this.emissionDisplayed] = false));
                this.emissionDisplayed[property] = !this.emissionDisplayed[property];
            }
            // Else act like a toggle and add/remove property
            else {
                this.emissionDisplayed[property] = !this.emissionDisplayed[property];
            }
        }

        // If all disabled, then enable all
        if (Object.keys(this.emissionDisplayed).every((p) => !this.emissionDisplayed[p as keyof typeof this.emissionDisplayed])) {
            Object.keys(this.emissionDisplayed).forEach((p) => (this.emissionDisplayed[p as keyof typeof this.emissionDisplayed] = true));
        }

        this.buildVerticalView(true);
    }

    changeFlightPhase(): void {
        this._verticalDisplayService.lastZoomEvent = null;
        this.buildVerticalView();
    }

    handleEmissionMeasureChange(value: string): void {
        this.coreService.emissionMeasure = value === 'atr20' ? 'Ccf' : 'Gwp100';
        this._verticalDisplayService.lastZoomEvent = null;
        this.buildVerticalView();
    }

    handleClickOnEmission =
        (data: FilteredData[], flightLevel: number, index: number) =>
        // eslint-disable-next-line
            (event: any) => {
            if (this.clickedPoint !== 'point--' + index) {
                this.clickedPoint = 'point--' + index;
                document.querySelectorAll('.point').forEach((el) => {
                    if (!el.classList.contains('point--' + index)) {
                        (el as SVGElement).style.opacity = '0.15';
                    } else {
                        (el as SVGElement).style.opacity = '1';
                    }
                });
                this.tooltip = select('.tooltip');
                this.tooltip
                    .attr('class', 'tooltip emission-tooltip')
                    .style('top', event.layerY - 20 + 'px')
                    .style('left', event.layerX + 6 + 'px')
                    .style('display', 'flex')
                    .style('opacity', 1);

                let htmlTooltip = '';
                htmlTooltip += `
                    <div class="tooltip-item">
                      <div class="tooltip-title">FL </div>
                      <div class="tooltip-value">${Math.round(flightLevel / 10) * 10}</div>
                  </div>`;
                data.forEach((el) => {
                    htmlTooltip += `<div class="tooltip-item"><div class="tooltip-title">${el.label.toUpperCase()}</div>
                            <div class="tooltip-value ${el.label.toLowerCase()}">
        ${this.coreService.emissionMeasure.toLowerCase() === 'gwp100' ? (el.value / 1000000).toFixed(2) : (el.value * 1e12).toFixed(2)}
        </div></div>`;
                });

                this.tooltip.html(htmlTooltip);
            } else {
                this.clickedPoint = null;
                this.tooltip.style('display', 'none').style('opacity', 0);
                document.querySelectorAll('.point').forEach((el) => ((el as SVGElement).style.opacity = '1'));
            }
            event.preventDefault();
            event.stopPropagation();
        };

    changeTrajectoryDisplayed(flightFeatureCollection: {
        flightNumber: string;
        startDate: string;
        startTime: string;
        source: Feature | FeatureCollection;
        visible: boolean;
        id: string;
    }) {
        if (flightFeatureCollection.visible) {
            this.trajectoryCurrentlyDisplayed = flightFeatureCollection;
            this.previousTrajectoryDisplayed = undefined;
            this.buildVerticalView(true);
        }
    }

    isPhaseAvailable(phase: number): boolean {
        return (this.trajectoryCurrentlyDisplayed?.source as FeatureCollection)?.features
            .map((p: Feature) => {
                if (p.properties) {
                    return p.properties['phase'];
                }
                return;
            })
            .includes(this.getPhaseName(phase));
    }

    private _addDataToDataset(collection: FeatureCollection, name: string): void {
        this.flightDataMap.set(name, []);
        if (collection && collection.features) {
            let firstIdx;
            let lastIdx;
            const phaseMargin = 0;
            const phaseArray = collection.features.map((p) => p?.properties!['phase']);
            switch (this.getPhaseName(this.selectedFlightPhase.value)) {
                case 'CL':
                    firstIdx = 0;
                    if (phaseArray.includes('CR')) {
                        lastIdx = phaseArray.indexOf('CR') + phaseMargin;
                    } else {
                        lastIdx = phaseArray.indexOf('DE') - 1 + phaseMargin;
                    }
                    break;
                case 'CR':
                    firstIdx = phaseArray.indexOf('CR') - phaseMargin;
                    lastIdx = phaseArray.lastIndexOf('CR') + phaseMargin;
                    break;
                case 'DE':
                    if (phaseArray.includes('CR')) {
                        firstIdx = phaseArray.lastIndexOf('CR') - phaseMargin;
                    } else {
                        firstIdx = phaseArray.indexOf('DE') - phaseMargin;
                    }
                    lastIdx = collection.features.length - 1;
                    break;
                default:
                    firstIdx = 0;
                    lastIdx = collection.features.length - 1;
                    break;
            }

            collection.features.slice(firstIdx, lastIdx + 1).forEach((feature) => {
                const coordinates = (feature.geometry as Point).coordinates;
                this.flightDataMap
                    .get(name)!
                    .push([
                        this._convertMeterToNauticalMiles.transform(feature.properties!['distanceStart'] as number),
                        this._convertMeterToFlightLevel.transform(coordinates[2]),
                        feature.properties,
                        this.legend
                            ? this._verticalDisplayService.getPointColorFromProperties(feature.properties, this.legendName, this.legend)
                            : null,
                    ]);
            });
            this.flightDataMap.set(name, this.cleanTrajectory(this.flightDataMap.get(name)!));
        }
    }

    // @ts-expect-error: D3.js stuff
    private _addDataToGraph(zoomable, trajectoryName: string, onlyUpdate: boolean | undefined): void {
        // Add line representation
        const path = zoomable
            .append('path')
            .attr('transform', `translate(${margin.left}, ${margin.top})`)
            .attr('id', 'graph-line-' + trajectoryName)
            .datum(this.flightDataMap.get(trajectoryName))
            .attr('fill', 'none')
            .attr('stroke', this._sassHelperService.getProperty('ffp-grey-110'))
            .attr('stroke-width', 3)
            .attr('opacity', '0.35')
            .on('mouseover', (event: MouseEvent) => event.stopPropagation())
            .attr('d', (d: FilteredData) => {
                return onlyUpdate ? this.line(d, this.rescaledAxis) : this.flatLine(d, this.rescaledAxis);
            });

        if (!onlyUpdate) {
            path.transition()
                .duration(2000)
                .attr('d', (d: FilteredData) => {
                    return this.line(d, this.rescaledAxis);
                });
        }

        const d3Container = zoomable.append('g').attr('class', 'conso-details');
        for (const [i, point] of this.flightDataMap.get(trajectoryName)!.entries()) {
            const emissionsDataSample: PointEmissionData[] = [];
            Object.entries(this.emissionDisplayed).forEach((entry) => {
                if (entry[1]) {
                    emissionsDataSample.push({
                        label: entry[0],
                        value: Math.abs(
                            parseFloat(point[2]![this.coreService.emissionMeasure.toLowerCase() + entry[0][0].toUpperCase() + entry[0].slice(1)]),
                        ),
                        distance: point[0],
                    });
                }
            });
            const total = sum(emissionsDataSample, (d) => d.value);
            const _data = this.filterData(emissionsDataSample, total).reverse();

            const initialChart = d3Container
                .append('g')
                .attr('class', 'point point--' + i)
                .on('click', this.handleClickOnEmission(_data, point[1], i))
                .selectAll('rect')
                .data(_data)
                .enter()
                .append('rect')
                .attr('class', (d: FilteredData) => 'rect-stacked ' + d.label)
                .attr('height', () => {
                    return this.y2(0) / emissionsDataSample.length;
                })
                .attr('x', (d: FilteredData) => this.x(d.distance))
                .attr('y', () => {
                    return this.y2(0);
                })
                .attr('transform', `translate(${margin.left}, ${margin.top})`)
                .attr('ry', (d: FilteredData) => {
                    return this.y2(d.cumulative) - this.y2(d.cumulative + d.value) > 12 ? 8 : 4;
                })
                .attr('rx', (d: FilteredData) => {
                    return this.y2(d.cumulative) - this.y2(d.cumulative + d.value) > 12 ? 8 : 4;
                })
                .attr('width', '12');

            initialChart
                .transition()
                .duration(2000)
                .attr('height', (d: FilteredData) => {
                    return Math.max(1, this.y2(d.cumulative) - this.y2(d.cumulative + d.value));
                })
                .attr('y', (d: FilteredData) => {
                    return this.y2(d.cumulative + d.value) - 2 * d.initialIndex;
                });
        }
    }

    private cleanTrajectory(
        initialTrajectory: [number, number, GeoJsonProperties, string | null][],
    ): [number, number, GeoJsonProperties, string | null][] {
        const trajectory = structuredClone(initialTrajectory);
        const temp = structuredClone(initialTrajectory);

        const barNumber = 45;

        // Add point in empty part of the trajectory
        for (let i = 1; i < trajectory.length; i++) {
            const distanceWithPreviousPoint = trajectory[i][0] - trajectory[i - 1][0];

            if (distanceWithPreviousPoint > (trajectory[trajectory.length - 1][0] - trajectory[0][0]) / barNumber) {
                const startPoint = trajectory[i - 1];
                const endPoint = trajectory[i];

                const distanceCoveredDuringSegment = endPoint[0] - startPoint[0];
                const altitudeCoveredDuringSegment = endPoint[1] - startPoint[1];

                const nbPoint = Math.round((distanceCoveredDuringSegment * 80) / (trajectory[trajectory.length - 1][0] - trajectory[0][0]));

                const pointsToAdd: [number, number, GeoJsonProperties, string | null][] = [];
                for (let j = 0; j < nbPoint; j++) {
                    const newPoint: [number, number, GeoJsonProperties, string | null] = [
                        startPoint[0] + (distanceCoveredDuringSegment / nbPoint) * (j + 1),
                        startPoint[1] + (altitudeCoveredDuringSegment / nbPoint) * (j + 1),
                        {
                            type: 'flight--data-at-points-feature',
                            contrails: endPoint[2]!['contrails'] / nbPoint,
                            distanceStart: startPoint[2]!['distanceStart'] + (distanceCoveredDuringSegment / nbPoint) * (j + 1),
                            wind: null,
                            flightNumber: endPoint[2]!['flightNumber'],
                            startDate: endPoint[2]!['startDate'],
                            ccfCo2: endPoint[2]!['ccfCo2'] / nbPoint,
                            ccfNox: endPoint[2]!['ccfNox'] / nbPoint,
                            ccfContrails: endPoint[2]!['ccfContrails'] / nbPoint,
                            ccfH2o: endPoint[2]!['ccfH2o'] / nbPoint,
                            gwp100Co2: endPoint[2]!['gwp100Co2'] / nbPoint,
                            gwp100Nox: endPoint[2]!['gwp100Nox'] / nbPoint,
                            gwp100Contrails: endPoint[2]!['gwp100Contrails'] / nbPoint,
                            gwp100H2o: endPoint[2]!['gwp100H2o'] / nbPoint,
                            totalCcfAbs: endPoint[2]!['totalCcfAbs'] / nbPoint,
                            totalGwp100: endPoint[2]!['totalGwp100'] / nbPoint,
                            phase: endPoint[2]!['phase'],
                        } as GeoJsonProperties,
                        endPoint[3],
                    ];
                    pointsToAdd.push(newPoint);
                }
                temp.splice(i + (temp.length - trajectory.length), 1, ...pointsToAdd);
            }
        }

        // Clean traj, so it contains only $barNumber points
        const totalDistance = temp[temp.length - 1][0] - temp[0][0];
        const distanceBetweenPoint = totalDistance / barNumber;
        const reducedTrajectory: [number, number, GeoJsonProperties, string | null][] = [];
        let lastClosestIndex = 0;
        const keys = [
            'contrails',
            'ccfCo2',
            'ccfNox',
            'ccfContrails',
            'ccfH2o',
            'totalCcfAbs',
            'gwp100',
            'gwp100Co2',
            'gwp100Nox',
            'gwp100Contrails',
            'gwp100H2o',
        ];

        // Exit loop if last point + distanceBetweenPoint exceeds totalDistance
        // Note: We subtract temp[0][0] because in case of CR or DE phase, the first point has already covered some distance
        for (let i = 0; i < barNumber && temp[lastClosestIndex][0] - temp[0][0] + distanceBetweenPoint < totalDistance; i++) {
            const closest = temp.reduce((prev, curr) => {
                return Math.abs(curr[0] - (temp[0][0] + distanceBetweenPoint * i)) < Math.abs(prev[0] - (temp[0][0] + distanceBetweenPoint * i)) &&
                    curr[0] - reducedTrajectory.slice(-1)[0][0] >= distanceBetweenPoint
                    ? curr
                    : prev;
            });

            const closestIndex = temp.findIndex((el) => JSON.stringify(el) === JSON.stringify(closest));
            const currentSegment = temp.slice(lastClosestIndex + 1, closestIndex);

            keys.forEach((key) => {
                closest[2]![key] = currentSegment.reduce((partialSum, p) => partialSum + p[2]![key], closest[2]![key]);
            });

            if (!reducedTrajectory.includes(closest)) {
                reducedTrajectory.push(closest);
            }

            lastClosestIndex = closestIndex;
        }

        // Add last point if it is not included, to have a clean traj
        if (!reducedTrajectory.includes(temp[temp.length - 1])) {
            const currentSegment = temp.slice(lastClosestIndex + 1, temp.length - 1);
            keys.forEach((key: string) => {
                temp[temp.length - 1][2]![key] = currentSegment.reduce(
                    (partialSum, p) => partialSum + (p[2]![key] as number),
                    temp[temp.length - 1][2]![key],
                );
            });
            reducedTrajectory.push(temp[temp.length - 1]);
        }

        return reducedTrajectory;
    }
}
