import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClimateMapRoutingModule } from './climate-map-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ClimateMapComponent } from './climate-map.component';

@NgModule({
    declarations: [ClimateMapComponent],
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Green Aviation
        ClimateMapRoutingModule,
        SharedModule,
    ],
    providers: [],
})
export class ClimateMapModule {}
