import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class LocalInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let apiReq: HttpRequest<unknown>;
        switch (environment.backend) {
            case 'local':
                apiReq = request.clone({
                    url: request.url.replace('/api', '/local/api'),
                });
                return next.handle(apiReq);
            case 'dev':
                apiReq = request.clone({
                    url: request.url.replace('/api', '/dev/api'),
                });
                return next.handle(apiReq);
            case 'qa':
                apiReq = request.clone({
                    url: request.url.replace('/api', '/qa/api'),
                });
                return next.handle(apiReq);
            case 'prod':
                apiReq = request.clone({
                    url: request.url.replace('/api', '/prod/api'),
                });
                return next.handle(apiReq);
            default:
                return next.handle(request);
        }
    }
}
