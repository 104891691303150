export class Utils {
    static getRgbaFromGradient(color1: string, color2: string, weight: number): string {
        const c1: number[] = JSON.parse(color1.split('rgba')[1].replaceAll('(', '[').replaceAll(')', ']'));
        const c2: number[] = JSON.parse(color2.split('rgba')[1].replaceAll('(', '[').replaceAll(')', ']'));
        const w = weight * 2 - 1;
        const w1 = (w + 1) / 2;
        const w2 = 1 - w1;
        return (
            'rgba(' +
            Math.round(c1[0] * w2 + c2[0] * w1) +
            ', ' +
            Math.round(c1[1] * w2 + c2[1] * w1) +
            ', ' +
            Math.round(c1[2] * w2 + c2[2] * w1) +
            ', ' +
            (c1[3] + c2[3]) / 2 +
            ')'
        );
    }

    static mod(n: number, m: number): number {
        return ((n % m) + m) % m;
    }
}
