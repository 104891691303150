<div class="container">
    <div class="header">
        <mat-icon> new_releases </mat-icon>
        <qtm-typography fontWeight="bold" fontSize="7"> A new feature is on the way </qtm-typography>
    </div>
    <div class="content">
        <div class="top">
            <div class="text">
                <qtm-typography fontWeight="medium" fontSize="8" class="label-1">
                    Our team is working on analytics to help you understand your consumption, depending on the city pair and the time needed.
                </qtm-typography>
                <qtm-typography fontWeight="medium" fontSize="8" class="label-2"> No worries, it will come fast! </qtm-typography>
            </div>
            <ffp-consumption-details-label [displayValues]="false" [displayLabel]="false"></ffp-consumption-details-label>
        </div>
        <div class="img">
            <img src="/assets/img/climate-analytics/coming-soon/coming-soon-1.svg" alt="" />
            <img src="/assets/img/climate-analytics/coming-soon/coming-soon-2.svg" alt="" />
        </div>
    </div>
</div>
