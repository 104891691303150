import { Component, HostBinding, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MaintenanceService, MixPanelService } from './core/services';
import { CoreService } from './core/services/core/core.service';
import { AuthService } from './core/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'ffp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('CookieInfo')
    cookieInfo!: TemplateRef<unknown>;

    @HostBinding('class.lp') contentIsLP: boolean = false;

    private touchHandlerSubscription: Subscription = new Subscription();

    constructor(
        private readonly _ccService: NgcCookieConsentService,
        private readonly _matDialog: MatDialog,
        private readonly _route: ActivatedRoute,
        private _mixPanelService: MixPanelService,
        private _maintenanceService: MaintenanceService,
        public readonly authService: AuthService,
        public readonly coreService: CoreService,
        private router: Router,
    ) {
        let zoom = '1.0';
        const minWidthHeight = Math.min(document.documentElement.clientWidth, document.documentElement.clientHeight);
        if (minWidthHeight <= 480) {
            zoom = '0.75';
        } else if (minWidthHeight <= 768) {
            // zoom = '0.8';
        } else if (minWidthHeight <= 1024) {
            // zoom = '0.9';
        }
        const viewport = document.querySelector('meta[name=viewport]')!;
        const viewportContent = 'width=device-width, initial-scale=' + zoom + ', minimum-scale=' + zoom + ', maximum-scale=' + zoom;
        viewport.setAttribute('content', viewportContent);
        document.querySelector('body')!.style.setProperty('--height', `${window.innerHeight}px`);

        window.addEventListener('resize', () => {
            document.querySelector('body')!.style.setProperty('--height', `${window.innerHeight}px`);
        });

        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.contentIsLP = !this.authService.isLoggedIn && val.url === '/';
            }
        });

        coreService.getDisplayWarning().subscribe((setDisplayWarning) => {
            if (setDisplayWarning && this.authService.airline === 'thales') {
                this.coreService.pushNotification({
                    title: 'WARNING',
                    description:
                        'The data displayed is open-sourced and is there to show you the order of magnitude of\n' +
                        '            CO<sub>2</sub> and non-CO<sub>2</sub>\n' +
                        '            emissions. <strong>It should be taken carefully</strong>.<br>For more reliable data or\n' +
                        '            details, please\n' +
                        '            <strong>contact us</strong>.',
                    icon: 'warning',
                    lifeDuration: '30000',
                    info: 'public-flight-warning',
                    index: 0,
                    type: 'WARNING',
                });
            }
        });

        if (this.isProduction) {
            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: environment.appInsightsConnectionString,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    disableFetchTracking: true,
                    enableCorsCorrelation: true,
                    enableAutoRouteTracking: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    autoTrackPageVisitTime: true,
                    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
                },
            });
            appInsights.loadAppInsights();

            // Google Tag Manager / PiwikPro
            const head = document.head;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-T3XTWHB');`;
            head.insertBefore(script, head.children[0]);
        }
    }

    ngOnInit(): void {
        // Cookie consent stuff
        const link: HTMLElement = document.querySelector('.cc-link') as HTMLElement;
        link.removeAttribute('href');
        link.onclick = () => {
            this._matDialog.open(this.cookieInfo, {
                backdropClass: 'cdk-overlay-dark-backdrop',
            });
        };

        this.touchHandlerSubscription = fromEvent(window, 'touchstart')
            .pipe(
                tap(() => {
                    this.coreService.isTactile = true;
                    this.touchHandlerSubscription.unsubscribe();
                }),
            )
            .subscribe();

        // Track user origin
        this._route.queryParams.subscribe((params) => {
            this._mixPanelService.utmSource = params['utm_source'] ? params['utm_source'] : this._mixPanelService.utmSource;
            this._mixPanelService.utmCampaign = params['utm_campaign'] ? params['utm_campaign'] : this._mixPanelService.utmCampaign;
        });

        // Init user tracking
        this._mixPanelService.identify();
    }

    get isUnderMaintenance(): boolean {
        return this._maintenanceService.isUnderMaintenance;
    }

    get isProduction(): boolean {
        return environment.appUrl === 'https://flights-footprint.com';
    }
}
