import { Injectable } from '@angular/core';
import {
    AuthenticationResult,
    EventMessage,
    EventType,
    InteractionRequiredAuthError,
    InteractionStatus,
    PublicClientApplication,
} from '@azure/msal-browser';
import { b2cPolicies, loginRequest, msalConfig, tokenRequest } from '../../../../../auth-config';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, filter } from 'rxjs/operators';
import { AccountInfo } from '@azure/msal-common/src/account/AccountInfo';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public authClient: PublicClientApplication;
    public accountId: string | undefined;
    public userMail: string | undefined;
    public firstName: string | undefined;
    public lastName: string | undefined;
    public isUserAdmin = false;
    public isLoggedIn = false;
    public roles: string[] = [];
    public groups: string[] = [];

    constructor(
        private _msalService: MsalService,
        private _msalBroadcastService: MsalBroadcastService,
        private readonly _router: Router,
    ) {
        this.authClient = new PublicClientApplication(msalConfig);
        this.authClient.initialize().then();

        this._msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)).subscribe((msg) => {
            if (msg.error!.message.includes('AADB2C90085')) {
                this.signOut().then();
            } else if (msg.error!.message.includes('AADB2C90118')) {
                this.authClient
                    .loginRedirect({
                        ...loginRequest,
                        ...b2cPolicies.authorities.resetPassword,
                    })
                    .then();
            }
        });

        this._msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS))
            .subscribe(() => {
                const activeAccount = this._msalService.instance.getActiveAccount();
                if (!activeAccount && this._msalService.instance.getAllAccounts().length > 0) {
                    const accounts = this._msalService.instance.getAllAccounts();
                    this.doLogin(accounts[0]).then();
                }
            });

        this._msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
            const activeAccount = this._msalService.instance.getActiveAccount();
            if (!activeAccount && this._msalService.instance.getAllAccounts().length > 0) {
                const accounts = this._msalService.instance.getAllAccounts();
                this.doLogin(accounts[0]).then();
            } else if (activeAccount && activeAccount.idToken === undefined) {
                this._msalService
                    .acquireTokenSilent(loginRequest)
                    .pipe(
                        catchError((err) => {
                            if (err instanceof InteractionRequiredAuthError) {
                                this._msalService.acquireTokenRedirect(loginRequest);
                            }
                            return of({} as AuthenticationResult);
                        }),
                    )
                    .subscribe();
            } else if (activeAccount) {
                this.doLogin(activeAccount).then();
            }
        });
    }

    signIn(redirectStartPage?: string): Observable<void> {
        return this._msalService.loginRedirect({
            redirectStartPage,
            ...loginRequest,
        });
    }

    async signOut(): Promise<void> {
        this.isLoggedIn = false;
        const r = {
            path: '',
            loadChildren: () => import('../../../features/landing-page/landing-page.module').then((m) => m.LandingPageModule),
        };
        this._router.resetConfig([r, ...this._router.config]);
        const logoutRequest = {
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            mainWindowRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        };
        await this.authClient.logoutRedirect(logoutRequest);
    }

    async doLogin(account: AccountInfo): Promise<void> {
        this.setAccount(account);
    }

    get airline(): string {
        return this.groups && this.groups.length > 0 ? this.groups[0] : 'thales';
    }

    get airport(): string | undefined {
        const airports = this.roles?.filter((r) => r.startsWith('role_airport'));
        if (airports && airports.length > 0) {
            return airports[0];
        }
        return;
    }

    private setAccount(account: AccountInfo): void {
        if (!account.idTokenClaims?.roles) {
            this.signOut().then();
        }
        this.accountId = account.homeAccountId;
        this.userMail = account.idTokenClaims!['email'] as string;
        this.firstName = account.idTokenClaims!['given_name'] as string;
        this.lastName = account.idTokenClaims!['family_name'] as string;
        this.authClient.setActiveAccount(account);
        this.isUserAdmin = account.idTokenClaims!['roles']?.includes('role_admin') as boolean;
        this.roles = account.idTokenClaims!['roles'] as string[];
        this.groups = account.idTokenClaims!['groups'] as string[];
        this.isLoggedIn = true;
    }

    private async getToken(): Promise<string | void> {
        tokenRequest.account = this.authClient.getAccount({
            homeAccountId: this.accountId,
        })!;
        return this.authClient
            .acquireTokenSilent(tokenRequest)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === '') {
                    throw new InteractionRequiredAuthError();
                }
                return response.accessToken;
            })
            .catch(() => this.authClient.acquireTokenRedirect(tokenRequest));
    }
}
