<div class="container" [ngClass]="{ show: !hideWarning, hide: hideWarning }" [className]="notification!.type.toLowerCase()">
    <div class="content">
        <div class="title">
            <mat-icon [inline]="true">
                {{ notification!.icon }}
            </mat-icon>
            <qtm-typography fontSize="8" fontWeight="medium">
                {{ notification!.title.toUpperCase() }}
            </qtm-typography>
        </div>
        <qtm-typography fontSize="8" fontWeight="normal" [innerHTML]="notification!.description"> </qtm-typography>
    </div>
    <mat-icon [inline]="true" class="close" (click)="closePanel()"> close </mat-icon>
</div>
