import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClimateAnalyticsRoutingModule } from './climate-analytics-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { CityPairExplorerComponent } from './components/city-pair-explorer/city-pair-explorer.component';
import { CityPairCardComponent } from './components/city-pair-explorer/components/city-pair-card/city-pair-card.component';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { ComingSoonComponent } from './components/city-pair-explorer/components/coming-soon/coming-soon.component';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from '../../core/core.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterPopUpComponent } from './components/city-pair-explorer/components/filter-pop-up/filter-pop-up.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FlightTableComponent } from './components/city-pair-explorer/components/flight-table/flight-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { PastFlightAnalysisComponent } from './components/past-flights-analysis/past-flight-analysis.component';
import { SearchBarComponent } from './components/past-flights-analysis/components/search-bar/search-bar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
// eslint-disable-next-line max-len
import { ConsumptionVisualisationComponent } from './components/past-flights-analysis/components/consumption-visualisation/consumption-visualisation.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import { SearchCardComponent } from './components/past-flights-analysis/components/comparison/search-card/search-card.component';
import { ComparisonModalComponent } from './components/past-flights-analysis/components/comparison/comparison-modal/comparison-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { DataDeltaComponent } from '../../shared/components/data-delta/data-delta.component';

@NgModule({
    declarations: [
        CityPairExplorerComponent,
        CityPairCardComponent,
        DataDeltaComponent,
        ComingSoonComponent,
        FilterPopUpComponent,
        ComingSoonComponent,
        FlightTableComponent,
        PastFlightAnalysisComponent,
        SearchBarComponent,
        ConsumptionVisualisationComponent,
        SearchCardComponent,
        ComparisonModalComponent,
    ],
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Green Aviation
        ClimateAnalyticsRoutingModule,
        SharedModule,
        QtmComponentLibraryModule,
        MatIconModule,
        CoreModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatTableModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSliderModule,
        MatDatepickerModule,
        OverlayModule,
        MatSelectModule,
        MatTooltipModule,
        MatBadgeModule,
    ],
    exports: [],
    providers: [],
})
export class ClimateAnalyticsModule {}
