import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './redirect.guard';

const routes: Routes = [
    {
        path: 'administration',
        loadChildren: () => import('./features/administration/administration.module').then((m) => m.AdministrationModule),
    },
    {
        path: 'forecast',
        loadChildren: () => import('./features/forecast/forecast.module').then((m) => m.ForecastModule),
    },
    {
        path: 'climate-map',
        loadChildren: () => import('./features/climate-map/climate-map.module').then((m) => m.ClimateMapModule),
    },
    {
        path: 'climate-analytics',
        loadChildren: () => import('./features/climate-analytics/climate-analytics.module').then((m) => m.ClimateAnalyticsModule),
    },
    {
        path: 'route-explorer',
        loadChildren: () => import('./features/route-explorer/route-explorer.module').then((m) => m.RouteExplorerModule),
    },
    {
        path: '',
        loadChildren: () => import('./features/landing-page/landing-page.module').then((m) => m.LandingPageModule),
        canActivate: [RedirectGuard],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
