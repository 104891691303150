import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    ConsumptionDetailsComponent,
    ConsumptionDetailsLabelComponent,
    FfpButtonComponent,
    HelpDialogComponent,
    LoaderComponent,
    LoadingDialogComponent,
    LogoutDialogComponent,
    MapButtonComponent,
    MapComponent,
    NoDataComponent,
    VerticalViewComponent,
} from './components';
import { MapService } from './services';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Utils } from './utils/utils';
import { WarningDialogComponent } from './components/dialog/warningDialog/warning-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QtmComponentLibraryModule } from '@qtm/angular';

import {
    AutoConvert,
    ConvertFlightLevelToMeter,
    ConvertGramsToTons,
    ConvertKgToTonsPipe,
    ConvertMeterToFeet,
    ConvertMeterToFlightLevel,
    ConvertMeterToNauticalMiles,
    ConvertMetersPerMsToKts,
    ConvertMetersToFeet,
    ConvertMetersToNM,
    ConvertMsToHoursAndMinutes,
    ConvertToPicoKelvin,
    FormatDate,
    FormatFullDate,
    FormatFullTime,
    FormatHoursAndMinutes,
    FormatMonthAndYear,
    FormatNumber,
    FormatPartialDate,
    GetMonth,
    GetSeason,
    GetYear,
    UppercaseFirstLetter,
} from './pipes/format-data.pipe';
import { MatSelectModule } from '@angular/material/select';
import { FlightInfoComponent } from './components/flight-info/flight-info.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { MatOptionsScrollDirective } from './directives/mat-option-scroll.directive';
import { SwiperComponent } from './components/swiper/swiper.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CountUpDirective } from './directives/count-up.directive';

@NgModule({
    declarations: [
        MapButtonComponent,
        MapComponent,
        VerticalViewComponent,
        LoaderComponent,
        HelpDialogComponent,
        LoadingDialogComponent,
        WarningDialogComponent,
        FfpButtonComponent,
        ConsumptionDetailsComponent,
        ConsumptionDetailsLabelComponent,
        ConvertKgToTonsPipe,
        ConvertGramsToTons,
        ConvertMetersToNM,
        ConvertMetersToFeet,
        ConvertMsToHoursAndMinutes,
        ConvertMetersPerMsToKts,
        ConvertToPicoKelvin,
        FormatDate,
        FormatNumber,
        AutoConvert,
        FormatFullTime,
        FormatFullDate,
        FormatPartialDate,
        FormatMonthAndYear,
        NoDataComponent,
        FormatHoursAndMinutes,
        LogoutDialogComponent,
        FlightInfoComponent,
        GetMonth,
        GetSeason,
        ToggleComponent,
        ConvertMeterToFlightLevel,
        ConvertFlightLevelToMeter,
        ConvertMeterToNauticalMiles,
        ConvertMeterToFeet,
        MatOptionsScrollDirective,
        SwiperComponent,
        GetYear,
        UppercaseFirstLetter,
        CountUpDirective,
    ],
    providers: [MapService, Utils],
    imports: [
        // Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // Material
        MatIconModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonModule,
        MatTooltipModule,
        MatSelectModule,
        // Green Aviation
        // 3rd party
        QtmComponentLibraryModule,
        MatCheckboxModule,
    ],
    exports: [
        MapComponent,
        VerticalViewComponent,
        LoaderComponent,
        LoadingDialogComponent,
        FfpButtonComponent,
        ConsumptionDetailsComponent,
        ConsumptionDetailsLabelComponent,
        ConvertKgToTonsPipe,
        ConvertToPicoKelvin,
        FormatDate,
        FormatFullTime,
        FormatFullDate,
        FormatPartialDate,
        FormatMonthAndYear,
        FormatNumber,
        NoDataComponent,
        FormatHoursAndMinutes,
        ConvertGramsToTons,
        ConvertMetersToFeet,
        ConvertMsToHoursAndMinutes,
        ConvertMetersPerMsToKts,
        ConvertMetersToNM,
        FlightInfoComponent,
        GetMonth,
        GetSeason,
        ToggleComponent,
        AutoConvert,
        ConvertMeterToFlightLevel,
        ConvertFlightLevelToMeter,
        ConvertMeterToNauticalMiles,
        ConvertMeterToFeet,
        MatOptionsScrollDirective,
        SwiperComponent,
        GetYear,
        UppercaseFirstLetter,
        CountUpDirective,
    ],
})
export class SharedModule {}
