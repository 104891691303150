<div class="container">
    <div class="content">
        @for (notification of coreService.getNotifications(); track notification) {
            <ffp-notification
                (mouseover)="setHoveredNotification(notification)"
                (mouseleave)="setHoveredNotification(null)"
                [notification]="notification"
                [class]="'notification-' + notification.index"
            ></ffp-notification>
        }
    </div>
    @if (coreService.getNotifications().length > 1) {
        <ffp-button type="secondary" size="x-small" neutral (ffpClick)="coreService.clearNotification()">
            <qtm-typography fontSize="9" fontWeight="medium"> Clear all </qtm-typography>
        </ffp-button>
    }
</div>
